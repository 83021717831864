@font-face {
  font-family: "Urbanist-light";
  src: url(../fonts/Urbanist/Urbanist-Light.ttf);
}

@font-face {
  font-family: "Urbanist-Regular";
  src: url(../fonts/Urbanist/Urbanist-Regular.ttf);
}

@font-face {
  font-family: "Urbanist-Semibold";
  src: url(../fonts/Urbanist/Urbanist-SemiBold.ttf);
}

@font-face {
  font-family: "Urbanist-Medium";
  src: url(../fonts/Urbanist/Urbanist-Medium.ttf);
}

@font-face {
  font-family: "Urbanist-Bold";
  src: url(../fonts/Urbanist/Urbanist-Bold.ttf);
}

@font-face {
  font-family: "AllianceNo2-Regular";
  src: url(../fonts/AllianceNo2/AllianceNo2-Regular.ttf);
}

@font-face {
  font-family: "Hauora-Regular";
  src: url(../fonts/Hauora/Hauora-Regular.ttf);
}

@font-face {
  font-family: "Hauora-Light";
  src: url(../fonts/Hauora/Hauora-Light.ttf);
}


@font-face {
  font-family: "Hauora-Medium";
  src: url(../fonts/Hauora/Hauora-Medium.ttf);
}

@font-face {
  font-family: "Hauora-SemiBold";
  src: url(../fonts/Hauora/Hauora-SemiBold.ttf);
}

.display-flex {
  display: flex;
}

.text-decoration {
  text-decoration: none !important;
}

.urbanist-semibold {
  font-family: "Urbanist-Semibold" !important;
}

/* start user auth pages */

.auth-flex-main {
  display: flex;
  min-height: 100vh;
}

.auth-left {
  flex: 1;
  background-color: #000000;
  display: flex;
}

.auth-right {
  max-width: 600px;
  width: 100%;
  flex: 0 0 600px;
}

.auth-forget-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 88px;
}

.input-box .MuiInputBase-root {
  width: 100%;
}

.input-box .MuiInputBase-input {
  padding: 10.5px 15px;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Urbanist-Medium';

}

.input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.checkbox-main .MuiSvgIcon-root {
  color: #114a65;
  width: 20px;
  height: 20px;
}

.auth-inner-main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.auth-inner {
  max-width: 420px;
  margin: auto;
}

.auth-inner-main .auth-title {
  color: #3c3480;
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 10px;
  font-family: "Urbanist-Regular";
}

.auth-inner-main .auth-para {
  color: #000000;
  font-size: 22px;
  line-height: 26px;
  font-family: "Urbanist-Regular";
  margin-bottom: 40px;
}

.auth-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.auth-field {
  margin-bottom: 19px;
}

.auth-field .form-group .form-control-eye input {
  color: rgba(73, 84, 99, 0.7);
  font-size: 14px;
  line-height: 17px;
  font-family: 'Urbanist-Medium';
  border: 0px;
  padding: 12px 10px;
}

.auth-field .form-group .form-control-eye input::placeholder {
  color: rgba(73, 84, 99, 0.7);
  font-size: 14px;
  line-height: 17px;
  font-family: 'Urbanist-Medium';
}

.auth-checkbox-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.auth-checkbox-main .checkbox-lable {
  margin-left: 0px;
  margin-right: 0px;
}

.auth-checkbox-main .auth-checkbox-lable {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Regular";
}

.auth-checkbox-main .auth-checkbox-spanlable {
  color: #5e55b8;
}

.auth-checkbox-main .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
  color: grey;
}

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background-color: #6e66e6;
  padding: 15px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Urbanist-Medium';
  text-transform: capitalize;
  color: #ffffff;
  width: 100%;
  border-radius: 5px;
}

.auth-forget-flex .primary-btn-main .auth-forget-btn {
  min-width: 180px;
  margin-top: 1px;
}

.primary-btn-main .auth-btn {
  margin-top: 19px;
  margin-bottom: 30px;
}


.btn-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 
.primary-btn-main{
  width: 180px;
}  */



.auth-inner-main .auth-sub-para {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Medium";
  margin-bottom: 40px;
}

.auth-sub-para-span {
  color: #5E55B8;
  font-family: "Urbanist-Semibold";
  margin-left: 2px;
}

.auth-inner-main .auth-forget-title {
  margin-top: 110px;
}

.auth-footer-flex .auth-footer-para {
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Medium";
  margin-right: 39px;
  color: #758698;
  margin-bottom: 19px;
}

.auth-inner-main .forget-para {
  color: #000000;
  font-size: 18px;
  line-height: 22px;
  font-family: "Urbanist-Regular";
  margin-bottom: 40px;
}

.auth-inner-main .auth-footer-terms-para {
  font-size: 14px;
  line-height: 20px;
  font-family: "Urbanist-Regular";
  margin-right: 39px;
  color: #758698;
  width: 100%;
}

.auth-footer-social-main {
  margin-bottom: 20px;
}

.auth-footer-social-main .auth-social-ul {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.auth-footer-social-main .auth-social-li {
  border: 1px solid #d2dde9;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-footer-social-main .auth-social-li .auth-social-link {
  text-decoration: none;
  max-height: 16px;
}

.auth-logo {
  width: 105px;
  height: 33px;
  object-fit: contain;
  margin-right: 10px;
}

.auth-logo-main .auth-logo-text {
  color: #000000;
  font-size: 33px;
  line-height: 33px;
  font-family: "Urbanist-Regular";
  position: relative;
  padding-left: 10px;
}

.auth-logo-main .auth-logo-text::before {
  position: absolute;
  content: "";
  background-color: #000000;
  height: 33px;
  width: 1px;
  margin-right: 10px;
  left: 0px;
}

.auth-logo-main {
  display: flex;
  margin-bottom: 50px;
}

.auth-checkbox-main .checkbox-lable {
  color: #000000;
  font-family: "Urbanist-Regular";
  font-size: 16px;
  line-height: 19px;
}

.auth-checkbox-main .auth-forgot-lable {
  color: #5e55b8;
  font-family: "Urbanist-Regular";
  font-size: 16px;
  line-height: 19px;
}

/* end user auth pages */

/* start user header css */


.header-sec {
  background-color: #000000;
  padding: 11px 0px;
  z-index: 99;
  position: fixed;
  top: 0px;
  width: 100%;
}

.header-sec-trans {
  background-color: transparent;
  padding: 13px 15px 14px;
  position: relative;
  z-index: 99;
}

.header-nav-main .header-nav-ul {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.header-nav-main .header-nav-li {
  margin: 0px;
  padding: 0px;
  width: max-content;
}

.header-nav-main .header-nav-link {
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Urbanist-light';
  text-decoration: none;
  margin-right: 24px;
  text-transform: capitalize;
  position: relative;
}

.header-nav-main .header-nav-link:hover {
  color: rgba(112, 104, 234, 1);
}

.header-nav-main .header-nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: rgba(112, 104, 234, 1);
  transition: width .5s;
  top: 20px;
  position: absolute;
}

.header-nav-main .header-nav-link:hover::after {
  width: 100%;
}

.header-nav-main .header-connect-btn {
  padding: 0px;
  min-width: max-content;
}

.header-nav-main .header-nav-li:last-child .header-nav-link {
  margin-right: 0px;
}

.header-btn-main .header-btn:last-child {
  margin: auto;
}

.header-nav-li .connect-btn {
  padding: 0px;
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  font-family: "Urbanist-Regular";
  text-transform: capitalize;
}

.header-btn-main .header-btn {
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  font-family: "Urbanist-Regular";
  margin-right: 8px;
  text-transform: capitalize;
  height: 34px;
  border-radius: 7px;
  position: relative;
}

.header-btn-main .header-switch-btn,
.header-btn-main .header-switch-btn:hover {
  min-width: 90px;
  background-color: rgba(112, 104, 234, 1);
}

.header-btn-main .header-signout-btn {
  border: 1.5px solid #7068EA;
  min-width: 90px;
}

.header-btn-main .header-signout-btn:hover {
  box-shadow: none;
  background-color: transparent;
}

.header-btn-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-logo {
  margin-right: 9px;
  object-fit: contain;
  max-width: 110px;
  height: 25px;
}

.squre-icon {
  margin-right: 12px;
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-left: 24px;
}

.display-row {
  align-items: center;
}

.header-logo-box .token-logo-text {
  color: #FFFFFF;
  font-size: 23px;
  line-height: 30px;
  font-family: "Hauora-Light";
  position: relative;
  padding-left: 10px;
}

.header-logo-box .token-logo-text::before {
  position: absolute;
  content: "";
  background-color: #FFFFFF;
  height: 33px;
  width: 1px;
  margin-right: 10px;
  left: 0px;
}

/* end user header css */

/* start user footer css */

.footer-sec {
  background-color: #000000;
  padding: 128px 0px 40px;
}

.back-to-login {
  text-decoration: none;
  display: flex;
  color: #5E55B8;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Urbanist-Medium';
}

.back-to-login svg {
  font-size: 13px;
  margin-right: 5px;
}



.footer-inner-main {
  /* max-width: 1125px;
      margin: auto; */

  /* padding-left: 365px;
      padding-right: 291px; */

  /* padding-left: 350px; */
  padding-left: 275px;
  padding-right: 276px;
}

.footer-privacy-main .footer-para-top {
  font-size: 11px;
  line-height: 17px;
  color: #FCFCFD;
  text-align: center;
}

.footer-privacy-main .footer-para {
  font-size: 11px;
  line-height: 17px;
  color: #FCFCFD;
  text-align: center;
  padding-top: 10px;
}

.footer-privacy-main {
  padding-top: 60px;
}

.footer-logo-box,
.footer-social-main {
  display: flex;
}

.footer-logo {
  width: 100%;
  max-width: 160px;
  height: 40px;
  object-fit: contain;
}

.footer-col .footer-title {
  color: #fcfcfd;
  font-size: 23px;
  line-height: 28px;
  font-family: "Urbanist-Regular";
  margin-bottom: 20px;
}

.footer-nav-main .footer-nav-ul {
  padding: 0px;
  margin: 0px;
}

.footer-nav-main .footer-nav-li {
  padding: 0px;
}

.footer-nav-main .footer-nav-link {
  color: #fcfcfd;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-light";
  margin-bottom: 20px;
  text-decoration: none;
  position: relative;
}

.footer-nav-main .footer-nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: rgba(112, 104, 234, 1);
  transition: width .5s;
  top: 22px;
  position: absolute;
}

.footer-nav-main .footer-nav-link:hover::after {
  width: 100%;
}

.footer-social-main .footer-social-ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 25px;
}

.footer-social-main .footer-social-li {
  margin: 0px;
  padding: 0px;
  display: flex;
  width: max-content;
}

.footer-social-main ul li:last-child .footer-social-link {
  margin-right: 0px;
}

.footer-social-main .footer-social-link {
  text-decoration: none;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
  margin-right: 20px;
}

.footer-social-main .footer-social-link:hover {
  background: rgba(112, 104, 234, 1);
}

.footer-social-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.footer-social-ul .footer-social-li:last-child .footer-social-link .footer-social-icon {
  margin-right: 0px;
}

.footer-col .footer-page-link {
  color: #fcfcfd;
  font-size: 14px;
  line-height: 28px;
  font-family: "Urbanist-Regular";
  margin-top: 33px;
  text-align: right;
}

.bottom-footer-col {
  margin-top: 10px;
}

.footer-highligh-text {
  color: #FCFCFD;
  text-decoration: none;
}

.footer-privacy-main .footer-register-text {
  padding-top: 15px;
}

/* end user footer css */

/* start user sidebae css */

.user-sidebar .user-sidebar-ul {
  padding: 0px;
  margin: 0px;
}

.user-sidebar .user-sidebar-li {
  padding: 0px;
  margin: 0px;
}

.user-sidebar .user-sidebar-link {
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  font-size: 18px;
  line-height: 22px;
  color: #2b2b2b;
  font-family: "Urbanist-Regular";
  margin-bottom: 26px;
}


.user-sidebar-dark-text {
  color: #000000;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Urbanist-Semibold';
}

.user-sidebar {
  padding: 64px 0px 0px 0px;
}

.purple-color {
  color: #7068ea !important;
}

.user-need-sidebar .user-need-sidebar-ul,
.user-need-sidebar .user-need-sidebar-li {
  margin: 0px;
  padding: 0px;
}

.user-need-sidebar .user-need-sidebar-link {
  color: #000000;
  font-size: 15px;
  line-height: 14px;
  font-family: "Urbanist-Regular";
  margin-bottom: 25px;
  text-decoration: none;
}

.primary-color {
  color: rgba(112, 104, 234, 1) !important;
}

.user-need-sidebar .sidebar-hightlight-text {
  color: #000000;
  font-size: 18px;
  line-height: 17px;
}

.user-need-sidebar {
  padding: 33px 0px 39px 0px;
}

/* end user sidebar css */

/* start page structure for user side */

.page-right-main {
  padding: 70px 0px 0px 76px;
  flex: 1;
}

.page-inner-main {
  display: flex;
}

.page-left-main {
  max-width: 165px;
  width: 100%;
  flex: 0 0 165px;
}

/* end page structure for user side */

/* start profile page css */

.tabs-main .tab {
  font-size: 16px;
  line-height: 19px;
  font-family: 'Urbanist-Medium';
  color: #000000;
  text-transform: uppercase;
  padding: 0px 0px 10px 0px;
  margin-right: 45px;
  min-height: 0px;
  min-width: max-content;
}

.tabs-main .tab.Mui-selected {
  color: #7668fe;
}

.tabs-main .MuiTabs-indicator {
  background-color: #7668fe;
  height: 2px;
}

.tabpanel>.MuiBox-root {
  padding: 19px 0px 0px 0px;
}

.page-right-main .page-titles {
  color: #3c3480;
  font-size: 23px;
  line-height: 28px;
  font-family: "Urbanist-Semibold";
  margin-bottom: 19px;
}

.profile-main {
  width: calc(100% + 23px);
}

.primary-btn-main .profile-btn , .primary-btn-main .profile-btn:hover {
  max-width: 160px;
  font-family: 'Urbanist-light';
}
.my-profile-main {
  max-width: 780px;
}
.profile-input-box .form-group .form-control input {
  color: rgba(73, 84, 99, 0.7);
  font-size: 14px;
  line-height: 17px;
  border: 0px;
}

.primary-btn-main .fa2-btn , .primary-btn-main .fa2-btn:hover {
  min-width: 160px;
  font-family: 'Urbanist-light';
}

.page-main-box {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 8%);
  padding: 40px;
}

.tabs-main-box .tabs-main {
  min-height: 0px;
  border-bottom: 1px solid #e6effb;
}

.profile-display-row {
  margin-left: -10px;
  margin-right: -10px;
}

.profile-input-box {
  padding-left: 10px;
  padding-right: 10px;
}
.profile-main {
  margin-bottom: 19px;
}

.profile-main .profile-para {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Regular";
  margin-bottom: 30px;
}

.profile-btn-left {
  display: flex;
  align-items: center;
}

.grey-btn-main .grey-btn {
  color: #495463;
  font-size: 12px;
  line-height: 14px;
  font-family: "Urbanist-SemiBold";
  width: 100%;
  margin-right: 10px;
  background-color: #d2dde9;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.text-btn-main .text-btn {
  color: #758698;
  font-size: 14px;
  line-height: 12px;
  font-family: "Urbanist-Regular";
  width: 100%;
  margin-right: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-btn-main .current-btn {
  min-width: 101px;
}

.grey-btn-main .disable-btn {
  max-width: 74px;
}

.security-setting-main .page-mid-title {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-SemiBold";
  margin-bottom: 23px;
}

.profile-switch {
  margin-bottom: 20px;
  margin-left: 10px;
}

.security-setting-main .manage-noti-text {
  margin-top: 40px;
}

.manage-noti {
  margin-bottom: 50px;
}

.error-text-main .blue-error-text {
  color: rgba(112, 104, 234, 0.9);
  font-size: 12px;
  line-height: 14px;
  font-family: "Urbanist-Regular";
  display: flex;
}

.error-text-main .red-error-text {
  color: #ff6868e5;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  font-family: "Urbanist-Regular";
  margin-bottom: 19px;
  margin-top: 5px;
}

.error-icon {
  margin-right: 7px;
  height: 13px;
  width: 13px;
  object-fit: contain;
}

/* end profile page css */

/* start switch css */

.switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 17px;
  font-family: "Urbanist-Regular";
  color: #495463;
  padding-left: 15px;
}

.switch-main .MuiSwitch-root {
  margin: 0px;
}

/* end switch css */

.white-border-btn-main .white-border-btn {
  color: #ffffff;
  font-size: 11px;
  line-height: 13px;
  font-family: "Urbanist-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 100%;
  padding: 7px;
}

.white-border-btn-main .dash-btn {
  min-width: 90px;
  height: 24px;
}

.dashboard-card {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  height: 215px;
  padding: 26px 31px 25.5px 31px;
  width: 100%;
}

.dashboard-card-1 {
  background: linear-gradient(255.52deg, #544DB7 0.62%, #766DF9 100.91%, #994EEE 100.93%, #994EEE 100.94%);
  max-width: 419px;
}

.dashboard-card-2 {
  background: linear-gradient(253.63deg, #9B4DEB 17.91%, #544DB7 96.97%);
  max-width: 390px;
  min-width: 390px;
}

.dashboard-card-3 {
  background: linear-gradient(253.18deg, #6E66E6 26.43%, #9A4EEC 90.17%);
  max-width: 390px;
  min-width: 390px;
  margin-left: 19px;
}

.dash-card-flex {
  display: flex;
  margin-bottom: 20px;
}

.dash-icon-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52.35px;
  height: 52.99px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  margin-right: 15px;
}

.dash-icons {
  height: 23.99px;
  width: 28.35px;
  object-fit: contain;
}

.dash-icon-text-main .dash-icon-text {
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
}

.dash-icon-text-main .dash-icon-price {
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
}

.dash-span-text {
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  font-family: 'Urbanist-light';
  margin-left: 7px;
}

.dash-card-content-flex {
  display: flex;
  justify-content: space-between;
  /* align-items: end; */
  margin-top: 69px;
}

.dash-card-content .dash-price-text {
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 2px;
  font-family: 'Urbanist-Medium';
  min-height: 22px;
}

.dash-card-content .dash-price-label {
  font-size: 12px;
  line-height: 14px;
  font-family: "Urbanist-light";
  color: #ffffff;
}

.green-btn-main .green-btn,
.green-btn-main .green-btn:hover {
  background: #84e9b4;
  border-radius: 5px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.private-sale-space-between {
  justify-content: space-between;
}

.green-btn-main .running-btn , .green-btn-main .running-btn:hover {
  min-width: 90px;
  height: 26px;
  font-size: 11px;
  line-height: 13px;
  text-transform: capitalize;
  margin-left: 10px;
}

.green-btn-main .grey-btn,
.green-btn-main .grey-btn:hover {
  background: #949494;
  border-radius: 5px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 5px;
}

.green-btn-main .purple-btn,
.green-btn-main .purple-btn:hover {
  background: #7068ea;
  border-radius: 5px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 5px;
}

.green-btn-main .status-btn,
.green-btn-main .status-btn:hover {
  min-width: 90px;
  height: 26px;
  font-size: 11px;
  line-height: 13px;
  text-transform: capitalize;
}

.price-card-main {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 453px;
  padding: 33.5px 32px;
}

.price-flex {
  align-items: center;
}

.price-flex .card-price {
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  font-family: "Urbanist-Semibold";
}

.price-flex .price-span {
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  font-family: "Urbanist-Regular";
}

.dash-price-card {
  margin-top: 23px;
}

.dash-page-right-main {
  padding: 30px 0px 0px 76px;
}

.dash-flex-end-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.price-card-inner .token-price-para {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  max-width: 221px;
  font-family: "Urbanist-Regular";
  margin: 24px 0px 34px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.primary-btn-main .buy-token-btn,
.primary-btn-main .buy-token-btn:hover {
  margin-right: 33px;
  min-width: 142px;
  height: 46px;
  padding: 14.5px;
  font-family: "Urbanist-light";
}

.green-btn-main .buy-token-btn {
  min-width: 200px;
  max-width: 200px;
  height: 46px;
  padding: 14.5px;
  margin-right: 33px;
}

.dash-token-data-content .dash-token-data-text {
  font-size: 13px;
  line-height: 16px;
  color: #7068ea;
  text-transform: capitalize;
  margin-bottom: 5px;
  font-family: "Urbanist-Regular";
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.dash-token-data-content .dash-token-data-price {
  font-size: 22px;
  line-height: 26px;
  font-family: "Urbanist-Regular";
  color: #000000;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.dash-token-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 33px;
}

.dash-token-flex a {
  text-decoration: none;
}

.dash-token-content .dash-token-text {
  font-size: 12px;
  line-height: 14px;
  font-family: "Urbanist-Regular";
  color: #7068ea;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.dash-token-content .dash-token-price {
  font-size: 15px;
  line-height: 19px;
  font-family: 'Urbanist-Regular';
  color: #000000;
}

.dash-token-value-main {
  display: flex;
  margin: 20px -12.5px 24px -12.5px;
  flex-wrap: wrap;
}

.dash-token-value-box {
  width: 100%;
  height: 64px;
  padding: 15px;
  background: #e7e7e7;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-token-value-inner {
  margin: 0 12.5px;
  width: calc(100% / 4 - 25px);
}

.dash-token-value-main .dash-token-value-inner:last-child {
  margin-right: 0px;
}

.dash-token-value-main .dash-token-value-text {
  font-size: 24px;
  line-height: 29px;
  font-family: "Urbanist-Regular";
  color: #000000;
}

.dash-token-value-main .dash-token-time-text {
  font-size: 13px;
  line-height: 16px;
  font-family: "Urbanist-Regular";
  color: #000000;
  text-align: center;
}

.dashboard-referral-flex {
  display: flex;
  justify-content: space-between;
}

.dashboard-referral-flex .dash-referral-text {
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  font-family: "Urbanist-Semibold";
}

.dash-referral-span-text {
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  font-family: "Urbanist-Regular";
}

.dashboard-referral-flex .dash-referral-para {
  color: #000000;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Urbanist-Medium';
}

.dashboard-referral-col {
  margin-top: 23px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  padding: 35.5px 40.86px 35.5px 43.5px;
  height: 453px;
}

/* start progrss bar css */

.progress-bar-main {
  width: 100%;
  position: relative;
}

.dash-progressbar {
  max-width: 355px;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
  /* background: linear-gradient(90deg,
      #2c54b0 22.94%,
      #df43ef 45.43%,
      #eb2b01 100%); */
  /* background: linear-gradient(269.99deg, #2c54b0 -26.06%, #df43ef 39.43%, #eb2b01 82%); */
  background: linear-gradient(90deg, #6158FF 0%, #7970FA 51.22%, #9850EF 100%);
  border-radius: 0px;
  height: 5px;
}

.progress-bar-main .MuiLinearProgress-root {
  border-radius: 0px;
  height: 5px;
  background-color: #e6e6e6 !important;
}

.progress-bar-text {
  position: absolute;
  right: 5px;
  top: -22px;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  font-family: "Urbanist-Regular";
}

/* end progrss bar css */

.primary-border-btn-main .primary-border-btn {
  border: 1.5px solid #6e66e6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  text-transform: capitalize;
  font-family: "Urbanist-Regular";
}

.primary-btn-main .copy-btn {
  min-width: 103px;
  height: 40px;
}

.primary-border-btn-main .history-btn {
  max-width: 105px;
  min-width: 105px;
  margin-left: 10px;
  font-family: 'Urbanist-Medium';
  height: 40px;
}

.dashboard-graph-flex {
  display: flex;
  width: 100%;
  margin: 27px 0px 27px;
}

.dash-graph-main {
  max-width: 660px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  margin-right: 19px;
  height: 327px;
  padding: 30.5px 30px;
}

.dash-recent-main {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  /* height: 327px; */
  min-height: 390px;
  padding: 30.5px 30px;
}

.grey-right-errow {
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-left: 8px;
}

.dashboard-referral-flex .dash-view-text {
  font-size: 16px;
  line-height: 19px;
  font-family: 'Urbanist-Semibold';
  color: #758698;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.user-dashboard-main {
  width: calc(100% + 3px);
}

.user-dash-first-row-left {
  max-width: 419px;
  width: 100%;
  flex: 0 0 419px;
}

.user-dash-first-row-right {
  flex: 1;
  margin-left: 19px;
}

.private-sale-final-main {
  width: calc(100% + 91px);
}

.private-sale-final-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -17px;
  margin-right: -17px;
}

.private-sale-final-col {
  margin-bottom: 35px;
  width: calc(100% / 3 - 33px);
  margin: 0px 16.5px 34px 16.5px;

}

.private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(3n + 3) {
  margin-right: 0px;
}


.private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(3n + 1) {
  padding-left: 0px;
  margin-left: 0px;
}

.text-copy-box {
  padding: 11px 20px 12px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  /* max-width: 476px; */
  max-width: 489px;
  width: 100%;
}

.text-copy-box .text-copy {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  font-family: "Urbanist-Medium";
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.dash-text-copy-box {
  margin-right: 10px;
}

.dash-referral-btn-main {
  margin-top: 56px;
  margin-bottom: 31px;
}

.dash-transaction-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 31px;
}

.dash-transaction-inner .dash-tarasaction-titles {
  font-size: 18px;
  line-height: 22px;
  color: #414c5a;
  font-family: "Urbanist-Semibold";
  margin-bottom: 5px;
}

.dash-transaction-inner:last-child {
  margin-bottom: 0px;
}

.dash-transaction-inner .dash-tarasaction-lables {
  font-size: 16px;
  line-height: 19px;
  color: #758698;
  font-family: "Urbanist-Regular";
}

.dash-transaction {
  margin-top: 30px;
  height: 200px;
  overflow: auto;
}

.dash-trasction-box {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-right: 5px;
}

.dash-transaction-yellow-box {
  border: 1px solid #f6c243;
}

.dash-transaction-green-box {
  border: 1px solid #00d285;
}

.dash-trasction-icons {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.referral-data-head-row {
  display: flex;
  justify-content: space-between;
}

.referral-right-main {
  padding: 30px 0px 67px 75px;
}

.referral-data-head-row .referral-col-title {
  font-size: 12px;
  line-height: 14px;
  color: #7668FE;
  font-family: 'Urbanist-Semibold';
  margin-bottom: 14px;
}

.referral-data-inner .dash-referral-titles {
  font-size: 14px;
  line-height: 17px;
  color: #414c5a;
  font-family: "Urbanist-SemiBold";
  margin-bottom: 6px;
}

.referral-data-inner .dash-referral-lables {
  font-size: 12px;
  line-height: 14px;
  color: #758698;
  font-family: "Urbanist-Regular";
}

.referral-data-inner-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6effb;
}

.yellow-border-btn-main .yellow-border-btn {
  font-size: 14px;
  line-height: 17px;
  color: #414c5a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
  min-width: 105px;
  height: 30px;
  border: 1px solid #f6c243;
  border-radius: 3px;
  text-transform: capitalize;
}


.referral-data-inner {
  padding: 15px 0px;
}

.referral-data-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.referral-data-col-1 {
  max-width: 170px;
  width: 100%;
  flex: 0 0 170px;
}

.referral-data-col-2 {
  max-width: 170px;
  width: 100%;
  flex: 0 0 170px;
}




.referral-data-col-4 {
  max-width: 177px;
  width: 100%;
  flex: 0 0 177px;
}

.user-dash-third-row {
  width: 100%;
  margin-bottom: 44px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  padding: 36px 0px 27px;
}



.dash-token-heading-main .dash-token-heading {
  font-size: 16px;
  line-height: 19px;
  color: #414c5a;
  font-family: "Urbanist-Medium";
  padding-bottom: 10px;
  border-bottom: 1px solid #414C5A;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 80px;
}

.modal .modal-twofa-inner {
  max-width: 686px !important;
}

.dash-token-data-flex {
  padding: 9.5px 10px 9.5px 18px;
  background: #ffffff;
  margin-bottom: 4px;
  display: flex;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.06));
  min-height: 41px;
}

.dash-token-data-flex .dash-token-label {
  font-size: 18px;
  line-height: 22px;
  color: #414c5a;
  font-family: "Urbanist-Regular";
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.dash-token-data-round {
  height: 22px;
  width: 22px;
  margin-right: 13px;
  border-radius: 50%;
}

.dash-token-data-round-1 {
  background: #3167f7;
}

.dash-token-data-round-2 {
  background: #5f5dc0;
}

.dash-token-data-round-3 {
  background: #89508a;
}

.dash-token-data-round-4 {
  background: #9f4972;
}

.dash-token-data-round-5 {
  background: #b5445d;
}

.dash-token-data-round-6 {
  background: #cc3c3f;
}

.dash-token-data-round-7 {
  background: #f63329;
}

.dash-token-data-round-8 {
  background: #ff4f29;
}

.dash-token-data-round-9 {
  background: #e90000;
}

.dash-token-main .dash-token-data-flex:last-child {
  margin-right: 0px;
}

.dash-token-col-1 {
  max-width: 190px;
  width: 100%;
  flex: 0 0 190px;
  margin-right: 5px;
}

.dash-token-col-2 {
  max-width: 160px;
  width: 100%;
  flex: 0 0 160px;
  margin-right: 5px;
}

.dash-token-col-3 {
  max-width: 160px;
  width: 100%;
  flex: 0 0 160px;
  margin-right: 5px;
}

.dash-token-col-4 {
  max-width: 210px;
  width: 100%;
  flex: 0 0 210px;
  margin-right: 5px;
}

.dash-token-col-5 {
  max-width: 160px;
  width: 100%;
  flex: 0 0 160px;
  margin-right: 5px;
}

.dash-token-col-6 {
  max-width: 160px;
  width: 100%;
  flex: 0 0 160px;
  margin-right: 5px;
}

.dash-token-col-7 {
  max-width: 160px;
  width: 100%;
  flex: 0 0 160px;
}

.grey-check-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.dash-token-check-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.gradiant-border {
  height: 5px;
  width: 1235px;
  /* background: linear-gradient(290deg,
      #3167f7 0%,
      #e142ef 56.8%,
      #fa3005 96.12%,
      #10d75c 110.71%,
      #b7a2af 123.61%); */
  background: linear-gradient(180deg, #6158FF 0%, #7970FA 51.22%, #9850EF 100%);
  display: none;
}

.dots-drop-main .dots-drop-btn {
  min-width: max-content;
}

.private-sale-final-right-main {
  padding: 36px 0px 17px 76px;
}

.private-sale-right-main {
  padding: 70px 0px 78px 76px;
}

.private-card-content-flex {
  justify-content: initial;
}

.private-card-space-between {
  margin: 20px 0px;
  justify-content: initial;
}

.private-card-space-between .dash-card-content:first-child {
  margin-right: 94px;
}

/* .private-card-content-flex .dash-card-content:first-child {
  margin-right: 94px;
} */

.private-sale-usd-main-flex {
  display: flex;
  flex-direction: initial !important;
}

.dashboard-card .private-card-wrap {
  margin-left: -10px;
  margin-right: -10px;
}

/* .private-card-content-flex .private-card-content:nth-child(1) {
  max-width: 76px;
  width: 100%;
  flex: 0 0 76px;
  margin-right: 51px;
}

.private-card-content-flex .private-card-content:nth-child(2) {
  max-width: 80px;
  width: 100%;
  flex: 0 0 80px;
  margin-right: 50px;
}

.private-card-content-flex .private-card-content:nth-child(3) {
  max-width: 73px;
  width: 100%;
  flex: 0 0 73px;
} */

.private-sale-col-left {
  max-width: 848px;
  width: 100%;
  flex: 0 0 848px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.12));
  border-radius: 2px;
  background: #fff;
  padding: 40px 30px;
  height: 100%;
  min-height: 653px;
}

.private-sale-col-right {
  flex: 1;
  margin-left: 19px;
}

.private-sale-data-main .private-sale-titles {
  font-size: 21px;
  line-height: 25px;
  color: #3c3480;
  margin-bottom: 10px;
  font-family: "Urbanist-Semibold";
}

.private-sale-data-main .private-sale-para {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-family: "Urbanist-Medium";
  margin-bottom: 40px;
  margin-top: 30px;
}

.private-sale-usd-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.private-sale-usd-icons {
  width: 14.4px;
  height: 13.13px;
  margin-right: 10px;
}

.private-sale-usd-box .private-sale-usd-text {
  font-size: 18px;
  line-height: 22px;
  color: #4b5462;
  font-family: "Urbanist-Semibold";
  display: flex;
  align-items: center;
}

.private-sale-usd-box .private-sale-usd-para {
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Medium";
  color: #000000;
}

.private-sale-usd-box {
  max-width: 385px;
  height: 52px;
  width: 100%;
  background: #e6effb;
  border: 2px solid #7868fe;
  border-radius: 5px;
  padding: 15px 20px;
}

.private-sale-usd-data .private-sale-data-lable {
  font-size: 14px;
  line-height: 17px;
  font-family: "Urbanist-Medium";
  color: #000000;
}

.private-sale-usd-data .private-sale-usd-amount {
  font-size: 21px;
  line-height: 25px;
  color: #3c3480;
  font-family: "Urbanist-Semibold";
  word-break: break-word;

}

.private-sale-usd-data {
  display: flex;
  align-items: center;
  margin-left: 43px;
}

.private-sale-usd-data .private-sale-data-equal {
  margin-right: 15px;
}

.private-sale-flex {
  display: flex;
  margin-bottom: 40px;
}

.private-checkbox-main .checkbox-lable {
  margin-left: 0px;
  margin-right: 0px;
}

.private-checkbox-main .private-checkbox-lable {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Medium";
  margin-right: 50px;
}

.private-checkbox-main .private-checkbox-spanlable {
  color: #5e55b8;
}

.private-checkbox-main .MuiSvgIcon-root {
  width: 15px;
  height: 15px;
  border-radius: 2px;
}

.private-amount-main {
  max-width: 788px;
  height: 99px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e6effb;
  display: flex;
  align-items: center;
  padding: 25px 30px;
}

.private-amount-col {
  display: flex;
  flex-direction: column;
  margin-right: 90px;
}

.private-sale-col-left .private-amount-para {
  font-size: 15px;
  line-height: 18px;
  color: #ff7878;
  font-family: "Urbanist-Regular";
  margin: 10px 0px 40px 0px;
}

.private-sale-col-left .private-token-para {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  font-family: "Urbanist-Regular";
}

.primary-error-icon {
  width: 12.99px;
  height: 12px;
  object-fit: contain;
  margin-right: 7px;
}

.primary-btn-main .make-payment-btn,
.primary-btn-main .make-payment-btn:hover {
  max-width: 235px;
  margin-bottom: 40px;
  font-family: "Urbanist-light";
}

.private-amount-col .private-amount-lable {
  font-size: 16px;
  line-height: 19px;
  color: #4b5462;
  font-family: "Urbanist-Medium";
  margin-bottom: 4px;
}

.private-amount-col .private-amount-data {
  font-size: 21px;
  line-height: 25px;
  color: #3c3480;
  font-family: "Urbanist-Semibold";
}

.private-sale-price-card {
  margin: 19px 0px;
}

.private-price-card-main {
  max-width: 390px;
  height: 343px;
}

.private-card-content-flex {
  margin: 20px 0px;
}

.private-sale-card-1 {
  background: linear-gradient(254.43deg, #5A50D3 13.36%, #9B4DEB 88.82%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  height: 203px;
}

.round-icons {
  height: 13px;
  width: 13px;
}

.private-sale-card-2 {
  background: linear-gradient(250.8deg, #9A4EEC 16.18%, #5E55EF 81.13%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  height: 203px;
}

.private-sale-card-2 .private-sale-card-title {
  font-size: 22px;
  line-height: 26px;
  font-family: "Urbanist-Medium";
  color: #ffffff;
}

.private-card-content-flex:last-child {
  margin-bottom: 0px;
}

.peivate-sale-border {
  border: 1px solid #d2dde9;
  margin-bottom: 20px;
}

.private-sale-main {
  width: calc(100% + 23px);
}

.private-payment-modal .payment-modal-inner {
  min-width: 658px;
  max-width: 658px;
  padding: 34.5px 57px 40px 54px;
}

.payment-modal-inner .payment-modal-title {
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  font-family: "Urbanist-Semibold";
  margin-bottom: 15px;
}

.payment-modal-inner .payment-modal-para {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Urbanist-Regular';
  color: #000000;
}

.payment-modal-inner .payment-condition-para {
  color: #4b5462;
  font-size: 13px;
  line-height: 16px;
  font-family: 'Urbanist-Regular';
  margin: 8px 0px 15px;
}
.payment-modal-inner .private-new-token-para{
  display: none;
}
.payment-modal-inner .payment-modal-para-1 {
  margin-bottom: 10px;
}

.payment-modal-inner .payment-modal-para-2 {
  margin-bottom: 20px;
}

.primary-btn-main .payment-buy-token-btn,
.primary-btn-main .payment-buy-token-btn:hover {
  max-width: 235px;
  margin: 10px 0px 0px;
  font-family: 'Urbanist-light';
}

.payment-modal-links {
  font-size: 13px;
  line-height: 16px;
  font-family: 'Urbanist-light';
  color: #6E66E6;
  text-decoration: none;
}

.payment-error-flex .payment-error-para {
  font-size: 13px;
  line-height: 16px;
  color: #4b5462;
  font-family: "Urbanist-Semibold";
}

.payment-checkbox-main .payment-checkbox-lable {
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  font-family: "Urbanist-Medium";
}

.payment-checkbox-main .checkbox-lable {
  margin: 0px;
}

.payment-radio-content {
  margin-left: 12px;
}

.payment-modal-radio-box .payment-radio-lable {
  margin-right: 0px;
  margin-left: 0px;
}

.payment-radio-content .payment-modal-error-para {
  font-size: 13px;
  line-height: 20px;
  color: #4B5462;
  font-family: 'Urbanist-Regular';
}

.payment-radio-content .payment-radio-text {
  color: #000000;
  font-size: 13px;
  line-height: 20px;
  font-family: "Urbanist-Semibold";
  margin-bottom: 8px;
}

.payment-modal-radio-box .MuiRadio-root {
  padding: 0px;
}

.payment-modal-radio-box {
  max-width: 100%;
  width: 100%;
  height: 100%;
  min-height: 91px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 1.5px solid #e6effb;
}
.pay-pro-first-radio .MuiTypography-body1{
margin-left: 15px;
}
.payment-modal-radio-box:last-child {
  margin-right: 0px;
}
.radio-box-main label{
  margin: 0 !important;
  padding: 12px 12px 12px 18px;
}

.payment-modal-radio-box .MuiFormControlLabel-label,
.radio-txt-main div:first-child {
  color: #000000;
  font-size: 13px;
  line-height: 20px;
  font-family: "Urbanist-Semibold";
}
.radio-txt-main div:nth-child(2) p{
  color: #000000;
  font-family: 'Urbanist-Regular';
  font-size: 11px;
}

.payment-modal-radio-box1 {
  border: 1.5px solid #6e66e6;
}

.payment-modal-radio-box2 {
  border: 1.5px solid #d2dde9;
}
.pay-pro-wa-img{
  display: flex;
  align-items: center;
}
.modal-highlight-text {
  color: #6e66e6;
  font-family: "Urbanist-Semibold";
}

.private-payment-modal .MuiModal-backdrop {
  background: rgba(0, 0, 0, 0.2);
}

.wallet-modal .modal-wallet-text {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Urbanist-Semibold';
  color: #000000;
  margin-bottom: 17px;
}

.modal-wallet-flex .modal-wallet-widthdro-control .MuiInputBase-root {
  padding: 0px;
  border: 1px solid #d2dde9;
  border-radius: 3px;
  width: 250px;
}

.modal-wallet-flex .modal-wallet-widthdro-control input {
  color: #4b5462;
  font-size: 20px;
  line-height: 24px;
  font-family: "Urbanist-SemiBold";
  padding: 13px 17px;
}

.modal-wallet-widthdro-control-text p {
  color: #4b5462;
  font-size: 20px;
  line-height: 24px;
  font-family: "Urbanist-SemiBold";
  margin-right: 15px;
}

.wallet-modal .wallet-modal-para {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  font-family: 'Urbanist-Regular';
  margin-bottom: 15px;
}

.wallet-modal .wallet-modal-title {
  color: #6e66e6;
  font-size: 22px;
  line-height: 22px;
  font-family: "Urbanist-Semibold";
  margin-bottom: 20px;
}

.modal .modal-inner {
  background: #ffffff;
  border-radius: 10px;
}

.primary-btn-main .payment-link-btn,
.primary-btn-main .payment-link-btn:hover {
  max-width: 90px;
  height: 24px;
  width: 100%;
  padding: 5px;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
  font-family: 'Urbanist-Regular';
  display: none;
}

.primary-btn-main .wallet-link-btn,
.primary-btn-main .wallet-link-btn:hover {
  max-width: 90px;
  height: 24px;
  width: 100%;
  padding: 5px;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
  font-family: 'Urbanist-Regular';
  margin-bottom: 15px;
}

/* .modal .payment-modal-inner {
  height: calc(100% - 80px);
  overflow: auto;
  min-height: 707px;
} */

/* .modal .modal-inner {
  height: calc(100% - 80px);
  overflow: auto;
} */

.wallet-modal .modal-wallet-inner {
  max-width: 614px;
  padding: 40px 48px 40px 50px;
  /* min-height: 570px; */
}

.wallet-modal .wallet-modal-subpara {
  font-size: 16px;
  line-height: 22px;
  font-family: "Urbanist-Medium";
  color: #6e66e6;
  margin-bottom: 30px;
  max-width: 515px;
  width: 100%;
}

.primary-btn-main .wallet-refresh-btn {
  min-width: 250px;
  height: 51px;
  font-family: 'Urbanist-Regular';
}

.primary-border-btn-main .wallet-cancel-btn {
  min-width: 250px;
  height: 51px;
  color: #000000;
  font-family: 'Urbanist-Regular';
}

.wallet-btn-modal-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-saccner-main {
  width: 115px;
  height: 115px;
  border: 1px solid #d2dde9;
  border-radius: 5px;
  padding: 5px;
  margin-right: 17px;
}

.wallet-saccner-data .wallet-scanner-data-text {
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Semibold";
  color: #000000;
  margin-bottom: 15px;
}

.wallet-saccner-data .wallet-scanner-span-text {
  color: #6e66e6;
  font-family: "Urbanist-Semibold";
}

.wallet-sacnner-data-field {
  min-width: 409.4px;
  height: 55px;
  width: 100%;
  border: 1px solid #d2dde9;
  border-radius: 3px;
}

.wallet-sacnner-data-field {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 15px;
}

.wallet-sacnner-data-field .wallet-usd-copy-text {
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Semibold";
  color: #4b5462;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.wallet-usd-icon {
  width: 14.4px;
  height: 13.13px;
  object-fit: contain;
  margin-right: 20px;
  position: absolute;
  top: 23px;
  left: 15px;
}

.wallet-copy-bg {
  width: 45px;
  height: 45px;
  background: #e6effb;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.wallet-scanner-flex {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}

.wallet-input-box {
  position: relative;
}

.wallet-input-box .wallet-text-control input {
  min-width: 409.4px;
  width: 100%;
  border: 1px solid #d2dde9;
  border-radius: 3px;
  padding: 5px 5px 5px 50px;
  height: 43.2px;
  color: #4B5462;
  font-size: 16px;
  line-height: 19px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-family: 'Urbanist-Semibold';
}

.wallet-input-box .wallet-text-control input::placeholder {
  color: #4B5462;
  font-size: 16px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 19px;
  font-family: 'Urbanist-Semibold';
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.primary-btn-main .twofa-btn {
  max-width: 170px;
}

.modal-twofa-inner .twofa-modal-title {
  font-size: 24px;
  line-height: 29px;
  color: #342d6e;
  font-family: "Urbanist-Semibold";
  margin-bottom: 20px;
}

.modal-twofa-inner .twofa-modal-step-text {
  font-size: 16px;
  line-height: 22px;
  font-family: "Urbanist-Medium";
  color: #495463;
  max-width: 538px;
  width: 100%;
}

.modal-twofa-inner {
  padding: 50.5px 50px;
}

.modal-twofa-inner .twofa-modal-step-lable {
  font-size: 16px;
  line-height: 22px;
  font-family: "Urbanist-Semibold";
  color: #495463;
  margin-right: 5px;
}

.twofa-highlight-text {
  font-size: 16px;
  line-height: 22px;
  font-family: "Urbanist-Regular";
  color: #5e55b8;
}

.twofa-step-flex:last-child {
  margin-bottom: 12px;
}

.twofa-step-flex {
  margin-bottom: 16px;
}

.twofa-scanner-main {
  height: 160px;
  width: 160px;
}

.twofa-scanner-img {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.twofa-input-box .twofa-text-control input {
  min-width: 377px;
  padding: 8.5px 15px;
  max-width: 409px;
  width: 100%;
}

.header-switch-popup {
  position: relative;
}

.header-switch-popup .header-popup-main {
  width: 133px;
  height: 100px;
  background-color: #000000;
  padding: 10px 21.5px 12px;
  box-shadow: none;
}

.header-popup-switch-main .MuiPaper-root {
  top: 56px !important;
  margin-left: 22px;
}

.header-popup-switch-main .header-popup-btn {
  max-width: 90px;
  width: 100%;
  height: 34px;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  border: 1.5px solid #7068EA;
}

.header-popup-switch-main .MuiMenu-list {
  padding: 10px 21.5px;
  width: 100%;
  min-width: 133px;
  height: 100px;
  background-color: #000000;
}

.trans-head-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trans-head-row .trans-title {
  font-size: 23px;
  line-height: 28px;
  color: #3c3480;
  font-family: "Urbanist-Semibold";
}

.trans-btn-main .trans-btn,
.trans-btn-main .trans-btn:hover {
  font-size: 16px;
  font-family: 'Urbanist-Semibold';
  line-height: 19px;
  text-align: center;
  min-width: 160px;
  height: 49px;
  background: #6e66e6;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: capitalize;
}

.dash-add-end-flex {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0px 0px;
}

.plus-icon {
  width: 19px;
  height: 19px;
  object-fit: contain;
  margin-right: 17px;
}

.transaction-main {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  padding: 40px;
  overflow: hidden;
}
.trans-data-main{
  overflow: auto;
  max-height: 350px;
}
.trans-data-head-row {
  display: flex;
  justify-content: space-between;
}

.trans-data-head-row .trans-col-title {
  font-size: 12px;
  line-height: 14px;
  color: #7668fe;
  font-family: "Urbanist-Semibold";
  margin-bottom: 14px;
}

.trans-data-inner .dash-trans-titles {
  font-size: 14px;
  line-height: 17px;
  color: #414c5a;
  font-family: "Urbanist-SemiBold";
  margin-bottom: 6px;
  display: flex;
}

.trans-data-inner .dash-trans-lables {
  font-size: 12px;
  line-height: 14px;
  color: #758698;
  font-family: 'Urbanist-Regular';
}

.trans-data-inner-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6effb;
  width: 100%;
}

.yellow-border-btn-main .yellow-border-btn {
  font-size: 14px;
  line-height: 17px;
  color: #414c5a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
  min-width: 90px;
  height: 30px;
  border: 1px solid #f6c243;
  border-radius: 3px;
  font-family: 'Urbanist-Regular';
  text-transform: capitalize;
}

.dots-drop-main {
  width: 32px;
  height: 29px;
  background: #e6effb;
  border: 1px solid #e6effb;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
}

.trans-data-inner {
  padding: 19px 0px 15px;
}

.trans-data-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.trans-data-col-1 {
  max-width: 210px;
  width: 100%;
  flex: 0 0 210px;
}

.trans-data-col-2 {
  max-width: 80px;
  width: 100%;
  flex: 0 0 80px;
}

.trans-data-col-3 {
  max-width: 80px;
  width: 100%;
  flex: 0 0 80px;
}

.trans-data-col-4 {
  max-width: 100px;
  width: 100%;
  flex: 0 0 100px;
}

.trans-data-col-5 {
  max-width: 100px;
  width: 100%;
  flex: 0 0 100px;
}

.trans-data-col-6 {
  max-width: 100px;
  width: 100%;
  flex: 0 0 100px;
}
.trans-data-col-7 {
  max-width: 100px;
  width: 100%;
  flex: 0 0 100px;
}
.trans-data-col-8 {
  max-width: 180px;
  width: 100%;
  flex: 0 0 180px;
}
/* .yellow-border-btn-main .trans-data-btn {
  min-width: 90px;
} */

.trans-data-span {
  font-family: "Urbanist-Regular";
}

.trans-data-title-span {
  font-family: "Urbanist-Regular";
}

.trans-data-btn-main .trans-green-btn {
  border: 1px solid #00d285;
}

.trans-data-btn-main .trans-red-btn {
  border: 1px solid #cc5353;
  display: flex;
    flex-direction: column;
    height: 35px;
    padding: 4px 11px;
}
.trans-data-btn-main .trans-span-text{
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  color: #495463;
  font-family: 'Urbanist-Regular';
}

.trans-icons {
  width: 14.72px;
  height: 14px;
  object-fit: contain;
}

.trans-search-btn-main .trans-search-btn {
  min-width: 34.72px;
  height: 34px;
  background: #ffffff;
  border: 0.8px solid #c9cfd6;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.trans-search-btn-main {
  display: flex;
}

.trans-search-btn-main .trans-search-btn:last-child {
  margin-right: 0px;
}

.trans-search-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 30px;
  top: 28px;
}

.trans-field {
  position: relative;
  margin: 19px 0px 30px;
}

.trans-field .form-control input {
  background: #f9fcff;
  border: 1px solid #e6effb;
  padding: 0px 50px;
  height: 64.2px;
}

.trans-field .form-control input::placeholder {
  color: #000000;
}

.trans-field .MuiFormControl-root {
  width: 100%;
  min-width: 1177px;
}

.trans-search-main {
  position: absolute;
  top: 16px;
  right: 30px;
}

.purchase-history {
  margin: 60px 0px 60px;
  overflow: hidden;
  max-height: 574px;
}

.purchas-data-col-1 {
  max-width: 185px;
  width: 100%;
  flex: 0 0 185px;
}

.purchas-data-col-2 {
  max-width: 65px;
  width: 100%;
  flex: 0 0 65px;
}

.purchas-data-col-3 {
  max-width: 65px;
  width: 100%;
  flex: 0 0 65px;
}

.purchas-data-col-4 {
  max-width: 65px;
  width: 100%;
  flex: 0 0 65px;
}

.purchas-data-col-5 {
  max-width: 65px;
  width: 100%;
  flex: 0 0 65px;
}

.purchas-data-col-6 {
  max-width: 65px;
  width: 100%;
  flex: 0 0 65px;
}

.purchas-data-col-7 {
  max-width: 65px;
  width: 100%;
  flex: 0 0 65px;
}

.purchas-data-col-8 {
  max-width: 65px;
  width: 100%;
  flex: 0 0 65px;
}

.purchas-data-col-9 {
  max-width: 82px;
  width: 100%;
  flex: 0 0 82px;
}

.purchas-data-col-10 {
  max-width: 172px;
  width: 100%;
  flex: 0 0 172px;
}

.dots-drop-main .MuiPopover-paper {
  box-shadow: none;
  background-color: transparent;
}

.private-card-main {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  min-height: 453px;
  padding: 28px 32px 35px 32px;
}

.primary-btn-main .locked-btn,
.primary-btn-main .locked-btn:hover {
  min-width: 90px;
  height: 26px;
  font-family: "Urbanist-Regular";
  font-size: 11px;
  line-height: 13px;
  margin-left: 10px;
}

.private-token-value-main .private-token-value-inner:nth-child(2n + 2) {
  margin-right: 0px;
}

.private-token-value-inner {
  width: calc(100% / 2 - 25px);
  margin: 0px 12.5px;
}

.private-token-value-main {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0px 0px;
  margin-left: -12.5px;
  margin-right: -12.5px;
}

.private-token-value-box {
  width: 100%;
  height: 64px;
  background: #e7e7e7;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
}

.private-sale-wallet-card-flex {
  display: flex;
  justify-content: space-between;
}


.private-token-value-main .private-token-value-inner:last-child {
  margin-right: 0px;
}

.private-token-value-main .private-token-value-text {
  font-size: 24px;
  line-height: 29px;
  font-family: "Urbanist-Regular";
  color: #000000;
}

.private-card-main .token-price-para {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-family: "Urbanist-Medium";
  max-width: 250px;
  width: 100%;
  margin-bottom: 23px;
}

.private-token-value-main .private-token-time-text {
  font-size: 13px;
  line-height: 16px;
  font-family: "Urbanist-Medium";
  color: #000000;
  text-align: center;
  margin-bottom: 16px;
}

.grey-btn-main .withdrow-btn,
.grey-btn-main .withdrow-btn:hover {
  width: 200px;
  margin-right: 33px;
  height: 46px;
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  font-family: "Urbanist-Medium";
  background: #949494;
}
.referral-card-flex{
  align-items: center;
}
.private-token-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 23px;
}
.private-error-token-flex{
  align-items: center;
  justify-content: flex-start;
}

.primary-btn-main  .Mui-disabled {
  color: #ffffff;
  background: #d1d1d1;
}

.private-token-content .private-token-text {
  font-size: 12px;
  line-height: 14px;
  font-family: "Urbanist-Regular";
  color: #7068ea;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.private-token-content .private-token-price {
  font-size: 14px;
  line-height: 18px;
  font-family: "Urbanist-Medium";
  color: #000000;
}

.private-sale-wallet-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16.5px; 
 margin-right: -16.5px;
}

.private-card-wallet-col {
  width: calc(100% / 3 - 33px);
  margin: 0px 16.5px 34px 16.5px;
}

.private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(3n + 3) {
  margin-right: 0px;
}

.private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(3n + 1) {
  padding-left: 0px;
  margin-left: 0px;
}

.private-sale-wallet-main {
  width: calc(100% + 89px);
  /* width: calc(100% + 103px); */
  /* margin-bottom: 48px; */
}

.private-card {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  height: 160px;
  width: 100%;
  padding: 18px 31px 19px;
  max-width: 320px;
  margin-right: 14px;
}
.private-card:last-child{
  margin-right: 0px;
}

.private-card-1 {
  background: linear-gradient(257.41deg, #9B4DEB 22.71%, #433AC0 98.34%);
}
.private-card-2{
  background: linear-gradient(269.12deg, #493CC3 15.54%, #994EEE 98.71%);
}
.private-card-3 {
  background: linear-gradient(257.41deg, #9B4DEB 22.71%, #433AC0 98.34%);
}

.private-card-4 {
  background: linear-gradient(255.37deg, #5A50ED 12.51%, #994EEE 100%);
}

.private-card-flex {
  display: flex;
  max-width: 224px;
  margin-right: 78px;
  flex: 0 0 224px;
  width: 100%;
}

.private-icon-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52.35px;
  height: 52.99px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  margin-right: 15px;
}

.private-icons {
  height: 23.99px;
  width: 28.35px;
  object-fit: contain;
}

.private-icon-text-main .private-icon-text {
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
}

.private-icon-text-main .private-icon-price {
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
}

.private-span-text {
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
  margin-left: 7px;
}

.private-card-wallet-flex {
  flex: 1;
}

.private-card-wallet-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.private-card-content .private-price-text {
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 2px;
  font-family: "Urbanist-Regular";
  margin-bottom: 22px;
}

.private-card-content .private-price-label {
  font-size: 13px;
  line-height: 16px;
  font-family: "Urbanist-light";
  color: #ffffff;
  margin-bottom: 2px;
}

.private-card-row {
  margin-bottom: 29px;
  display: flex;
  width: 100%;
  margin-left: -16.5px;
  margin-right: -16.5px;
}
.primary-btn-main .make-payment-btn.Mui-disabled , .primary-btn-main .payment-buy-token-btn.Mui-disabled {
  color: #ffffff;
  background: #d1d1d1;
}

.private-card-content-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
}
.private-card-1-content-end{
  margin-top: 45px;
}
.private-wallet-inner {
  padding: 55px 50px 55px 50px;
  max-width: 650px;
}

.private-wallet-inner .private-wallet-modal-title {
  color: #3c3480;
  font-size: 24px;
  line-height: 29px;
  font-family: "Urbanist-Bold";
  margin-bottom: 26px;
}

.modal-wallet-flex .modal-wallet-lable {
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Bold";
  color: #000000;
  max-width: 97px;
  min-width: 97px;
}

.modal-wallet-box .wallet-box-inner-text {
  color: #4b5462;
  font-size: 20px;
  line-height: 24px;
  font-family: "Urbanist-SemiBold";
}

.modal-wallet-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 250px;
  padding: 15px 17px;
  border: 1px solid #d2dde9;
  border-radius: 3px;
}

.modal-wallet-flex {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  margin-top: 9px;
}

.modal-otp-box {
  min-width: 174px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 20px;
}

.modal-wallet-flex .wallet-box-inner-otp-label {
  max-width: 110px;
  margin-right: 20px;
}

.private-wallet-inner .private-wallet-modal-para {
  font-size: 16px;
  line-height: 22px;
  font-family: "Urbanist-Medium";
  color: #000000;
}

.modal-black-highlight-text {
  color: #000000;
  font-family: "Urbanist-SemiBold";
}

.primary-border-btn-main .wallet-modal-cancel-btn {
  min-width: 260px;
  height: 49px;
  color: #6e66e6;
  font-family: "Urbanist-Semibold";
}

.primary-btn-main .wallet-withdrow-btn {
  min-width: 260px;
  height: 49px;
}

.wallet-Request-btn {
  min-width: 90px;
  height: 24px;
}

.primary-btn-main .wallet-Request-btn,
.primary-btn-main .wallet-Request-btn:hover {
  min-width: 90px;
  height: 24px;
  font-size: 11px;
  line-height: 13px;
  margin-left: 10px;
}

.private-wallet-inner .modal-otp-para {
  max-width: 372px;
}

.wallet-btn-flex {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.wallet-otp-flex {
  align-items: flex-end;
}

.wallet-modal-input-box .wallet-text-control input {
  min-width: 384.4px;
  width: 100%;
  border: 1px solid #d2dde9;
  border-radius: 3px;
  padding: 5px 5px 5px 18px;
  height: 43.2px;
}

.wallet-modal-input-box .wallet-text-control input::placeholder {
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Semibold";
  color: #4b5462;
}

.wallet-modal-input-box {
  position: relative;
  margin-bottom: 0px;
}

.wallet-otp-input-box .wallet-text-control input {
  max-width: 149.4px;
  width: 100%;
  border: 1px solid #d2dde9;
  border-radius: 3px;
  padding: 5px;
  height: 43.2px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.3em;
  font-family: "Urbanist-Semibold";
  color: #4b5462;
}

.wallet-otp-input-box {
  margin-bottom: 0px;
}

.wallet-otp-input-box .wallet-text-control input::placeholder {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.3em;
  font-family: "Urbanist-Semibold";
  color: #4b5462;
}

.yellow-border-btn-main .referral-data-btn {
  min-width: 105px;
}

.referral-data-col-1 {
  max-width: 169px;
  width: 100%;
  flex: 0 0 169px;
}

.referral-data-col-2 {
  max-width: 170px;
  width: 100%;
  flex: 0 0 170px;
}

.referral-data-col-3 {
  max-width: 100px;
  width: 100%;
  flex: 0 0 100px;
}

.referral-data-col-4 {
  max-width: 176px;
  width: 100%;
  flex: 0 0 176px;
}

.referral-data-col-5 {
  max-width: 185px;
  width: 100%;
  flex: 0 0 185px;
}

.referral-data-btn-main .referral-green-btn {
  border: 1px solid #00d285;
}

.referral-data-main {
  padding: 40px 40px 29px 40px;
}

.primary-btn-main .referral-copy-btn {
  min-width: 89px;
  height: 42px;
}

.referral-text-copy-box {
  margin-right: 19px;
  max-width: 389px;
  min-width: 389px;
}

.referral-field {
  margin: 0px 0px 30px;
}

.referral-head-row {
  margin-bottom: 30px;
}

.referral-card-1 {
  background: linear-gradient(180deg, #9851F0 0%, #746CF5 100%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 37px 35px 37px 40px;
}

.referral-card-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.referral-card-content {
  margin-right: 70px;
}

.referral-card-content .referral-price-text {
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 2px;
  font-family: "Urbanist-Regular";
  margin-bottom: 2px;
}

.referral-card-content .referral-price-label {
  font-size: 12px;
  line-height: 14px;
  font-family: "Urbanist-light";
  color: #ffffff;
}

.referral-card-row {
  margin-bottom: 19px;
}

.mobile-header-main {
  display: none;
}

.privatsale-card-wrapper {
  flex-direction: column;
  width: calc(100% / 3 - 20px);
  margin: 0px 10px 20px 10px;

}

.mobile-drawer-btn-main {
  display: none;
}

.header-logo-box .mobile-menu-btn {
  display: none;
}

.header-logo-box {
  display: flex;
  align-items: center;
}

.mobile-dropdown-main {
  display: none;
}

.mobile-switch-main {
  display: none;
}

.hero-content-main .hero-titles {
  font-size: 42px;
  line-height: 54px;
  text-align: center;
  color: #ffffff;
  font-family: "Urbanist-Medium";
  margin-bottom: 50px;
}

.hero-btn-main {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.hero-btn-main .hero-btn {
  border-radius: 50px;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  font-family: "Urbanist-Regular";
  text-transform: capitalize;
  padding: 13.5px 14.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-btn-main .hero-fill-btn,
.hero-btn-main .hero-fill-btn:hover {
  background: #37abff;
  min-width: 293px;
  margin-right: 25px;
}

.hero-btn-main .hero-border-btn,
.hero-btn-main .hero-border-btn:hover {
  background: rgba(31, 31, 31, 0.55);
  border: 1.5px solid #34a9ff;
  min-width: 194px;
}

.hero-content-main .hero-sub-para {
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-family: 'Urbanist-light';
  text-align: center;
}

.banner-video {
  width: 100%;
  height: calc(100vh - 65px);
  object-fit: cover;
  display: flex;
  min-height: 1012px;
  position: relative;
  z-index: 0;
  margin-top: -65px;
}

.hero-content-main {
  width: 100%;
  padding-top: 187px;
  position: absolute;
  right: 0px;
  left: 0px;
  top: 65px;
  max-width: 1424px;
  margin: auto;
  z-index: 1;
}


.hero-first-row {
  display: flex;
  padding-top: 185px;
  justify-content: space-between;
}

.hero-progress-inner {
  min-width: 465px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-progress-main .hero-progress-txet {
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Medium";
  color: #ffffff;
}

.hero-progress-data .hero-progress-data-text {
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
}

.hero-progress-data .hero-progress-data-price {
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Medium";
  color: #ffffff;
}

.progress-bar-main {
  margin-top: 20px;
}

.progress-bar-main .hero-progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(180deg, #6bbffc 0%, #006dbc 100%);
  border-radius: 0px;
  height: 5px;
}

.hero-bonus-content-main .hero-bonus-text {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
  color: #ffffff;
  font-family: "Urbanist-Medium";
}

.hero-bonus-content-main .hero-bonus-price {
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  font-family: "Urbanist-SemiBold";
  margin-bottom: 15px;
  position: relative;
}

.hero-bonus-content-main .duration-dots-main::after {
  content: "";
  position: absolute;
  background-image: url(../images/svg/duration-dots.svg);
  height: 20px;
  width: 6px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 25%;
  right: 0px;
  margin-right: -19px;
}

.hero-bonus-content-main .hero-bonus-inner-text {
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  font-family: "Urbanist-Medium";
  text-align: center;
}

.hero-bonus-flex {
  display: flex;
  align-items: center;
  min-width: 390px;
}

.hero-bonus-inner-flex {
  display: flex;
  min-width: 234px;
}

.hero-bonus-flex .hero-bonus-content-main:first-child {
  margin-right: 70px;
}

.hero-bonus-duration-main {
  margin-right: 36px;
}

.hero-bonus-inner-flex .hero-bonus-duration-main:last-child {
  margin-right: 0px;
}

.hero-social-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero-social-left .hero-social-flex:first-child {
  margin-right: 40px;
}

.hero-social-round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin-right: 10px;
}

.hero-social-icons {
  object-fit: contain;
  width: 14.6px;
  height: 17px;
}

.hero-social-right .footer-social-main .footer-social-ul {
  margin-bottom: 0px;
  margin-top: 0px;
}

.hero-social-right .footer-social-main .footer-social-link {
  background-color: transparent;
}

.hero-social-flex .hero-social-lables {
  color: #ffffff;
  font-size: 14px;
  font-family: "Urbanist-Medium";
  line-height: 20px;
}

.hero-social-left {
  display: flex;
  align-items: center;
}

.hero-social-left .hero-social-left:last-child {
  margin-right: 0px;
}

.hero-second-row {
  padding: 53px 0px 0px;
}

.home-token-main .home-token-ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.home-token-main .home-token-li {
  padding: 0px;
  width: calc(100% / 3 - 59px);
  margin: 0 59px 70px 0px;
}

.home-token-utility-box {
  max-width: 380px;
  width: 100%;
  border-left: 1px solid #515151;
  height: 319px;
  padding-left: 20px;
  position: relative;
}

.home-token-main .home-token-ul .home-token-li:nth-child(3n + 3) {
  margin-right: 0px;
}

.home-token-utility-box .home-token-utility-titles {
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
  margin-bottom: 10px;
  font-family: "AllianceNo2-Regular";
}

.home-token-utility-box .home-token-utility-para {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 80px;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-family: "Hauora-Regular";
}

.black-bg {
  background-color: #000000;
}

.home-utility-icons {
  height: 38px;
  width: 38px;
  position: absolute;
  bottom: 20px;
  object-fit: contain;
}

.home-token-main {
  max-width: 1378px;
  margin: auto;
}

.home-token-utility-sec {
  padding: 129px 0px 16px;
}

.main-titles-box .main-titles {
  margin-bottom: 60px;
  font-size: 34px;
  line-height: 38px;
  color: #ffffff;
  font-family: "Urbanist-Medium";
}

.home-token-allocation-sec {
  padding: 146px 0px 41px;
  position: relative;
}

.allocation-bg-video {
  width: 100%;
  object-fit: cover;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
}

.page-center-title-box .page-center-title {
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  font-family: "Urbanist-Semibold";
  margin-bottom: 80px;
  text-align: center;
}

.home-roadmap-sec {
  padding: 140px 0px;
}

.road-map-main {
  max-width: 1220px;
  margin: auto;
}

.road-map-main .roadmap-ul::after {
  content: "";
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: #e6e6e6;
  top: 27%;
}

.road-map-main .roadmap-ul {
  padding: 0px;
  margin: 0px;
  width: 100%;
  display: flex;
  position: relative;
}

.road-map-main .roadmap-li {
  padding: 0px;
  flex: 1;
}

.road-map-main .roadmap-li:last-child {
  margin-right: 0px;
}

.roadmap-content-box {
  width: 100%;
  min-height: 210px;
}

.roadmap-content-box .roadmap-quarter-text {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-family: "Urbanist-Medium";
  color: #ffffff;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  padding-bottom: 30px;
  max-width: 245px;
  width: 100%;
  margin: auto;
}

.roadmap-content-box .roadmap-quarter-date {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-family: "Urbanist-Medium";
  color: #ffffff;
  padding-bottom: 15px;
  padding-top: 30px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  max-width: 245px;
  margin: auto;
  width: 100%;
}

.roadmap-content-box .roadmap-para {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-family: "Urbanist-Medium";
  color: #ffffff;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  max-width: 245px;
  margin: auto;
  width: 100%;
}

.roadmap-dot {
  width: 20px;
  height: 20px;
  background: #e6e6e6;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin: auto;
}

.rewards-program-sec {
  padding: 60px 0px 140px;
}

.white-bg {
  background: #ffffff;
}

.rewards-main {
  max-width: 1231px;
  margin: auto;
}

.rewards-main .rewards-titles {
  color: #000000;
  letter-spacing: 0.02em;
  font-size: 34px;
  line-height: 38px;
  margin-bottom: 10px;
  font-family: "Hauora-Medium";
}

.rewards-main .rewards-titles-para {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  font-family: "Hauora-Medium";
  color: #000000;
}

.rewards-main .rewards-note-titels {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 20px;
  font-family: "Hauora-Medium";
}

.rewards-main .rewards-note-para {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7d7f81;
  margin-bottom: 10px;
  font-family: "Urbanist-Regular";
}

.rewards-main-flex {
  display: flex;
  position: relative;
  align-items: center;
  height: 80px;
  border-radius: 50px 15px 15px 50px;
}

.rewards-round-box {
  width: 80px;
  height: 80px;
  background: #ffffff;
  box-shadow: 5px 0px 20px 3px rgba(162, 166, 171, 0.12);
  border-radius: 50%;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rewards-icons {
  width: 65.84px;
  height: 65.84px;
  object-fit: contain;
}

.rewards-main .rewards-note-para:last-child {
  margin-bottom: 0px;
}

.rewards-main-flex .rewards-data {
  font-weight: 500;
  font-size: 19px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-family: "Hauora-Medium";
  overflow: hidden;
}

.rewards-data-bg1 {
  background: linear-gradient(90deg, rgba(237, 243, 247, 0.7) 0%, #f8fafa 100%);

  margin-bottom: 15px;
}

.rewards-data-bg2 {
  background: linear-gradient(90deg, #fbf5e7 0%, #fdfaf2 100%);
  margin-bottom: 15px;
}

.rewards-data-bg3 {
  background: linear-gradient(90deg, #f7f2f8 0%, #f3f8fe 100%);
}

.rewards-data-main {
  margin: 40px 0px 40px;
}

.header-btn-main .head-play-btn-main {
  margin-right: 0px;
}

.rewards-main-flex .rewards-data-1 {
  max-width: 143px;
  width: 100%;
  flex: 0 0 143px;
  margin-right: 51px;
}

.rewards-main-flex .rewards-data-2 {
  max-width: 150px;
  width: 100%;
  flex: 0 0 150px;
  margin-right: 59.5px;
}

.rewards-main-flex .rewards-data-3 {
  flex: 1;
}

.earn-token-row {
  display: flex;
}

.earn-token-left-main {
  max-width: 553px;
  width: 100%;
  flex: 0 0 553px;
  margin-right: 100px;
}

.earn-token-sec {
  padding: 73px 0px 107px;
}

.earn-token-right-main {
  flex: 1;
}

.earn-token-video {
  filter: drop-shadow(4px 4px 25px rgba(0, 0, 0, 0.25));
  height: 511px;
  width: 100%;
  object-fit: cover;
}

.earn-question-icon {
  width: 12.01px;
  height: 12px;
  object-fit: contain;
}

.earn-btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.earn-token-left-main .earn-token-title {
  font-size: 38px;
  line-height: 50px;
  color: #000000;
  margin-bottom: 20px;
  max-width: 360px;
  width: 100%;
  font-family: "Hauora-SemiBold";
}

.earn-token-left-main .earn-token-para {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 68px;
  max-width: 376px;
  width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-family: "Hauora-Regular";
}

.earn-token-card {
  max-width: 264px;
  width: 100%;
  height: 245px;
  background: #f7f9fe;
  border-radius: 2px;
  padding: 20px 12px 20px 25px;
  margin-right: 25px;
  position: relative;
}

.earn-token-card-flex {
  display: flex;
}

.earn-token-card-flex .earn-token-card:last-child {
  margin-right: 0px;
}

.earn-token-card .earn-token-price {
  font-weight: 500;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.02em;
  color: #40475a;
  margin-bottom: 46px;
  font-family: "Urbanist-Medium";
}

.earn-token-card .earn-token-card-title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #2c2236;
  margin-bottom: 5px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-family: "Urbanist-Medium";
  overflow: hidden;
}

.earn-token-card .earn-token-card-para {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #565672;
  font-family: "Urbanist-Medium";
  margin-bottom: 6px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.earn-token-card .earn-token-btn,
.earn-token-card .earn-token-btn:hover {
  width: 115px;
  height: 44px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  background: #9234A9;
  border-radius: 50px;
  font-family: 'Urbanist-light';
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  min-width: 115px;
  text-transform: capitalize;
}

.earn-token-card .earn-token-card-para1 {
  margin-bottom: 24px;
}

.earn-token-span {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #40475a;
  font-family: "Urbanist-Medium";
}

.token-private-sale-sec {
  padding: 72px 0px 118px;
}

.token-private-sale-title-box .token-private-sale-title {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 40px;
}

.token-private-sale-main {
  background: #ffffff;
  box-shadow: 0px 0px 25px 8px rgba(225, 232, 238, 0.55);
  border-radius: 20px;
  padding: 40px 60px 70px;
}

/* .token-private-data-main .token-table-container .token-table {
  min-width: 1000px;
} */

.token-table-container .token-table {
  min-width: 1000px;
}



.token-private-data-main .token-table-container {
  background: transparent;
  box-shadow: none;
  overflow-x: initial;
}

.token-table-inner-main .token-table-container {
  border-radius: 0px 0px 20px 20px;
  overflow-x: auto;
}

.token-private-data-main {
  border-radius: 20px 20px 0px 0px;
  overflow: auto;
  box-shadow: 0px 6px 23px 6px rgba(135, 136, 150, 0.1);
  background-color: transparent;
}

.token-table-main {
  border-radius: 20px 20px 0px 0px;
}

.token-private-data-main .token-table-head {
  padding: 20px 10px;
  height: 62px;
  background: #000000;
  border-radius: 20px 20px 0px 0px;
}

.token-private-data-main .token-table-th {
  color: #ffffff;
  text-align: left;
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  min-width: 315px;
  font-family: "Hauora-Regular";
  padding-left: 7px;
}

.token-private-data-main .token-table .token-table-row .token-table-th:first-child {
  padding-left: 70px;
  text-align: left;
}

.token-private-data-main .token-table .token-table-row .token-table-td:first-child {
  padding: 20px 0px 22px 80px;
  text-align: left;
}

.token-private-data-main .token-table .token-table-row .token-table-td:last-child {
  padding: 20px 80px 22px 20px;
  text-align: left;
}

.token-private-data-main .token-table .token-table-row:first-child .token-table-td {
  padding-top: 35px;
}

.token-private-data-main .token-table-td {
  color: #000000;
  width: 100%;
  min-width: 315px;
  letter-spacing: 0.02em;
  font-size: 18px;
  line-height: 22px;
  padding: 20.5px 20px 22px;
  text-align: left;
  font-family: "Urbanist-Medium";
  background: transparent;
  border-bottom: 1px solid #f4f6fa;
}

/* .token-private-data-main .token-table .token-table-row:first-child .token-table-td {
  padding: 35px 20px 22px 80px;
} */

.token-private-notes-main .token-private-note-titels {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 20px;
  font-family: "Hauora-Medium";
  letter-spacing: 0.02em;
}

.token-private-notes-main .token-private-note-para {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  color: #7d7f81;
  font-family: "Hauora-Regular";
}

.token-private-notes-main .token-private-note-para:last-child {
  margin-bottom: 0px;
}

.token-private-notes-main {
  margin-top: 40px;
}

.token-logo-main {
  display: flex;
  margin-bottom: 53px;
}

.token-logo {
  width: 150px;
  height: 33px;
  object-fit: contain;
  margin-right: 10px;
}

.token-logo-main .token-logo-text {
  color: #000000;
  font-size: 33px;
  line-height: 33px;
  font-family: "Hauora-Regular";
  position: relative;
  padding-left: 10px;
}

.private-sale-main-token {
  max-width: 1300px;
  width: 100%;
  margin: auto;
}

.token-logo-main .token-logo-text::before {
  position: absolute;
  content: "";
  background-color: #000000;
  height: 33px;
  width: 1px;
  margin-right: 10px;
  left: 0px;
}

.advance-main {
  max-width: 1205px;
  margin: auto;
}

.advance-data-main {
  max-width: 1205px;
  margin: auto;
}

.advance-main .advance-titles {
  text-transform: capitalize;
  font-size: 46px;
  line-height: 58px;
  color: #ffffff;
  font-family: "Urbanist-Medium";
  margin-bottom: 13px;
}

.advance-data-body {
  padding: 0px 0px 0px 40px;
}

.advance-main .advance-para {
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Regular";
  color: #ffffff;
  margin-bottom: 47px;
  max-width: 847px;
  width: 100%;
}

.advance-sec {
  padding: 94px 0px 78px;
}

.stake-token-third-row {
  width: 100%;
  max-width: 1315px;
  margin: auto;
}

.stake-token-heading-main .stake-token-heading {
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-family: "Urbanist-Medium";
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 106px;
}

.stake-token-data-flex {
  padding: 9.5px 10px;
  background: rgba(31, 31, 31, 0.6);
  margin-bottom: 4px;
  display: flex;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.06));
  min-height: 41px;
  align-items: center;
}

.stake-token-data-flex .stake-token-label {
  font-size: 18px;
  line-height: 22px;
  font-family: "Urbanist-Regular";
  color: #ffffff;
}

.stake-token-data-round {
  height: 22px;
  width: 22px;
  margin-right: 13px;
  border-radius: 50%;
}

.stake-token-main .stake-token-data-flex:last-child {
  margin-right: 0px;
}

.stake-token-col-1 {
  max-width: 155px;
  width: 100%;
  flex: 0 0 155px;
  margin-right: 5px;
}

.stake-token-col-2 {
  max-width: 140px;
  width: 100%;
  flex: 0 0 140px;
  margin-right: 5px;
}

.stake-token-col-3 {
  max-width: 140px;
  width: 100%;
  flex: 0 0 140px;
  margin-right: 5px;
}

.stake-token-col-4 {
  max-width: 140px;
  width: 100%;
  flex: 0 0 140px;
  margin-right: 5px;
}

.stake-token-col-5 {
  max-width: 140px;
  width: 100%;
  flex: 0 0 140px;
  margin-right: 5px;
}

.stake-token-col-6 {
  max-width: 140px;
  width: 100%;
  flex: 0 0 140px;
  margin-right: 5px;
}

.stake-token-col-7 {
  max-width: 140px;
  width: 100%;
  flex: 0 0 140px;
  margin-right: 5px;
}

.stake-token-col-8 {
  max-width: 140px;
  width: 100%;
  flex: 0 0 140px;
  margin-right: 5px;
}

.stake-token-col-9 {
  max-width: 140px;
  width: 100%;
  flex: 0 0 140px;
}

.grey-check-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.stake-token-sec {
  padding: 79px 0px 107px;
}

.colspan-2 {
  min-height: 86px;
}

.stake-token-check-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.page-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 72px;
}

.page-title-right-box .page-title-right {
  font-size: 34px;
  line-height: 46px;
  text-align: right;
  font-family: "Hauora-Regular";
  color: #ffffff;
}

.token-logo-main .white-token-text {
  color: #ffffff;
}

.token-logo-main .white-token-text::before {
  background-color: #ffffff;
}

.stack-tokenlogo-main {
  margin-bottom: 0px;
}

.advance-data-head {
  display: flex;
  border-bottom: 0.5px solid #ffffff;
  margin-bottom: 28px;
  max-width: 1133px;
  padding: 0px 5px 0px 40px;
}

.advance-data-head .advance-data-titles {
  color: #ffffff;
  font-size: 20px;
  line-height: 24px;
  font-family: "Urbanist-Medium";
  text-align: center;
  padding-bottom: 10px;
}

.advance-col-1 {
  max-width: 251px;
  width: 100%;
  flex: 0 0 251px;
  margin-right: 120px;
}

.advance-col-2 {
  max-width: 160px;
  width: 100%;
  flex: 0 0 160px;
  margin-right: 120px;

}

.advance-col-3 {
  max-width: 160px;
  width: 100%;
  flex: 0 0 160px;
  margin-right: 120px;
}

.advance-col-4 {
  max-width: 160px;
  flex: 0 0 160px;
  width: 100%;
}

.advance-data-row {
  display: flex;
}

.advance-data-box .advance-data-text {
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
}

.advance-data-box .advance-data-text-small {
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  font-family: "Urbanist-Medium";
}

.advance-data-box .text-center {
  text-align: center;
}

.bg-blue {
  background: #008aef;
}

.bg-dark-blue {
  background: #141b2f;
}

.advance-data-row:first-child .advance-col-2,
.advance-data-row:first-child .advance-col-3,
.advance-data-row:first-child .advance-col-4 {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.advance-data-row:last-child .advance-col-2,
.advance-data-row:last-child .advance-col-3,
.advance-data-row:last-child .advance-col-4 {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.advance-data-body .advance-data-row:last-child {
  margin-bottom: 15px;
}

.advance-data-row:last-child .advance-data-box .advance-data-text {
  margin-bottom: 0px;
}

.bg-purple {
  background: #7650ff;
}

.bg-light-black {
  background: #222222;
}

.advance-data-box {
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.advance-data-row .advance-col-1 .advance-data-box {
  justify-content: flex-start;
  align-items: flex-start;
}

.advance-sec .advance-condition-text {
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  font-family: "Urbanist-Regular";
  text-align: right;
  margin-top: 20px;
  max-width: 1133px;
}

.advance-sec .advance-condition-text-span {
  color: rgba(101, 228, 161, 1);
}

.roadmap-dot-main {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 99;
}

.roadmap-line-left {
  width: 50%;
  height: 5px;
  background: #34a9ff;
}

.roadmap-line-left-nun {
  width: 50%;
  height: 5px;
  background: #ffffff;
}

.roadmap-line-right {
  width: 50%;
  height: 5px;
  background: #34a9ff;
}

.roadmap-line-right-nun {
  width: 50%;
  height: 5px;
  background: #ffffff;
}

.roadmap-dot-main .active {
  background: #34a9ff;
}

.roadmap-dots {
  width: 20px;
  height: 20px;
  background: #34a9ff;
  border: 2px solid #e6e6e6;
  border-radius: 50%;
  padding: 8px;
}

.roadmap-dots-nun {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  padding: 8px;
}

.roadmap-line {
  position: relative;
}

.roadmap-main {
  max-width: 1220px;
  margin: auto;
}

.header-landing-btn-main .header-btn {
  color: #fcfcfd;
  font-size: 14px;
  line-height: 20px;
  height: 38px;
  font-family: "Urbanist-Medium";
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  width: 100%;
  min-width: 140px;
  border-radius: 7px;
}

.header-landing-btn-main {
  display: flex;
}

.header-landing-btn-main .header-switch-btn {
  background: #34A9FF;
  border: 1.5px solid #34A9FF;
  border-radius: 7px;
  margin-right: 15px;
}

.header-landing-btn-main .header-signout-btn {
  box-shadow: 0px 0px 35px #34a9ff;
  background: #000000;
  border: 1.5px solid #34A9FF;
}

.header-landing-btn-main .header-landing-btn-fill,
.header-landing-btn-main .header-landing-btn-fill:hover {
  margin-right: 15px;
  background: #34a9ff;
  border: 1.5px solid #34a9ff;
}

.header-landing-btn-main .header-landing-btn-border {
  background: #000000;
  border: 1.5px solid #34a9ff;
  box-shadow: 0px 0px 12px 8px rgba(52, 169, 255, 0.4);
}

.roadmap-owl .owl-nav {
  margin-top: 60px !important;
}

.roadmap-main .roadmap-owl .owl-nav .owl-prev,
.roadmap-main .roadmap-owl .owl-nav .owl-next {
  background-color: transparent;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(52, 169, 255, 0.5);
  border-radius: 50px;
  margin: 0px 15px 0px 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.roadmap-main .roadmap-owl .owl-nav.active {
  border: 1px solid #34a9ff;
}

.roadmap-main .roadmap-owl .owl-nav [class*="owl-"]:hover {
  background-color: transparent;
}

.roadmap-owl .owl-prev img {
  transform: rotate(180deg);
}

.turncate-text {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.hero-content-inner .hero-sub-titles {
  display: none;
}

.token-table-inner-main {
  background: linear-gradient(270.03deg,
      #cd33b5 0.03%,
      #9651ee 30.06%,
      #5b50be 69.16%,
      #1900fe 99.95%),
    linear-gradient(0deg, #ffffff, #ffffff);
  padding: 1px !important;
  border: 0;
  border-radius: 0px 0px 20px 20px;
}

.token-table .token-table-body .token-table-row:last-child {
  position: relative;
  /* background-image: url(../images/svg/gradiant-border.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%; */

  /* content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  /* border: double 1px transparent;
  border-radius: 0px 0px 20px 20px;
  background-image: linear-gradient(white, white), linear-gradient(277deg, rgba(205, 51, 181, 1), rgba(150, 81, 238, 1), rgba(91, 80, 190, 1), rgba(25, 0, 254, 1));
  background-origin: border-box;
  background-clip: content-box, border-box; */
}

/* .token-private-data-main .token-table .token-table-body tr:last-child td {
  background: transparent;
  padding: 22.5px 20px 22px;
} */
/* .token-table .token-table-body .token-table-row:last-child::after { */
/* content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px 0px 20px 20px;
  border: 1px solid transparent;
  background: -webkit-linear-gradient(277deg, rgba(205, 51, 181, 1), rgba(150, 81, 238, 1), rgba(91, 80, 190, 1), rgba(25, 0, 254, 1)) border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  background: -webkit-linear-gradient(277deg, rgba(205, 51, 181, 1), rgba(150, 81, 238, 1), rgba(91, 80, 190, 1), rgba(25, 0, 254, 1)) border-box !important; */

/* border-radius: 0px 0px 20px 20px;
  border: 1px solid transparent; 
  background: linear-gradient(277deg, rgba(205, 51, 181, 1), rgba(150, 81, 238, 1), rgba(91, 80, 190, 1), rgba(25, 0, 254, 1))border-box; 
  -webkit-mask: 
     linear-gradient(#fff 0 0) padding-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; 
          mask-composite: exclude; 
             content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */

/* } */


/* .token-table .token-table-body .token-table-row:last-child:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: double 1px transparent;
  border-radius: 0px 0px 20px 20px;
  background-image: linear-gradient(white, white), linear-gradient(277deg, rgba(205, 51, 181, 1), rgba(150, 81, 238, 1), rgba(91, 80, 190, 1), rgba(25, 0, 254, 1));
  background-origin: border-box;
  background-clip: content-box, border-box;
} */
/* 
.token-private-data-main .token-table .token-table-body .token-table-row .token-table-td:nth-child(1) {
  width: 25%;
}

.token-private-data-main .token-table .token-table-body .token-table-row .token-table-td:nth-child(2) {
  width: 25%;
}

.token-private-data-main .token-table .token-table-body .token-table-row .token-table-td:nth-child(3) {
  width: 25%;
}

.token-private-data-main .token-table .token-table-body .token-table-row .token-table-td:nth-child(4) {
  width: 25%;
}

.token-table-inner-main .token-table .token-table-body .token-table-row .token-table-td:nth-child(1) {
  width: 25%;
}

.token-table-inner-main .token-table .token-table-body .token-table-row .token-table-td:nth-child(2) {
  width: 25%;
}

.token-table-inner-main .token-table .token-table-body .token-table-row .token-table-td:nth-child(3) {
  width: 25%;
}

.token-table-inner-main .token-table .token-table-body .token-table-row .token-table-td:nth-child(4) {
  width: 25%;
}

.token-table-inner-main .token-table .token-table-body .token-table-row:first-child .token-table-inner-td {
  padding: 20.5px 20px 22px;
} */

.trans-icon-box {
  width: 28px;
  height: 28px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.trans-inner-data-flex {
  display: flex;
  align-items: center;
}

.mob-trans-data-col-1 {
  max-width: 127px;
  width: 100%;
  flex: 0 0 127px;
}

.mob-trans-data-col-2 {
  max-width: 59px;
  width: 100%;
  flex: 0 0 59px;
}

.mob-trans-data-col-3 {
  max-width: 45px;
  width: 100%;
  flex: 0 0 45px;
}

.mobile-view {
  display: none;
}

.trans-data-inner {
  margin-right: 32px;
}

.trans-data-inner:last-child {
  margin-right: 0px;
}

.trans-collpase-row .trans-col-title {
  font-size: 12px;
  line-height: 14px;
  font-family: "Urbanist-Medium";
  color: #7668FE;
  margin-bottom: 7px;
}

.trans-collpase-row .dash-trans-titles {
  font-size: 14px;
  line-height: 17px;
  color: #495463;
  font-family: 'Urbanist-Bold';
  margin-bottom: 2px;
}

.trans-collpase-row .dash-trans-lables {
  font-size: 12px;
  line-height: 14px;
  color: #758698;
  font-family: "Urbanist-Medium";
}

.trans-data-wrapper {
  border: 1px solid #E6EFFB;
  border-radius: 5px;
  padding: 0px 0px 20px 0px;
  margin: 20px 0px;
}

.trans-collpase-row {
  padding: 10px 15px;
  border-bottom: 1px solid #E6EFFB;
}

.trans-data-wrapper-inner .trans-collpase-row:last-child {
  border-bottom: 0px;
}

.trans-yellow-btn-main {
  padding: 10px 15px 0px;
}

.trans-yellow-btn-main .trans-data-btn {
  min-width: 90px;
  height: 38px;
}

.error-text {
  font-family: "Urbanist-Regular";
  font-size: 15px;
  line-height: 17px;
  color: red;
  margin: 5px 0px 0px 0px;
  text-transform: capitalize;
}

.cuts-accordian-main .MuiSvgIcon-root {
  display: none;
}

.cuts-accordian-main .MuiAccordionSummary-content {
  margin: 0px !important;
}

.cuts-accordian-main .cuts-accordian {
  border: 0px;
  background-color: transparent;
}

.cuts-accordian-main .cuts-accordian-summary {
  background-color: transparent;
  padding: 0px;
  min-height: auto;
}

.cuts-accordian-main .cuts-accordian-details {
  border: 0px;
  padding: 0px;
  border-bottom: 1px solid rgba(230, 239, 251, 1);
}

.trans-data-flex-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.trans-status-flex {
  display: flex;
  margin-bottom: 10px;
}

.trans-status-flex .trans-status-text {
  font-size: 11px;
  line-height: 13px;
  font-family: 'Urbanist-SemiBold';
  color: #000000;
  margin-right: 10px;
}

.trans-status-flex .trans-status-highlight {
  color: #7668FE;
}

.red-icon-box {
  border: 1px solid #FF7878;
}

.green-icon-box {
  border: 1px solid #00D285;
}

.yellow-icon-box {
  border: 1px solid #F6C243;
}

.trans-search-box {
  width: 100%;
}

.playstore-icons:first-child {
  margin-right: 14px;
}

.playstore-icons {
  height: 17px;
  width: 17px;
  object-fit: contain;
}

.header-btn-main .head-play-btn-main {
  border: 1px solid #fff;
  padding: 0px;
  height: 34px;
  max-width: 67px;
  width: 100%;
}

.header-play-scanner-box {
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
  display: flex;
  max-width: 250px;
  align-items: center;
  width: 100%;
}

.header-scanner-img {
  height: 90px;
  width: 90px;
  object-fit: contain;
  margin-right: 15px;
}

.header-play-scanner-box .head-scan-text {
  color: #000000;
  font-family: "Hauora-Regular";
  font-size: 15px;
  line-height: 20px;
}

.header-scanner-show {
  display: none;
  position: absolute;
  top: 60px;
}

.header-btn-main {
  position: relative;
}

.header-btn-main .head-play-btn-main:hover+.header-scanner-show {
  display: block;
}

.footer-playstore-main {
  display: flex;
  margin: 30px 0px 30px;
}

.footer-social-title-box .Footer-mobile-text {
  color: #FCFCFD;
  font-family: 'Urbanist-Regular';
  font-size: 18px;
  margin-bottom: 8px;
  line-height: 22px;
}

.footer-playstore-img {
  height: auto;
  margin-bottom: 10px;
  width: 128px;
}

.footer-scanner-img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.footer-paly-right .Footer-scanner-text {
  font-size: 11px;
  line-height: 14px;
  font-family: "Urbanist-Medium";
  color: #FCFCFD;
  text-align: center;
}

.footer-play-left {
  max-width: 150px;
  width: 100%;
}

.footer-email-main {
  position: relative;
}

.footer-email-field .form-group .form-control input {
  background: none;
  /* border: 1px solid hsla(0, 0%, 100%, .5); */
  border-radius: 8px;
  padding: 13.04px 10px;
  color: #fff;
  font-family: "Urbanist-Medium";
  font-size: 14px;
  width: 100%;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.87);
}

.footer-email-field .form-group .form-control input::placeholder {
  color: #fff;
  font-family: "Urbanist-Medium";
  font-size: 14px;
}

.footer-email-main .footer-go-btn,
.footer-email-main .footer-go-btn:hover {
  background: rgba(112, 104, 234, 1);
  border-radius: 8px;
  height: 40px;
  position: absolute;
  right: 4px;
  top: 3.5px;
  min-width: 38px;
}

.footer-right-errow-img {
  height: 16px;
  width: 16px;
  object-fit: contain;
}

.footer-email-field .MuiInputBase-root {
  min-width: 250px;
  width: 100%;
}

.page-wrapper {
  height: calc(100% - 56px);
  padding-top: 56px;
}

.dash-token-flex-row .dash-token-main:last-child {
  margin-right: 0px;
}

.transaction-right-main {
  padding: 40px 0px 60px 76px;
}
.modal-header {
  position: relative;
}

.modal-close {
  height: 30px;
  width: 30px;
  object-fit: contain;
  position: absolute;
  right: -27px;
  top: -27px;
}

.referral-data-main-dash {
  max-width: 715px;
  overflow: auto;
  height: 255px;
}

/* width */
.referral-data-main-dash::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

/* Track */
.referral-data-main-dash::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.referral-data-main-dash::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.referral-data-main-dash::-webkit-scrollbar-thumb:hover {
  background: #8888886e;
}

.profile-right-main {
  padding: 78px 0px 100px 76px;
}

.drop-down-icon {
  object-fit: contain;
  margin-left: 28px;
  width: 8px;
  height: 4px;
}

.user-need-sidebar .user-need-sidebar-ul .user-need-sidebar-li:first-child .user-need-sidebar-link {
  margin-bottom: 11px;
}

.pages-pagination-main .MuiTablePagination-actions {
  display: none;
}

.pages-pagination-main .MuiInputBase-root {
  border: 1px solid #E6EFFB;
  border-radius: 2px;
  margin-right: 8px;

}

.pages-pagination-main {
  margin: 20px 0px;
}

.pages-pagination-main .MuiInputBase-root .MuiSvgIcon-root {
  display: none;
}

.pages-pagination-main .MuiSelect-select {
  padding: 4px 7.889px 3.45px !important;
  max-height: 30px;
  max-width: 33px;
  background-color: transparent;
  font-size: 15px;
  line-height: 18px;
  font-family: 'Urbanist-Semibold';
}

.pages-pagination-main .MuiTablePagination-selectLabel,
.pages-pagination-main .MuiTablePagination-displayedRows {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Urbanist-Medium';
  color: #000000;
}

.pages-pagination-dropdown .pages-pagination-list {
  border: 1px solid #E6EFFB;
  min-width: 33px;
  padding: 0px;
}

.pages-pagination-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pages-pagination-flex .pages-pagination-text {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Urbanist-Medium';
  color: #000000;
}

.pages-pagination-dropdown .pages-pagination-list .pages-pagination-item {
  font-size: 15px;
  line-height: 18px;
  font-family: 'Urbanist-Semibold';
  padding: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #E6EFFB;
  padding: 6px 8px;
}

.pages-pagination-dropdown .pages-pagination-list .pages-pagination-item:last-child {
  border-bottom: 0px;
}

.pages-pagination-main .pages-pagination-btn {
  min-width: 33px;
  height: 30px;
  border: 1px solid #E6EFFB;
  border-radius: 2px;
  background-color: transparent;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 18px;
  font-family: 'Urbanist-Semibold';
  color: #000000;
}

.pages-pagination-dropdown {
  position: absolute;
  top: 33px;
  max-width: 33px;
}

.pages-dropdown-main {
  max-width: 33px;
  position: relative;
  margin: 0px 8px
}

.mobile-footer-logo {
  display: none;
}

.footer-social-col {
  display: flex;
  flex-direction: column;
}

.round-icons {
  height: 18px;
  width: 18px;
  object-fit: contain;
}

img[alt] {
  font-size: 10px;
}

.sale-field .Mui-error {
  color: #666666 !important;
}
.listing-flex {
  padding: 9.5px 10px 9.5px 10px;
}
.sale-field .privat-sale-datepicker fieldset {
  border-color: #c4c4c4 !important;
}

.token-arrow-icon {
  width: 10px;
  height: 20px;
  margin-right: 13px;
  object-fit: cover;
}

.listing-arrow-icon {
  object-fit: contain;
  width: 24px;
  height: 23px;
  margin-right: 7px;
}

.footer-email-field .form-control fieldset {
  border-color: transparent;
  border-width: 0px;
  border-radius: 8px;
}


.footer-logo-box .token-logo-text {
  color: #FFFFFF;
  font-size: 29px;
  line-height: 29px;
  font-family: "Hauora-Light";
  position: relative;
  padding-left: 10px;
}

.footer-logo-box .token-logo-text::before {
  position: absolute;
  content: "";
  background-color: #FFFFFF;
  height: 27px;
  width: 1px;
  margin-right: 10px;
  left: 0px;
}

.footer-logo-box .footer-logo {
  margin-right: 9px;
  object-fit: contain;
  max-width: 130px;
  height: 30px;
}

.private-wallet-mobile-card {
  background: linear-gradient(261.24deg, #433AC0 4.07%, #994EEE 97.01%, #994EEE 97.02%);
  display: none;
}

.private-wallet-mobile-card .private-card-wallet-inner .private-card-content:last-child {
  margin-bottom: 0px;
}

.private-wallet-mobile-card .private-card-content .private-price-label {
  margin-bottom: 0px;
}

.dash-token-inner-box {
  max-width: 1065px;
  width: 100%;
  padding: 0px 86px;
}

.user-dash-second-row .trans-data-main {
  height: 200px;
  overflow: auto;
}

.header-container {
  max-width: 1554px !important;
}

.token-table-row {
  display: flex;
}

.token-table-body .token-table-row:last-child .token-table-td {
  border-bottom: 0 !important;
}

.token-private-data-main {
  border-radius: 20px !important;
}

.cust-token-private-row,
.cust-token-private-head-row {
  display: flex;
  background-color: transparent;
}

.cust-token-private-row {
  border-bottom: 1px solid #f4f6fa;
}

.cust-token-private-body {
  border-radius: 20px 20px 0px 0px;
  overflow: auto;
  box-shadow: 0px 6px 23px 6px rgba(135, 136, 150, 0.1);
  background-color: transparent;
}

.cust-token-private-head-row,
.cust-token-private-body {
  max-width: 1180px;
  margin: auto;
  padding: 0px 88px;
}

.cust-token-private-data-main .grediant-border-token:last-child {
  border-bottom: 0px;
  border: double 1px transparent;
  border-radius: 0px 0px 20px 20px;
  background-image: linear-gradient(white, white), linear-gradient(277deg, rgba(205, 51, 181, 1), rgba(150, 81, 238, 1), rgba(91, 80, 190, 1), rgba(25, 0, 254, 1));
  background-origin: border-box;
  background-clip: content-box, border-box;
  /* border-bottom: 1px solid #f4f6fa; */
}

.token-table-body .token-table-row:last-child {
  border-bottom: 0px;
  border: double 1px transparent;
  border-radius: 0px 0px 20px 20px;
  background-image: linear-gradient(white, white), linear-gradient(277deg, rgba(205, 51, 181, 1), rgba(150, 81, 238, 1), rgba(91, 80, 190, 1), rgba(25, 0, 254, 1));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.cust-token-private-data-main {
  margin-top: 100px;
}

.token-private-data-main .token-table-td:last-child {
  border-radius: 0px 0px 0px 20px;
}

.token-private-data-main .token-table-td:last-child {
  border-radius: 0px 0px 20px 0px;
}

.cust-token-private-col-td {
  width: 100%;
  min-width: 285px;
  color: #000000;
  letter-spacing: 0.02em;
  font-size: 18px;
  line-height: 22px;
  padding: 20.5px 20px 22px 0px;
  text-align: left;
  font-family: "Urbanist-Medium";
  background: transparent;
}

.cust-token-private-col-th {
  color: #ffffff;
  text-align: left;
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  min-width: 285px;
  font-family: "Hauora-Regular";
}


/* width */
.token-private-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
.token-private-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* Handle */
.token-private-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.token-private-scrollbar::-webkit-scrollbar-thumb:hover {
  background: transparent;
}


.cust-token-private-head {
  padding: 20px 0px;
  height: 62px;
  background: #000000;
  border-radius: 20px 20px 0px 0px;
}

.profile-input-box .profile-data-text {
  color: rgba(73, 84, 99, 0.7);
  font-size: 14px;
  line-height: 17px;
  margin-top: 20px;
  font-family: 'Urbanist-Regular';
  margin-bottom: 30px;
}

.profile-input-box .profile-condition-text {
  /* font-size: 14px;
line-height: 17px; */
  font-size: 11px;
  line-height: 14px;
  color: #495463B2;
  font-family: 'Urbanist-Regular';
  margin-bottom: 10px;
}

.profile-data-main .profile-data-text {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  color: #495463B2;
  line-height: 17px;
  font-family: 'Urbanist-Regular';
}

.header-language-drop-box .haeder-language-ul {
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  position: absolute;
  top: 25px;
  min-width: 120px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .15);
}

.header-language-main {
  position: relative;
}

.header-language-drop-box .haeder-language-li {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  font-family: 'Urbanist-Regular';
  padding: 0px;
  margin: 0px;
}

.header-language-drop-box {
  display: block;
  position: relative;
}
/* 
.header-language-button:hover+.header-language-drop-box {
  display: block;
} */

.header-language-main .header-language-button {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  font-family: 'Urbanist-Regular';
  padding: 0px;
  text-transform: capitalize;
  margin-left: 8px;
}

.language-down-arrow {
  width: 12.96px;
  height: 7px;
  object-fit: contain;
  margin-left: 5px;
  position: absolute;
  top: 6px;
}
.transcation-popup .MuiInputBase-sizeSmall{
  width: 100% !important;
    min-width: 300px !important;
}
.set-modal-box .MuiPaper-rounded {
  padding: 20px 15px !important;
}

.dropdown-box .form-control{
  color: rgba(73,84,99,.7);
}
.price-card-main .green-btn-main .Mui-disabled {
  color: #fff;
}
.payment-modal-flex .MuiFormGroup-root{
  margin: 0px;
}



.modal .modal-confirm-inner {
  max-width: 661px;
    min-width: 661px;
  padding: 48px 54px 45.5px;
}
.modal-confirm-inner .confirm-modal-title{
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  font-family: "Urbanist-Medium";
  margin-bottom: 15px;
}
.modal-confirm-inner .confirm-modal-para{
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 20px;
  font-family: "Urbanist-Regular";
}
.modal-confirm-inner .confirm-modal-para:last-child{
  margin-bottom: 0px;
}
.primary-btn-main .modal-return-btn{
  max-width: 183px;
  height: 51px;
  margin-top: 8px;
}
