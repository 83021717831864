@media screen and (min-width:1600px) {
    .container {
        max-width: 1505px;
        margin: auto;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .auth-inner {
        min-height: 500px;
        overflow: auto;
        padding: 50px 0px;
    }


    .auth-inner ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    .auth-inner ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    .auth-inner ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    .auth-inner ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

}






















@media screen and (min-width: 1400px) and (max-width:1599px) {
    .container {
        max-width: 1320px;
        margin: auto;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .header-nav-main .header-nav-link {
        font-size: 14px;
        line-height: 16px;
        margin-right: 20px;
    }

    .dash-token-inner-box {
        padding: 0px 56px;
    }

    .dash-token-flex-row {
        justify-content: center;
        align-items: center;
    }

    .gradiant-border {
        width: 100%;
    }

    .gradiant-border {
        width: 100%;
    }

    .dashboard-card-3 {
        margin-left: 10px;
    }

    .user-dash-first-row-right {
        margin-left: 10px;
    }

    .page-right-main {
        padding: 53px 0px 50px 15px;
    }

    .dashboard-referral-col {
        padding: 20px;
    }

    .dash-token-col-2 {
        max-width: 145px;
        width: 100%;
        flex: 0 0 145px;
    }

    .dash-token-col-5,
    .dash-token-col-6,
    .dash-token-col-7 {
        max-width: 132px;
        width: 100%;
        flex: 0 0 132px;
    }

    .trans-data-inner {
        margin-right: 20px;
    }

    .referral-data-col-1 {
        max-width: 110px;
        width: 100%;
        flex: 0 0 110px;
    }

    .referral-data-col-4 {
        max-width: 110px;
        width: 100%;
        flex: 0 0 110px;
    }

    .referral-data-main {
        max-width: 1111px;
        overflow: auto;
    }

    .page-right-main {
        padding: 30px 0px 67px 15px;
    }

    .referral-data-main {
        max-width: 1111px;
        overflow: auto;
    }

    .private-sale-final-col .price-card-main {
        max-width: 375px;
    }

    .private-sale-main {
        width: 100%;
    }

    .primary-btn-main .buy-token-btn,
    .primary-btn-main .buy-token-btn:hover {
        margin-right: 9px;
        min-width: 100px;
        height: 46px;
    }

    .private-price-card-main {
        max-width: 390px;
        height: auto;
        padding: 15px;
    }

    .private-card-content-flex .private-card-content:nth-child(1) {
        max-width: 66px;
        width: 100%;
        flex: 0 0 66px;
        margin-right: 10px;
    }

    .private-card-content-flex .private-card-content:nth-child(2) {
        max-width: 80px;
        width: 100%;
        flex: 0 0 80px;
        margin-right: 10px;
    }

    .private-sale-card-2 {
        padding: 15px;
    }

    .dash-card-content .dash-price-label {
        font-size: 10px;
        line-height: 12px;
    }

    .private-card-content-flex {
        justify-content: space-between;
    }

    .dash-token-data-content .dash-token-data-price {
        font-size: 16px;
        line-height: 20px;
    }

    .price-flex .price-span {
        font-size: 16px;
        line-height: 19px;
    }

    .price-flex .card-price {
        font-size: 17px;
        line-height: 20px;
    }

    .auth-inner {
        min-height: 500px;
        overflow: auto;
        padding: 50px 0px;
    }

    .auth-logo-main {
        margin-bottom: 25px;
    }

    .auth-inner ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    .auth-inner ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    .auth-inner ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    .auth-inner ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .dots-drop-main {
        margin-left: 10px;
    }

    .referral-data-main-dash {
        width: 100%;
        overflow: auto;
    }

    .private-card-main {
        max-width: 391px;
    }

    .green-btn-main .buy-token-btn {
        min-width: 147px;
        max-width: 147px;
        margin-right: 21px;
    }

    .trans-field .MuiFormControl-root {
        width: 100%;
        min-width: 100%;
    }
}




















@media screen and (min-width: 1200px) and (max-width:1399px) {
    .container {
        max-width: 1140px;
        margin: auto;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .header-container {
        max-width: 1140px !important;
    }

    .header-language-drop-box .haeder-language-ul {
        min-width: 100px;
    }

    .text-copy-box {
        max-width: 430px;
    }
    .dashboard-referral-flex .dash-referral-text , .dash-referral-span-text{
        font-size: 18px;
        line-height: 22px;
    }
    .primary-border-btn-main .history-btn {
        min-width: 70px;
    }
    .dashboard-referral-flex .dash-referral-para {
        font-size: 16px;
        line-height: 18px;
    }
    .primary-btn-main .copy-btn {
        min-width: 79px;
    }

    .private-sale-final-main {
        width: 100%;
    }

    .private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(n + 1) {

        margin-left: 0px;
        margin-right: 16.5px;
    }

    .private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(2n + 2) {
        margin-right: 0;
        margin-left: 16.5px;
    }


    .private-sale-final-col {
        width: calc(100% / 2 - 33px);
    }

    .private-card-wallet-col {
        width: calc(100% / 2 - 33px);
    }

    .header-nav-main .header-nav-link {
        font-size: 12px;
        line-height: 16px;
        margin-right: 15px;
    }

    .header-landing-btn-main .header-btn {
        font-size: 10px;
        line-height: 14px;
        height: 33px;
        min-width: 93px;
        padding: 5px;
    }

    .hero-content-main .hero-titles {
        font-size: 35px;
        line-height: 45px;
        max-width: 1000px;
        margin: 0px auto 50px;
    }

    .hero-first-row {
        padding-top: 70px;
        max-width: 1200px;
        margin: auto;
    }

    .hero-second-row {
        max-width: 1200px;
        margin: auto;
    }

    .banner-video {
        min-height: 850px;
    }

    .stake-token-col-1 {
        max-width: 113px;
        width: 100%;
        flex: 0 0 113px;
    }

    .stake-token-col-2,
    .stake-token-col-3,
    .stake-token-col-4,
    .stake-token-col-5,
    .stake-token-col-6,
    .stake-token-col-7,
    .stake-token-col-8,
    .stake-token-col-9 {
        max-width: 120px;
        width: 100%;
        flex: 0 0 120px;
        margin-right: 5px;
    }

    .advance-main .advance-titles {
        font-size: 37px;
        line-height: 43px;
    }

    .hero-btn-main .hero-fill-btn,
    .hero-btn-main .hero-fill-btn:hover {
        min-width: 253px;
    }

    .hero-btn-main .hero-btn {
        font-size: 13px;
        line-height: 16px;
        padding: 10.5px 14.5px;
    }

    .hero-content-main .hero-sub-para {
        font-size: 18px;
        line-height: 21px;
    }

    .hero-bonus-content-main .hero-bonus-price {
        font-size: 25px;
        line-height: 26px;
    }

    .hero-progress-main .hero-progress-txet {
        font-size: 14px;
        line-height: 16px;
    }

    .token-logo-main .token-logo-text {
        font-size: 28px;
        line-height: 28px;
    }

    .token-logo {
        height: 28px;
        margin-right: 0px;
    }

    .token-logo-main .token-logo-text::before {
        height: 29px;
    }

    .stake-token-heading-main .stake-token-heading {
        font-size: 13px;
        line-height: 15px;
        min-height: 80px;
    }

    .page-title-right-box .page-title-right {
        font-size: 27px;
        line-height: 34px;
    }

    .stake-token-data-flex .stake-token-label {
        font-size: 14px;
        line-height: 18px;
    }

    .home-token-utility-sec {
        padding: 50px 0px 16px;
    }

    .main-titles-box .main-titles {
        margin-bottom: 40px;
        font-size: 28px;
        line-height: 28px;
    }

    .home-token-utility-box .home-token-utility-titles {
        font-size: 19px;
        line-height: 30px;
    }

    .home-token-utility-box .home-token-utility-para {
        font-size: 13px;
        line-height: 20px;
    }

    .home-utility-icons {
        height: 25px;
        width: 25px;
    }

    .home-token-main .home-token-li {
        margin: 0 59px 42px 0px;
    }

    .advance-main .advance-titles {
        font-size: 30px;
        line-height: 38px;
    }

    .advance-main .advance-para {
        font-size: 13px;
        line-height: 15px;
    }

    .advance-data-head .advance-data-titles {
        font-size: 16px;
        line-height: 20px;
    }

    .advance-data-box .advance-data-text {
        font-size: 13px;
        line-height: 18px;
    }

    .earn-token-left-main .earn-token-title {
        font-size: 31px;
        line-height: 45px;
    }

    .earn-token-left-main .earn-token-para {
        font-size: 13px;
        line-height: 19px;
    }

    .earn-token-sec {
        padding: 50px 0px 50px;
    }

    .token-private-sale-sec {
        padding: 50px 0px 50px;
    }

    .earn-token-card .earn-token-price {
        font-size: 36px;
        line-height: 43px;
    }

    .earn-token-card .earn-token-card-title {
        font-size: 15px;
        line-height: 17px;
    }

    .earn-token-card .earn-token-btn,
    .earn-token-card .earn-token-btn:hover {
        height: 36px;
        font-size: 15px;
        line-height: 19px;
    }

    .token-private-sale-main {
        padding: 30px 30px 30px;
    }

    .token-private-data-main .token-table-th {
        font-size: 15px;
        line-height: 19px;
    }

    .token-private-data-main .token-table-td {
        font-size: 14px;
        line-height: 18px;
        padding: 20px 20px 14px;
    }

    /* 
    .token-private-data-main .token-table .token-table-row:first-child .token-table-td {
        padding: 13px 20px 13px;
    } */
    .token-private-data-main .token-table-th,
    .token-private-data-main .token-table-td {
        min-width: 255px;
    }

    .token-private-notes-main .token-private-note-para {
        font-size: 13px;
        line-height: 21px;
    }

    .token-table-container .token-table {
        min-width: 1000px;
    }

    .token-private-notes-main .token-private-note-titels {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 11px;
    }

    .rewards-program-sec {
        padding: 50px 0px 50px;
    }

    .token-private-sale-title-box .token-private-sale-title {
        font-size: 25px;
        line-height: 33px;
        margin-bottom: 28px;
    }

    .rewards-main .rewards-titles {
        font-size: 27px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    .rewards-main .rewards-titles-para {
        font-size: 14px;
        line-height: 22px;
    }

    .rewards-main-flex .rewards-data {
        font-size: 15px;
        line-height: 23px;
    }

    .rewards-main-flex {
        height: 70px;
    }

    .rewards-round-box {
        width: 70px;
        height: 70px;
    }

    .rewards-main .rewards-note-para {
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 7px;
    }

    .home-token-allocation-sec {
        padding: 50px 0px 50px;
    }

    .home-roadmap-sec {
        padding: 50px 0px;
    }

    .page-center-title-box .page-center-title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 40px;
    }

    .roadmap-content-box .roadmap-quarter-text {
        font-size: 12px;
        line-height: 16px
    }

    .roadmap-content-box .roadmap-para {
        font-size: 14px;
        line-height: 17px;
    }

    .roadmap-content-box .roadmap-quarter-date {
        font-size: 14px;
        line-height: 17px;
    }

    .footer-sec {
        padding: 70px 0px 40px;
    }

    .footer-inner-main {
        padding-left: 0px;
        padding-right: 0px;
    }

    .footer-col .footer-title {
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 10px;
    }

    .footer-nav-main .footer-nav-link {
        font-size: 13px;
        line-height: 14px;
        margin-bottom: 16px;
    }

    .gradiant-border {
        width: 100%;
    }

    .dash-token-col-1 {
        max-width: 137px;
        width: 100%;
        flex: 0 0 137px;
        margin-right: 5px;
    }

    .dash-token-col-2,
    .dash-token-col-3,
    .dash-token-col-5,
    .dash-token-col-6,
    .dash-token-col-7 {
        max-width: 110px;
        width: 100%;
        flex: 0 0 110px;
        margin-right: 5px;
    }

    .dash-token-col-4 {
        max-width: 185px;
        width: 100%;
        flex: 0 0 185px;
        margin-right: 5px;
    }

    .gradiant-border {
        width: 100%;
    }

    .dash-token-col-1 {
        max-width: 120px;
        width: 100%;
        flex: 0 0 120px;
        margin-right: 5px;
    }

    .dash-token-col-2,
    .dash-token-col-3,
    .dash-token-col-5,
    .dash-token-col-6,
    .dash-token-col-7 {
        max-width: 119px;
        width: 100%;
        flex: 0 0 119px;
        margin-right: 5px;
    }

    .dash-token-col-7 {
        max-width: 90px;
        width: 100%;
        flex: 0 0 90px;
        margin-right: 0px;
    }

    .dash-token-col-4 {
        max-width: 210px;
        width: 100%;
        flex: 0 0 210px;
        margin-right: 5px;
    }

    .dash-token-data-round {
        height: 10px;
        width: 10px;
    }

    .dash-token-data-flex .dash-token-label {
        font-size: 13px;
        line-height: 15px;
    }

    .dash-token-heading-main .dash-token-heading {
        font-size: 12px;
        line-height: 15px;
    }
    .dash-token-value-main .dash-token-value-text {
        font-size: 19px;
        line-height: 23px;
    }
    .dash-token-value-box {
        height: 40px;
    }
    .dash-token-data-flex {
        padding: 7px 7px;
        align-items: center;
    }

    .dashboard-card {
        padding: 15px;
        height: 197px;
    }

    .dashboard-card-3 {
        margin-left: 10px;
    }

    .dash-icon-text-main .dash-icon-price {
        font-size: 18px;
        line-height: 22px;
    }
    .price-card-main{
        height: 400px;
    }
    .dash-icon-text-main .dash-icon-text {
        font-size: 14px;
        line-height: 18px;
    }

    .dash-card-content .dash-price-text {
        font-size: 14px;
        line-height: 19px;
    }

    .dash-price-card .price-card-main {
        max-width: 100%;
        padding: 15px;
        height: 396px;
    }

    .dashboard-referral-col {
        padding: 15px;
        height: 397px;
    }

    .dash-referral-btn-main {
        margin-top: 20px;
        margin-bottom: 21px;
    }



    .dash-page-right-main {
        padding: 53px 0px 0px 20px;
    }

    .dash-token-data-content .dash-token-data-price {
        font-size: 13px;
        line-height: 18px;
    }

    .dash-token-flex {
        margin-top: 20px;
    }
    .dashboard-card-2 , .dashboard-card-3{
        min-width: 314px;
        max-width: 314px;
        height: 180px;
    }
    .user-dash-first-row-left {
        max-width: 285px;
        width: 100%;
        flex: 0 0 285px;
    }

    .dash-price-card {
        margin-bottom: 20px;
    }

    .dash-token-data-content .dash-token-data-text {
        font-size: 10px;
        line-height: 14px;
    }

    .dashboard-card-1 {
        max-width: 100%;
        height: 180px;
    }

    .user-dash-first-row-right {
        margin-left: 10px;
    }

    .trans-data-inner .dash-trans-titles {
        font-size: 13px;
    }

    .referral-data-col-1 {
        max-width: 134px;
        width: 100%;
        flex: 0 0 134px;
    }

    .trans-data-inner {
        margin-right: 0px;
    }

    .referral-data-col-2 {
        max-width: 200px;
        width: 100%;
        flex: 0 0 200px;
    }

    .referral-data-main-dash {
        max-width: 870px;
    }

    .referral-data-inner .dash-referral-titles {
        font-size: 12px;
        line-height: 14px;
    }

    .referral-data-col-3 {
        max-width: 85px;
        width: 100%;
        flex: 0 0 85px;
    }

    .private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(n + 1) {

        margin-left: 0;
        margin-right: 16.5px;
    }

    .private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(2n + 2) {
        margin-right: 0;
        margin-left: 16.5px;
    }

    .dashboard-graph-flex {
        margin: 0px 0px 27px;
    }

    .page-right-main {
        padding: 30px 0px 67px 15px;
    }

    .transaction-main,
    .referral-data-main {
        max-width: 926px;
    }

    .primary-btn-main .buy-token-btn,
    .primary-btn-main .buy-token-btn:hover {
        margin-right: 0px;
        min-width: 70px;
        height: 40px;
        padding: 9.5px;
        font-size: 12px;
        line-height: 12px;
    }

    .dash-token-content .dash-token-text {
        font-size: 11px;
        line-height: 13px;
        margin-left: 10px;
    }

    .private-price-card-main {
        max-width: 390px;
        height: auto;
        padding: 15px;
    }

    .dash-token-content .dash-token-price {
        font-size: 14px;
        line-height: 18px;
        margin-left: 10px;
    }

    .private-sale-main {
        width: 100%;
    }


    .private-sale-col-left {
        max-width: 620px;
        width: 100%;
        flex: 0 0 620px;
        padding: 20px 20px;
    }

    .private-amount-col {
        margin-right: 78px;
    }

    .private-price-card-main {
        max-width: 100%;
        height: 343px;
    }

    .dash-card-content .dash-price-label {
        font-size: 10px;
        line-height: 12px;
    }

    /* .private-card-content-flex .private-card-content:nth-child(1),
    .private-card-content-flex .private-card-content:nth-child(2),
    .private-card-content-flex .private-card-content:nth-child(3) {
        max-width: 76px;
        width: 100%;
        flex: 0 0 76px;
        margin-right: 15px;
    } */
    .primary-btn-main .make-payment-btn,
    .primary-btn-main .make-payment-btn:hover {
        max-width: 155px;
        margin-bottom: 16px;
    }

    .user-sidebar-dark-text {
        font-size: 14px;
        line-height: 20px;
    }

    .private-sale-card-2 .private-sale-card-title {
        font-size: 17px;
        line-height: 19px;
    }

    .user-need-sidebar .sidebar-hightlight-text {
        font-size: 15px;
        line-height: 15px;
    }

    .price-flex .price-span {
        font-size: 15px;
        line-height: 17px;
    }

    .private-sale-usd-data .private-sale-usd-amount {
        font-size: 18px;
        line-height: 21px;
    }

    .price-flex .card-price {
        font-size: 15px;
        line-height: 17px;
    }

    .user-sidebar .user-sidebar-link {
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 20px;
    }

    .private-checkbox-main .private-checkbox-lable {
        font-size: 12px;
        line-height: 14px;
    }

    .private-amount-col .private-amount-lable {
        font-size: 10px;
        line-height: 12px;
    }

    .private-sale-col-left .private-amount-para {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0px 10px 0px;
    }

    .private-price-card-main {
        max-width: 100%;
        height: 343px;
    }

    .auth-inner {
        min-height: 500px;
        overflow: auto;
        padding: 50px 0px;
    }

    .auth-logo-main {
        margin-bottom: 25px;
    }

    .auth-inner ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    .auth-inner ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    .auth-inner ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    .auth-inner ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .private-sale-wallet-main {
        width: 100%;
    }


    .private-price-card-main {
        max-width: 100%;
    }

    .green-btn-main .buy-token-btn , .green-btn-main .buy-token-btn:hover{
        min-width: 105px;
        max-width: 105px;
        font-size: 11px;
        margin-right: 27px;
        height: 35px;
    }
    .private-token-value-box {
        height: 45px;
    }
    .private-token-value-main .private-token-value-text {
        font-size: 17px;
        line-height: 25px;
    }
    .trans-btn-main .trans-btn, .trans-btn-main .trans-btn:hover {
        font-size: 13px;
        line-height: 16px;
        min-width: 131px;
        height: 35px;
    }
    .dashboard-referral-flex .dash-view-text {
        font-size: 13px;
        line-height: 16px;
    }
    .trans-data-main {
        max-width: 1000px;
        overflow: auto;
    }

    .private-sale-data-main .private-sale-titles {
        font-size: 16px;
        line-height: 17px;
    }

    .private-sale-data-main .private-sale-para {
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 25px;
    }

    .trans-field .MuiFormControl-root {
        width: 100%;
        min-width: 0px;
    }

    .purchas-data-col-2,
    .purchas-data-col-3,
    .purchas-data-col-4,
    .purchas-data-col-6,
    .purchas-data-col-7,
    .purchas-data-col-8,
    .purchas-data-col-9 {
        max-width: 120px;
        width: 100%;
        flex: 0 0 120px;
    }

    .purchas-data-col-5 {
        max-width: 170px;
        width: 100%;
        flex: 0 0 170px;
    }

    .header-logo {
        max-width: 80px;
        height: 25px;
    }

    .dash-token-inner-box {
        max-width: 100%;
        padding: 0px 50px;
    }

    .header-btn-main .head-play-btn-main {
        height: 33px;
    }

    .user-dash-third-row {
        margin-bottom: 0px;
    }

    .modal .payment-modal-inner {
        height: calc(100% - 80px);
        overflow: auto;
        max-height: 600px;
    }

    .modal .modal-inner {
        height: calc(100% - 80px);
        overflow: auto;
    }

    .wallet-modal .modal-wallet-inner {
        max-width: 614px;
        padding: 30px 30px;
        max-height: 570px;
    }

    .dash-icon-main {
        width: 34px;
        height: 34px;
    }

    .private-icons {
        height: 17px;
        width: 17px;
    }

    .private-icon-text-main .private-icon-price {
        font-size: 20px;
        line-height: 25px;
    }

    .private-card {
        padding: 18px 15px 18px;
        max-width: 300px;
        margin-right: 8px;
    }
    .tabs-main .tab {
        font-size: 13px;
        line-height: 17px;
    }
    .input-box .form-lable {
        font-size: 13px;
        line-height: 14px;
    }
    .profile-main .profile-para {
        font-size: 14px;
        line-height: 17px;
    }
    .grey-btn-main .disable-btn {
        margin-right: 0px;
    }
    .dash-icons {
        height: 18px;
        width: 18px;
    }
    .primary-btn-main .fa2-btn, .primary-btn-main .fa2-btn:hover {
        min-width: 110px;
        height: 35px;
        font-size: 15px;
    }
    .trans-head-row .trans-title {
        font-size: 20px;
        line-height: 24px;
    }
    .page-right-main .page-titles {
        font-size: 20px;
        line-height: 24px;
    }
    .private-card-wallet-col {
        width: calc(50% - 17px);
    }
}


























@media screen and (min-width: 992px) and (max-width:1199px) {
    .container {
        max-width: 960px;
        margin: auto;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .text-copy-box {
        max-width: 455px;
        max-width: 457px;
    }

    .primary-border-btn-main .history-btn {
        min-width: 77px;
    }

    .referral-data-main-dash {
        max-width: 100%;
    }

    .grey-btn-main .withdrow-btn {
        width: 128px;
        margin-right: 15px;
    }

    .private-card-wallet-col {
        width: calc(100% / 2 - 33px);
    }

    .private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(n + 1) {

        margin-left: 16.5px;
        margin-right: 16.5px;
    }

    .private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(2n + 2) {
        margin-right: 0;
        margin-left: 16.5px;
    }


    .header-logo {
        margin-right: 5px;
        max-width: 65px;
    }

    .header-nav-main .header-nav-link {
        font-size: 11px;
        line-height: 8px;
        margin-right: 15px;
    }

    .squre-icon {
        margin-right: 0px;
        width: 10px;
        height: 10px;
        margin-left: 7px;
    }

    .user-sidebar-dark-text {
        font-size: 16px;
        line-height: 17px;
    }

    .user-sidebar .user-sidebar-link {
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 18px;
    }

    .header-nav-li .connect-btn {
        font-size: 12px;
        line-height: 15px;
    }

    .header-logo-box .token-logo-text {
        font-size: 16px;
        line-height: 16px;
        padding-left: 7px;
    }

    .header-logo-box .token-logo-text::before {
        margin-right: 5px;
        height: 17px;
    }

    .hero-content-main .hero-titles {
        font-size: 26px;
        line-height: 37px;
        max-width: 800px;
        margin: 0px auto 50px;
    }

    .hero-first-row {
        padding-top: 70px;
        max-width: 950px;
        margin: auto;
    }

    .hero-second-row {
        max-width: 950px;
        margin: auto;
    }

    .stake-token-flex-row {
        justify-content: center;
    }

    .hero-progress-inner {
        min-width: 417px;
    }

    .banner-video {
        height: 500px;
    }

    .stake-token-col-1 {
        max-width: 113px;
        width: 100%;
        flex: 0 0 113px;
    }

    .stake-token-col-2,
    .stake-token-col-3,
    .stake-token-col-4,
    .stake-token-col-5,
    .stake-token-col-6,
    .stake-token-col-7,
    .stake-token-col-8,
    .stake-token-col-9 {
        max-width: 95px;
        width: 100%;
        flex: 0 0 95px;
        margin-right: 5px;
    }

    .advance-main .advance-titles {
        font-size: 37px;
        line-height: 43px;
    }

    .hero-btn-main .hero-fill-btn,
    .hero-btn-main .hero-fill-btn:hover {
        min-width: 253px;
    }

    .hero-btn-main .hero-btn {
        font-size: 13px;
        line-height: 16px;
        padding: 10.5px 14.5px;
    }

    .hero-content-main .hero-sub-para {
        font-size: 18px;
        line-height: 21px;
    }

    .hero-bonus-content-main .hero-bonus-price {
        font-size: 20px;
        line-height: 22px;
    }

    .hero-progress-main .hero-progress-txet {
        font-size: 14px;
        line-height: 16px;
    }

    .token-logo-main .token-logo-text {
        font-size: 28px;
        line-height: 28px;
    }

    .token-logo {
        height: 28px;
        margin-right: 0px;
    }

    .token-logo-main .token-logo-text::before {
        height: 29px;
    }

    .stake-token-heading-main .stake-token-heading {
        font-size: 13px;
        line-height: 15px;
        min-height: 80px;
    }

    .page-title-right-box .page-title-right {
        font-size: 27px;
        line-height: 34px;
    }

    .stake-token-data-flex .stake-token-label {
        font-size: 14px;
        line-height: 18px;
    }

    .home-token-utility-sec {
        padding: 50px 0px 16px;
    }

    .main-titles-box .main-titles {
        margin-bottom: 40px;
        font-size: 28px;
        line-height: 28px;
    }

    .home-token-utility-box .home-token-utility-titles {
        font-size: 19px;
        line-height: 30px;
    }

    .home-token-utility-box .home-token-utility-para {
        font-size: 13px;
        line-height: 20px;
    }

    .home-utility-icons {
        height: 25px;
        width: 25px;
    }

    .home-token-main .home-token-li {
        margin: 0 59px 42px 0px;
    }

    .advance-main .advance-titles {
        font-size: 30px;
        line-height: 38px;
    }

    .advance-main .advance-para {
        font-size: 13px;
        line-height: 15px;
    }

    .advance-data-head .advance-data-titles {
        font-size: 16px;
        line-height: 20px;
    }

    .advance-data-box .advance-data-text {
        font-size: 13px;
        line-height: 18px;
    }

    .earn-token-left-main .earn-token-title {
        font-size: 26px;
        line-height: 38px;
    }

    .earn-token-card .earn-token-price {
        font-size: 25px;
        line-height: 43px;
    }

    .earn-token-sec {
        padding: 50px 0px 50px;
    }

    .token-private-sale-sec {
        padding: 50px 0px 50px;
    }

    .earn-token-card .earn-token-price {
        font-size: 36px;
        line-height: 43px;
    }

    .earn-token-card .earn-token-card-title {
        font-size: 15px;
        line-height: 17px;
    }

    .earn-token-card .earn-token-btn,
    .earn-token-card .earn-token-btn:hover {
        height: 36px;
        font-size: 15px;
        line-height: 19px;
    }

    .token-private-sale-main {
        padding: 30px 30px 30px;
    }

    .token-private-data-main .token-table-th {
        font-size: 15px;
        line-height: 19px;
        min-width: 280px;
    }

    .token-private-data-main .token-table-td {
        font-size: 14px;
        line-height: 18px;
        padding: 20px 20px 14px;
        min-width: 280px;
    }

    .token-table-container .token-table {
        min-width: 1130px;
    }

    /* .token-private-data-main .token-table .token-table-row:first-child .token-table-td {
        padding: 13px 20px 13px;
    } */

    .token-private-notes-main .token-private-note-para {
        font-size: 13px;
        line-height: 21px;
    }

    .token-private-notes-main .token-private-note-titels {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 11px;
    }

    .rewards-program-sec {
        padding: 50px 0px 50px;
    }

    .token-private-sale-title-box .token-private-sale-title {
        font-size: 25px;
        line-height: 33px;
        margin-bottom: 28px;
    }

    .rewards-main .rewards-titles {
        font-size: 27px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    .rewards-main .rewards-titles-para {
        font-size: 14px;
        line-height: 22px;
    }

    .rewards-main-flex .rewards-data {
        font-size: 15px;
        line-height: 23px;
    }

    .rewards-main-flex {
        height: 70px;
    }

    .rewards-round-box {
        width: 70px;
        height: 70px;
    }

    .rewards-main .rewards-note-para {
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 7px;
    }

    .home-token-allocation-sec {
        padding: 50px 0px 50px;
    }

    .home-roadmap-sec {
        padding: 50px 0px;
    }

    .page-center-title-box .page-center-title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 40px;
    }

    .roadmap-content-box .roadmap-quarter-text {
        font-size: 12px;
        line-height: 16px
    }

    .roadmap-content-box .roadmap-para {
        font-size: 14px;
        line-height: 17px;
    }

    .roadmap-content-box .roadmap-quarter-date {
        font-size: 14px;
        line-height: 17px;
    }

    .footer-sec {
        padding: 70px 0px 40px;
    }

    .footer-inner-main {
        padding-left: 10px;
        padding-right: 10px;
    }

    .footer-col .footer-title {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 10px;
    }

    .footer-nav-main .footer-nav-link {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 16px;
    }

    .advance-col-1 {
        max-width: 220px;
        width: 100%;
        flex: 0 0 220px;
        margin-right: 80px;
    }

    .advance-col-2,
    .advance-col-3,
    .advance-col-4 {
        max-width: 140px;
        width: 100%;
        flex: 0 0 140px;
        margin-right: 80px;
    }

    .earn-token-left-main {
        max-width: 50%;
        flex: 0 0 50%;
        margin-right: 50px;
    }

    .header-btn-main .header-switch-btn {
        min-width: 67px;
    }

    .header-btn-main .header-btn {
        font-size: 12px;
        line-height: 16px;
        margin-right: 7px;
    }

    .header-btn-main .header-signout-btn {
        min-width: 70px;
    }

    .private-sale-row {
        flex-direction: column;
    }

    .private-card-content-flex {
        justify-content: space-between;
    }

    .private-sale-col-right {
        margin-left: 0px;
        margin-top: 20px;
    }

    .modal-wallet-flex .primary-border-btn-main:first-child {
        margin-right: 20px;
    }


    .private-card-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .private-card-1,
    .private-card-2,
    .private-card-3,
    .private-card-4 {
        margin-bottom: 15px;
    }

    .page-right-main {
        padding: 30px 0px 30px 10px;
    }

    .private-sale-final-main {
        width: 100%;
    }

    .private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(n + 1) {

        margin-left: 0px;
        margin-right: 16.5px;
    }

    .private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(2n + 2) {
        margin-right: 0;
        margin-left: 16.5px;
    }


    .private-sale-final-col {
        width: calc(100% / 2 - 33px);
    }

    .private-sale-wallet-card-flex .private-card:first-child {
        margin-right: 20px;
    }

    .private-sale-wallet-card-flex {
        display: flex;
        justify-content: space-between;
    }

    .private-sale-wallet-main {
        width: 100%;
        margin-bottom: 0px;
    }

    .private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(1n+3) {
        padding-right: 0px;
        padding-left: 0px;
    }

    .primary-btn-main .locked-btn,
    .primary-btn-main .locked-btn:hover {
        margin-left: 0px;
    }

    .modal-wallet-flex .primary-border-btn-main:first-child {
        margin-right: 20px;
    }

    .private-caard-row {
        flex-direction: column;
    }

    .user-dashboard-main {
        width: 100%;
    }

    .user-dash-first-row-left {
        max-width: 375px;
        width: 100%;
        flex: 0 0 375px;
    }



    .gradiant-border {
        width: 100%;
    }

    .dash-progressbar {
        max-width: 100%;
    }

    .dash-token-inner-box {
        max-width: 100%;
        padding: 0px 50px;
    }

    /* .text-copy-box {
        max-width: 100%;
    } */
    .dash-token-inner-box {
        max-width: 100%;
        padding: 0px 20px;
    }

    .referral-btn-main {
        justify-content: space-between;
        width: 100%;
    }

    .dash-referral-btn-main {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .green-btn-main .running-btn {
        margin-left: 0px;
    }

    .dashboard-referral-col {
        padding: 15px;
        height: auto;
    }

    .dash-token-col-1 {
        max-width: 137px;
        width: 100%;
        flex: 0 0 137px;
        margin-right: 5px;
    }

    .dash-token-col-2,
    .dash-token-col-3,
    .dash-token-col-5,
    .dash-token-col-6,
    .dash-token-col-7 {
        max-width: 110px;
        width: 100%;
        flex: 0 0 110px;
        margin-right: 5px;
    }

    .dash-token-col-4 {
        max-width: 185px;
        width: 100%;
        flex: 0 0 185px;
        margin-right: 5px;
    }

    .gradiant-border {
        width: 100%;
    }

    .dash-token-col-1 {
        max-width: 100px;
        width: 100%;
        flex: 0 0 100px;
        margin-right: 5px;
    }

    .dash-token-col-2, .dash-token-col-3, .dash-token-col-5, .dash-token-col-6, .dash-token-col-7 {
        max-width: 94px;
        width: 100%;
        flex: 0 0 94px;
        margin-right: 5px;
    }

    .dash-token-col-7 {
        max-width: 90px;
        width: 100%;
        flex: 0 0 90px;
        margin-right: 0px;
    }

    .dash-token-col-4 {
        max-width: 154px;
        width: 100%;
        flex: 0 0 154px;
        margin-right: 5px;
    }
    .dash-token-data-round {
        height: 10px;
        width: 10px;
    }

    .dash-token-data-flex .dash-token-label {
        font-size: 14px;
        line-height: 14px;
        text-align: center;
    }

    .dash-token-data-flex .dash-token-label {
        font-size: 14px;
        line-height: 14px;
        text-align: center;
    }

    .dash-token-heading-main .dash-token-heading {
        font-size: 13px;
        line-height: 15px;
    }

    .dash-token-data-flex {
        padding: 7px 7px;
        align-items: center;
    }

    .user-dash-first-row-right {
        margin-left: 0px;
    }

    .user-dash-first-row-left,
    .user-dash-first-row-right {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .user-dash-first-row {
        width: 100%;
        flex-direction: column;
    }

    .dash-price-card .price-card-main {
        max-width: 100%;
    }

    .dash-price-card {
        margin-bottom: 20px;
    }

    .dashboard-card-1 {
        max-width: 100%;
        margin-bottom: 15px;
    }

    .header-play-scanner-box .head-scan-text {
        font-size: 11px;
        line-height: 17px;
    }

    .user-dash-third-row {
        margin-bottom: 0px;
    }

    .price-flex {
        justify-content: space-between;
    }

    .trans-data-main {
        overflow: auto;
        width: 100%;
    }

    .trans-data-inner {
        margin-right: 0px;
    }

    .purchas-data-col-8 {
        max-width: 100px;
        width: 100%;
        flex: 0 0 100px;
    }

    .purchas-data-col-4 {
        max-width: 100px;
        width: 100%;
        flex: 0 0 100px;
    }

    .trans-btn-main .trans-btn,
    .trans-btn-main .trans-btn:hover {
        height: 40px;
    }

    .trans-field .MuiFormControl-root {
        width: 100%;
        min-width: auto;
    }

    .trans-head-row {
        align-items: self-start;
        flex-direction: column;
    }

    .trans-head-row .trans-title {
        margin-bottom: 20px;
    }

    .referral-text-copy-box {
        max-width: initial;
        min-width: initial;
    }

    .auth-inner {
        min-height: 500px;
        overflow: auto;
        padding: 50px 0px;
    }

    .auth-logo-main {
        margin-bottom: 25px;
    }

    .auth-inner ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    .auth-inner ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    .auth-inner ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    .auth-inner ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .private-amount-col .private-amount-lable {
        font-size: 14px;
        line-height: 19px;
    }

    .private-price-card-main {
        max-width: 100%;
    }

    .header-landing-btn-main .header-btn {
        font-size: 10px;
        line-height: 14px;
        height: 33px;
        min-width: 93px;
        padding: 5px;
    }

    .green-btn-main .buy-token-btn {
        min-width: 140px;
        max-width: 140px;
    }

    .purchas-data-col-2,
    .purchas-data-col-3,
    .purchas-data-col-4,
    .purchas-data-col-6,
    .purchas-data-col-7,
    .purchas-data-col-8,
    .purchas-data-col-9 {
        max-width: 120px;
        width: 100%;
        flex: 0 0 120px;
    }

    .purchas-data-col-5 {
        max-width: 170px;
        width: 100%;
        flex: 0 0 170px;
    }

    .dash-token-inner-box {
        max-width: 100%;
        padding: 0px 20px;
    }

    .footer-email-main .footer-go-btn,
    .footer-email-main .footer-go-btn:hover {
        right: -12px;
    }

    .price-card-main {
        height: 433px;
        padding: 15px 12px;
    }

    .modal .payment-modal-inner {
        height: calc(100% - 80px);
        overflow: auto;
        max-height: 500px;
    }

    .modal .modal-inner {
        height: calc(100% - 80px);
        overflow: auto;
    }

    .wallet-modal .modal-wallet-inner {
        padding: 30px 30px;
        max-height: 470px;
    }

    .private-payment-modal .payment-modal-inner {
        padding: 30px;
    }
    .dashboard-card-3 , .dashboard-card-2{
        max-width: 361px;
        min-width: 369px;
    }
}























@media screen and (max-width:991px) {
    .container {
        max-width: 1140px;
        margin: auto;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .referral-data-main-dash {
        max-width: 100%;
    }

    .display-row {
        align-items: center;
        display: flex;
    }

    .header-nav-main .header-nav-link {
        font-size: 10px;
        line-height: 16px;
        margin-right: 22px;
    }

    .hero-content-main .hero-titles {
        font-size: 20px;
        line-height: 37px;
        max-width: 650px;
        margin: 0px auto 50px;
    }

    .page-title-row {
        margin-bottom: 25px;
    }

    .hero-first-row {
        padding-top: 50px;
        max-width: 700px;
        margin: auto;
    }

    .hero-second-row {
        max-width: 700px;
        margin: auto;
    }

    .banner-video {
        min-height: 650px;
        max-height: 650px;
    }

    .stake-token-col-1 {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .stake-token-col-2,
    .stake-token-col-3,
    .stake-token-col-4,
    .stake-token-col-5,
    .stake-token-col-6,
    .stake-token-col-7,
    .stake-token-col-8,
    .stake-token-col-9 {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
        margin-right: 5px;
    }

    .advance-main .advance-titles {
        font-size: 37px;
        line-height: 43px;
    }

    .hero-btn-main .hero-fill-btn,
    .hero-btn-main .hero-fill-btn:hover {
        min-width: 253px;
    }

    .hero-btn-main .hero-btn {
        font-size: 13px;
        line-height: 16px;
        padding: 10.5px 14.5px;
    }

    .hero-content-main .hero-sub-para {
        font-size: 18px;
        line-height: 21px;
    }

    .hero-bonus-content-main .hero-bonus-price {
        font-size: 20px;
        line-height: 22px;
    }

    .hero-progress-main .hero-progress-txet {
        font-size: 14px;
        line-height: 16px;
    }

    .hero-progress-main .hero-progress-txet {
        font-size: 16px;
        line-height: 19px;
    }

    .token-logo {
        height: 28px;
        margin-right: 0px;
    }

    .token-logo-main .token-logo-text::before {
        height: 29px;
    }

    .stake-token-heading-main .stake-token-heading {
        font-size: 13px;
        line-height: 15px;
        min-height: 129px;
    }

    .page-title-right-box .page-title-right {
        font-size: 26px;
        line-height: 31px;
        text-align: left;
        margin-top: 0px;
    }

    .stake-token-sec {
        padding: 50px 0px 50px;
    }

    .stake-token-data-flex .stake-token-label {
        font-size: 11px;
        line-height: 13px;
        text-align: center;
    }

    .home-token-utility-sec {
        padding: 50px 0px 16px;
    }

    .main-titles-box .main-titles {
        margin-bottom: 29px;
        font-size: 24px;
        line-height: 18px;
    }

    .home-token-utility-box .home-token-utility-titles {
        font-size: 16px;
        line-height: 27px;
    }

    .home-token-utility-box .home-token-utility-para {
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 50px;
    }

    .home-token-utility-box {
        height: 242px;
    }

    .home-utility-icons {
        height: 25px;
        width: 25px;
    }

    .home-token-main .home-token-li {
        margin: 0 59px 42px 0px;
    }

    .advance-sec {
        padding: 20px 0px 50px;
    }

    .advance-main .advance-titles {
        font-size: 30px;
        line-height: 38px;
    }

    .advance-main .advance-titles {
        font-size: 26px;
        line-height: 32px;
    }

    .advance-data-body {
        padding: 0px 0px 0px 20px;
    }

    .rewards-main {
        max-width: 900px;
    }

    .advance-data-head .advance-data-titles {
        font-size: 14px;
        line-height: 17px;
    }

    .advance-data-box .advance-data-text {
        font-size: 13px;
        line-height: 18px;
    }

    .earn-token-left-main .earn-token-title {
        font-size: 26px;
        line-height: 38px;
    }

    .earn-token-card .earn-token-price {
        font-size: 25px;
        line-height: 43px;
    }

    .earn-token-sec {
        padding: 50px 0px 50px;
    }

    .token-private-sale-sec {
        padding: 50px 0px 50px;
    }

    .earn-token-card .earn-token-price {
        font-size: 36px;
        line-height: 43px;
    }

    .earn-token-card .earn-token-card-title {
        font-size: 15px;
        line-height: 17px;
    }

    .earn-token-card .earn-token-btn,
    .earn-token-card .earn-token-btn:hover {
        height: 36px;
        font-size: 15px;
        line-height: 19px;
    }

    .token-private-sale-main {
        padding: 30px 30px 30px;
    }

    .token-private-data-main .token-table-th {
        font-size: 15px;
        line-height: 19px;
        min-width: 230px;
    }

    .token-private-data-main .token-table-td {
        font-size: 14px;
        line-height: 18px;
        padding: 20px 20px 14px;
        min-width: 230px;
    }

    .token-table-container .token-table {
        min-width: fit-content;
    }

    /* .token-private-data-main .token-table .token-table-row:first-child .token-table-td {
        padding: 13px 20px 13px;
    } */

    .token-private-notes-main .token-private-note-para {
        font-size: 13px;
        line-height: 21px;
    }

    .token-private-notes-main .token-private-note-titels {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 11px;
    }

    .rewards-program-sec {
        padding: 50px 0px 50px;
    }

    .token-private-sale-title-box .token-private-sale-title {
        font-size: 25px;
        line-height: 33px;
        margin-bottom: 28px;
    }

    .rewards-main .rewards-titles {
        font-size: 27px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    .rewards-main .rewards-titles-para {
        font-size: 14px;
        line-height: 22px;
    }

    .rewards-main-flex .rewards-data {
        font-size: 15px;
        line-height: 23px;
    }

    .rewards-main-flex {
        height: 70px;
    }

    .rewards-round-box {
        width: 70px;
        height: 70px;
    }

    .rewards-main .rewards-note-para {
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 7px;
    }

    .home-token-allocation-sec {
        padding: 50px 0px 50px;
    }

    .home-roadmap-sec {
        padding: 50px 0px;
    }

    .home-token-main {
        max-width: 900px;
    }

    .page-center-title-box .page-center-title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 40px;
    }

    .roadmap-content-box .roadmap-quarter-text {
        font-size: 12px;
        line-height: 16px
    }

    .roadmap-content-box .roadmap-para {
        font-size: 14px;
        line-height: 17px;
    }

    .roadmap-content-box .roadmap-quarter-date {
        font-size: 14px;
        line-height: 17px;
    }

    .roadmap-main {
        max-width: 900px;
    }

    .footer-sec {
        padding: 70px 0px 40px;
    }

    .footer-inner-main {
        padding-left: 260px;
        padding-right: 286px;
    }

    .footer-col .footer-title {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 10px;
    }

    .footer-inner-main {
        padding-left: 0px;
        padding-right: 0px;
    }

    .footer-playstore-img {
        width: 105px;
    }

    .footer-scanner-img {
        display: flex;
    }

    .footer-paly-right .Footer-scanner-text {
        font-size: 12px;
        line-height: 14px;
    }

    .footer-playstore-main {
        order: 2;
        margin: 0px;
    }

    .footer-privacy-main {
        padding-top: 30px;
    }

    .footer-social-main .footer-social-link {
        height: 42px;
        width: 42px;
    }


    .footer-nav-main .footer-nav-link {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 16px;
    }

    .advance-col-1 {
        max-width: 220px;
        width: 100%;
        flex: 0 0 220px;
        margin-right: 80px;
    }

    .advance-col-2,
    .advance-col-3,
    .advance-col-4 {
        max-width: 140px;
        width: 100%;
        flex: 0 0 140px;
        margin-right: 80px;
    }

    .earn-token-left-main {
        max-width: 50%;
        flex: 0 0 50%;
        margin-right: 50px;
    }

    .hero-progress-inner {
        min-width: 224px;
    }

    .hero-bonus-flex .hero-bonus-content-main:first-child {
        margin-right: 30px;
    }

    .header-btn-main .header-switch-btn,
    .header-btn-main .header-signout-btn {
        max-width: 255px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        border-radius: 5px;
        font-size: 19px;
    }

    .hero-content-main {
        padding-top: 70px;
    }

    .trans-data-main {
        overflow-x: auto;
        width: 100%;
    }

    .page-inner-main {
        flex-direction: column;
    }

    .trans-data-inner {
        margin-right: 0px;
    }

    .purchas-data-col-8 {
        max-width: 100px;
        width: 100%;
        flex: 0 0 100px;
    }

    .purchas-data-col-4 {
        max-width: 100px;
        width: 100%;
        flex: 0 0 100px;
    }

    .trans-btn-main .trans-btn,
    .trans-btn-main .trans-btn:hover {
        height: 40px;
    }

    .modal .payment-modal-inner {
        height: calc(100% - 80px);
        overflow: auto;
        max-height: 500px;
    }

    .modal .modal-inner {
        height: calc(100% - 80px);
        overflow: auto;
    }

    .wallet-modal .modal-wallet-inner {
        padding: 30px 30px;
        max-height: 470px;
    }

    .private-payment-modal .payment-modal-inner {
        padding: 30px;
    }
}



























@media screen and (min-width: 768px) and (max-width: 991px) {
    .price-flex {
        justify-content: space-between;
    }

    .primary-btn-main .locked-btn,
    .primary-btn-main .locked-btn:hover {
        margin-left: 0px;
    }

    .private-card-wallet-col {
        width: calc(100% / 2 - 33px);
    }

    .private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(n + 1) {

        margin-left: 16.5px;
        margin-right: 16.5px;
    }

    .private-sale-wallet-row {
        margin-left: -16.5px;
        margin-right: -16.5px;
    }

    .private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(2n + 2) {
        margin-right: 0;
        margin-left: 16.5px;
    }

    .mobile-drawer-btn-main {
        display: block;
    }

    .mobile-menu-icon {
        width: 23px;
        height: 24px;
        object-fit: contain;
        margin-right: 13px;
    }

    .header-landing-btn-main {
        display: none;
    }

    .header-logo-box .mobile-menu-btn {
        display: block;
    }

    .header-sec {
        padding: 18px 29px 20px 20px;
    }

    .squre-icon {
        display: none;
    }

    .header-btn-main {
        flex-direction: column;
    }

    .header-btn-main .header-switch-btn {
        border: 1.5px solid #34A9FF;
        border-radius: 5px;
        min-width: 255px;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .mobile-dropdown-main .mobile-dro-btn {
        width: 160px;
        height: 33px;
        background: #000000;
        border: 1.5px solid #34A9FF;
        box-shadow: 0px 0px 12px 6px rgba(52, 169, 255, 0.4);
        border-radius: 7px;
        text-transform: capitalize;
        color: #FFFFFF;
        font-size: 12px;
        line-height: 14px;
        font-family: "Urbanist-Medium";
    }

    .header-logo {
        width: 107.03px;
        height: 22px;
        object-fit: contain;
        margin-right: 0px;
    }

    .display-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mobile-drop-ul-main .mobile-drop-list {
        padding: 15px 18px 16px;
        margin: 0px;
        width: 133px;
        background: rgba(23, 23, 23, 0.9);
    }

    .mobile-drop-ul-main .mobile-drop-item {
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        font-family: 'Urbanist-Medium';
        padding: 0px;
        margin-bottom: 13px;
    }

    .mobile-dropdown-main {
        position: relative;
    }

    .mobile-drop-ul-main {
        position: absolute;
        right: 0px;
        top: 52px;
    }

    .header-nav-main .header-nav-ul {
        flex-direction: column;
    }

    .header-nav-main .header-nav-link {
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 24px;
        margin-right: 0px;
    }

    .header-nav-main .header-nav-li {
        justify-content: center;
        width: auto;
    }

    .header-nav-main {
        position: fixed;
        left: -1000px;
        transition: 0.5s all;
        background: rgba(0, 0, 0, 0.75);
        width: 100%;
        display: flex;
        justify-content: center;
        height: 100vh;
        top: 0px;
        margin: 66px 0px 0px 0px;
        right: 0px;
        overflow: auto;
        z-index: 999;
        padding-top: 40px;
    }

    .trans-field .MuiFormControl-root {
        width: 100%;
        min-width: auto;
    }

    .header-nav-main.active {
        left: 0px;
    }

    .header-logo-box .mobile-menu-btn {
        min-width: auto;
        padding: 0px;
        height: 24px;
    }

    .display-row>* {
        padding: 0px 0px;
    }

    .mobile-dropdown-main {
        display: block;
    }

    .mobile-switch-main {
        display: block;
    }

    .header-swtch-main {
        display: none;
    }

    .stake-token-col-1 {
        max-width: 100%;
        width: 100%;
        flex: 1;
    }

    .stake-token-col-2,
    .stake-token-col-3,
    .stake-token-col-4,
    .stake-token-col-5,
    .stake-token-col-6,
    .stake-token-col-7,
    .stake-token-col-8,
    .stake-token-col-9 {
        max-width: 73px;
        width: 100%;
        flex: 0 0 73px;
        margin-right: 5px;
    }

    .advance-col-2,
    .advance-col-3,
    .advance-col-4 {
        max-width: 101px;
        width: 100%;
        flex: 0 0 101px;
        margin-right: 60px;
    }

    .advance-col-1 {
        max-width: 200px;
        width: 100%;
        flex: 0 0 200px;
        margin-right: 80px;
    }

    .footer-privacy-main .footer-para {
        padding-top: 29px;
    }

    .colspan-2 {
        min-height: 86px;
    }

    .page-right-main {
        padding: 30px 0px 30px 0px;
    }

    .private-card-content-flex {
        justify-content: space-between;
    }

    .private-price-card-main {
        max-width: 100%;
    }

    .page-left-main {
        display: none;
    }

    .page-main-box {
        padding: 20px;
    }

    .profile-main {
        width: 100%;
    }

    .private-sale-row {
        flex-direction: column;
    }

    .private-sale-card-1,
    .private-sale-card-2 {
        width: 100%;
    }

    .private-price-card-main {
        width: 100%;
    }

    .private-sale-main {
        width: 100%;
    }

    .body-overflow {
        overflow: hidden;
    }

    .private-amount-col .private-amount-lable {
        font-size: 13px;
        line-height: 15px;
    }

    .private-sale-final-main {
        width: 100%;
    }

    .private-price-card-main {
        max-width: 100%;
    }

    .dash-token-data-content .dash-token-data-price {
        font-size: 17px;
        line-height: 20px;
    }

    .text-copy-box {
        max-width: 100%;
    }

    .private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(3n + 1) {
        margin-left: 16.5px;
    }

    .private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(n + 1) {

        /* margin-left: 0px; */
        margin-right: 16.5px;
    }

    .private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(2n + 2) {
        margin-right: 0;
        margin-left: 16.5px;
    }


    .private-sale-final-col {
        width: calc(100% / 2 - 33px);
    }

    .referral-btn-main {
        justify-content: space-between;
        width: 100%;
    }

    .dash-progressbar {
        max-width: 100%;
    }

    .header-col-3 {
        margin-left: auto;
    }


    .private-card-row {
        margin-left: 0px;
        margin-right: 0px;
        flex-wrap: wrap;
    }

    .private-card-2,
    .private-card-1,
    .private-card-3,
    .private-card-4 {
        margin-right: 0px;
        max-width: 100%;
        margin-bottom: 15px;
    }



    .private-sale-wallet-card-flex .private-card:first-child {
        margin-right: 20px;
    }

    .private-card-wallet-col .private-card-main {
        min-height: 300px;
    }

    .private-sale-wallet-card-flex {
        flex-direction: column;
    }

    .private-sale-wallet-card-flex {
        display: flex;
        justify-content: space-between;
    }

    .private-sale-wallet-main {
        width: 100%;
        margin-bottom: 0px;
    }

    .private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(1n+3) {
        padding-right: 0px;
        padding-left: 0px;
    }

    .private-card-wallet-col {
        padding-right: 0px;
        padding-left: 0px;
    }


    .private-token-value-main .private-token-value-inner:nth-child(2n+2) {
        margin-right: 10px;
    }

    .private-token-value-inner {
        margin-right: 10px;
    }

    .private-token-value-main .private-token-value-inner:last-child {
        margin-right: 0px;
    }

    .modal-wallet-flex .primary-border-btn-main:first-child {
        margin-right: 20px;
    }

    .gradiant-border {
        width: 100%;
    }

    .dash-token-col-1 {
        max-width: 100%;
        width: 100%;
        flex: 1;
        margin-right: 5px;
    }

    .dash-token-col-5,
    .dash-token-col-6,
    .dash-token-col-7 {
        max-width: 80px;
        width: 100%;
        flex: 0 0 80px;
    }

    .dash-token-col-2 {
        max-width: 115px;
        width: 100%;
        flex: 0 0 115px;
    }

    .dash-token-col-3 {
        max-width: 100px;
        width: 100%;
        flex: 0 0 100px;
    }

    .dash-token-data-round {
        height: 10px;
        width: 10px;
    }

    .dash-token-data-flex .dash-token-label {
        font-size: 12px;
        line-height: 13px;
        text-align: center;
    }

    .dash-token-heading-main .dash-token-heading {
        font-size: 11px;
        line-height: 16px;
        padding-bottom: 5px;
    }

    .dash-token-data-flex .dash-token-label {
        font-size: 12px;
        line-height: 13px;
        text-align: center;
    }

    .dash-token-data-flex {
        padding: 7px 7px;
        align-items: center;
    }

    .user-dash-first-row-right {
        margin-left: 0px;
    }

    .user-dash-first-row-left,
    .user-dash-first-row-right {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .dashboard-card-2,
    .dashboard-card-3 {
        max-width: 100%;
    }

    .user-dash-first-row {
        width: 100%;
        flex-direction: column;
    }

    .dash-price-card .price-card-main {
        max-width: 100%;
    }

    .dash-price-card {
        margin-bottom: 20px;
    }

    .user-dashboard-main {
        width: 100%;
    }

    .dash-token-inner-box {
        max-width: 100%;
        padding: 0px 6px;
    }

    .dashboard-card-1 {
        max-width: 100%;
        margin-bottom: 15px;
    }

    .footer-email-field .MuiInputBase-root {
        min-width: 170px;
        width: 100%;
    }

    .trans-data-main {
        overflow: auto;
        width: 100%;
    }

    .page-inner-main {
        flex-direction: column;
    }

    .trans-data-inner {
        margin-right: 0px;
    }

    .purchas-data-col-8 {
        max-width: 100px;
        width: 100%;
        flex: 0 0 100px;
    }

    .purchas-data-col-4 {
        max-width: 100px;
        width: 100%;
        flex: 0 0 100px;
    }

    .trans-btn-main .trans-btn,
    .trans-btn-main .trans-btn:hover {
        height: 40px;
    }

    .referral-head-row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .trans-head-row .trans-title {
        margin-bottom: 20px;
    }

    .dash-referral-btn-main {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .private-sale-col-right {
        margin-left: 0px;
        margin-top: 20px;
    }

    .private-sale-col-left {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .modal .modal-twofa-inner {
        max-height: 374px;
        padding: 30px;
    }
    .grey-btn-main .withdrow-btn, .grey-btn-main .withdrow-btn:hover {
        width: 123px;
    }
    .auth-right {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .auth-left {
        display: none;
    }

    .auth-inner-main .auth-para {
        font-size: 17px;
        line-height: 20px;
    }

    .auth-inner-main .auth-title {
        font-size: 30px;
        line-height: 35px;
    }


    .footer-logo-box,
    .footer-social-main {
        margin-bottom: 15px;
    }

    .green-btn-main .buy-token-btn {
        width: 126px;
        height: 46px;
        padding: 14.5px;
        margin-right: 15px;
    }

    .green-btn-main .buy-token-btn {
        min-width: 150px;
        max-width: 150px;
    }

    .footer-logo {
        max-width: 120px;
        height: 30px;
    }

    .admin-sidebar-main {
        width: 200px;
        border-right: 0px;
    }

    .admin-header-main {
        left: 200px;
    }

    .dashboard-left-main {
        flex: 0 0 200px;
        max-width: 200px;
        width: 100%;
    }

    .admin-sidebar-list-main .admin-sidebar-link {
        padding: 5px 12px 5px;
    }

    .private-amount-main {
        max-width: 100%;
    }

    .modal .payment-modal-inner {
        height: calc(100% - 80px);
        overflow: auto;
        max-height: 725px;
    }

    .modal .modal-inner {
        height: calc(100% - 80px);
        overflow: auto;
    }
    .modal .modal-confirm-inner {
        padding: 25PX;
        max-height: 285px;
    }
    .wallet-modal .modal-wallet-inner {
        padding: 30px 30px;
        max-height: 370px;
    }
    .modal-close {
        right: -14px;
        top: -14px;
    }

    .private-payment-modal .payment-modal-inner {
        padding: 30px;
    }
}

























@media screen and (max-width:767px) {

    .page-inner-main {
        flex-direction: column;
    }

    .page-left-main {
        display: none;
    }

    .page-right-main {
        padding: 25px 0px;
    }

    .white-border-btn-main .dash-btn {
        min-width: 85px;
        height: 27px;
    }

    .tabs-main .tab {
        text-transform: capitalize;
        margin-right: 33px;
        font-size: 11.5px;
        line-height: 14px;
        align-items: flex-start;
    }

    .primary-btn-main .fa2-btn {
        min-width: 130px;
        height: 42px;
    }

    .page-main-box {
        width: 100%;
        box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);
        padding: 20px;
    }

    .auth-right {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .auth-inner-main {
        padding: 50px 40px;
    }

    .auth-inner-main .auth-para {
        font-size: 20px;
        line-height: 24px;
    }

    .auth-inner {
        max-width: 100%;
    }

    .auth-logo-main .auth-logo-text {
        font-size: 30px;
        line-height: 30px;
    }

    .auth-checkbox-main .auth-forgot-lable {
        font-size: 14px;
        line-height: 17px;
    }

    .auth-checkbox-main .checkbox-lable {
        font-size: 14px;
        line-height: 17px;
    }

    .primary-btn-main .auth-btn,
    .primary-btn-main .auth-btn:hover {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 17px;
    }

    .auth-inner-main .auth-sub-para {
        font-size: 14px;
        line-height: 17px;
    }

    .auth-footer-flex .auth-footer-para {
        font-size: 14px;
        line-height: 17px;
    }

    .auth-inner-main .auth-footer-terms-para {
        font-size: 12px;
        line-height: 20px;
    }

    .auth-inner-main .auth-title {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 10px;
    }

    .auth-forget-flex .primary-btn-main .auth-forget-btn {
        min-width: 145px;
    }

    .auth-checkbox-main .auth-checkbox-lable {
        font-size: 11px;
        line-height: 13px;
    }

    .footer-inner-main .mobile-footer-logo {
        display: flex;
        margin-bottom: 24px;
    }

    .footer-sec {
        padding: 35px 10px 30px 5px;
    }

    .footer-col .footer-para {
        padding-top: 0px;
    }

    .footer-inner-main {
        padding-left: 0px;
        padding-right: 0px;
    }

    .private-sale-right-main {
        padding: 30px 0px;
    }

    .private-sale-col-left {
        max-width: 100%;
        padding: 40px 20px;
        flex: 0 0 100%;
    }

    .private-sale-main {
        width: 100%;
    }

    .private-amount-main {
        max-width: 100%;
        flex-direction: column;
        height: auto;
        padding: 25px 20px;
    }

    .private-sale-row {
        flex-direction: column;
    }

    .user-dash-first-row-left {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .dash-token-value-inner {
        margin: 0 10.83px;
        width: calc(100% / 4 - 21.67px);
    }

    .dash-token-value-box {
        height: 54px;
    }

    .user-dash-first-row {
        flex-direction: column;
    }

    .price-card-main {
        padding: 20px;
        height: 100%;
        margin-bottom: 15px;
    }

    .dash-card-content {
        flex-direction: column !important;
    }
    .grey-btn-main .grey-btn, .text-btn-main .text-btn{
        margin-right: 0px;
    }
    .dash-progressbar {
        max-width: 100%;
    }
    .drop-down-icon {
        margin-left: 19px;
    }
    .price-flex .card-price {
        font-size: 18px;
        line-height: 22px;
    }

    .price-flex {
        justify-content: space-between;
    }

    .green-btn-main .running-btn {
        min-width: 80px;
        font-size: 11px;
        line-height: 13px;
    }

    .dash-token-value-main {
        margin: 24px -10.83px 23px -10.83px;
    }

    .dash-token-value-main .dash-token-time-text {
        font-size: 12px;
        line-height: 14px;
    }

    .dash-token-data-content .dash-token-data-text {
        font-size: 12px;
        line-height: 14px;
    }

    .dash-token-data-content .dash-token-data-price {
        font-size: 18px;
        line-height: 22px;
    }

    .price-card-inner .token-price-para {
        font-size: 12px;
        line-height: 18px;
        margin: 15px 0px 43px;
    }

    .dash-token-flex {
        margin-top: 17px;
    }

    .green-btn-main .buy-token-btn,
    .green-btn-main .buy-token-btn:hover {
        width: 140px;
        height: 36px;
        padding: 9.65px 29px;
        min-width: 140px;
        max-width: 200px;
        font-size: 13px;
        line-height: 17px;
    }

    .user-dash-card-flex {
        flex-direction: column;
    }

    .dashboard-card {
        padding: 25px 20px 25px;
        width: 100%;
        max-width: 100%;
        height: 188px;
        display: flex;
        /* align-items: center; */
        flex-direction: column;
        justify-content: center;
        min-width: 100%;
    }

    .dashboard-card-3 {
        margin-left: 0px;
    }

    .user-dash-first-row-right {
        margin-left: 0px;
    }

    .dashboard-card-2 {
        margin-bottom: 15px;
    }

    .dashboard-referral-col {
        padding: 25px 20px;
        height: auto;
    }

    .dashboard-referral-flex {
        flex-direction: column;
    }

    .dashboard-referral-flex .dash-referral-text {
        font-size: 20px;
        line-height: 24px;
    }

    .dash-recent-main .dashboard-referral-flex .dash-referral-text {
        font-size: 16px;
        line-height: 19px;
    }

    .dashboard-referral-flex .dash-referral-para {
        font-size: 15px;
        line-height: 18px;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .dashboard-referral-flex .dash-view-text {
        color: #5E55B8;
        font-size: 12px;
        line-height: 14px;
    }

    .grey-right-errow {
        width: 9px;
        height: 9px;
    }

    .dash-recent-main .dashboard-referral-flex {
        flex-direction: initial;
    }

    .dash-transaction-inner .dash-tarasaction-titles {
        font-size: 15px;
        line-height: 18px;
    }

    .dash-transaction-inner .dash-tarasaction-lables {
        font-size: 12px;
        line-height: 14px;
    }

    .dash-trasction-box {
        width: 24.91px;
        height: 24px;
    }

    .dash-trasction-icons {
        height: 10px;
        width: 10px;
    }

    .user-dash-second-row .trans-data-main {
        height: auto;
        overflow: auto;
    }

    .dash-recent-main {
        padding: 16px 20px 15px;
        height: auto;
    }

    .radio-txt-main div:nth-child(2) p {
        font-size: 9px;
        line-height: 12px;
    }

    .gradiant-border {
        display: block;
    }

    .dash-transaction-main:last-child {
        margin-bottom: 0px;
    }

    .gradiant-border {
        height: 5px;
        width: 100%;
        margin: 10px 0px 0px;
    }

    .dash-token-title-flex {
        display: none;
    }

    .dashboard-graph-flex {
        margin: 15px 0px 15px;
    }

    .dash-card-content-flex {
        margin-top: 18px;
        width: 100%;
    }

    .dash-card-flex {
        width: 100%;
    }

    .private-card-content-flex {
        /* justify-content: space-between; */
        width: initial;
    }

    .payment-modal-inner .private-new-token-para {
        display: block;
    }

    .private-card-content-flex .private-card-content:nth-child(1),
    .private-card-content-flex .private-card-content:nth-child(2),
    .private-card-content-flex .private-card-content:nth-child(3),
    .private-card-content-flex .private-card-content:nth-child(4),
    .private-card-content-flex .private-card-content:nth-child(5),
    .private-card-content-flex .private-card-content:nth-child(6) {
        margin-right: 11px;
    }

    .footer-col .footer-title {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 19px;
        font-family: 'Urbanist-Medium';
    }

    .footer-email-main .footer-go-btn,
    .footer-email-main .footer-go-btn:hover {
        height: 35px;
        width: 38px;
        top: 5px;
        min-width: 38px;
    }

    .footer-email-field .form-group .form-control input {
        padding: 11.54px 10px;
        font-family: 'Urbanist-light';
        font-size: 15px;
        line-height: 20px;
    }

    .footer-playstore-img {
        width: 128px;
        height: 37px;
    }

    .footer-nav-main .footer-nav-link {
        margin-bottom: 13px;
        font-size: 13px;
        line-height: 16px;
    }

    .bottom-footer-col {
        margin-top: 0px;
    }

    .footer-nav-main .footer-nav-li:last-child .footer-nav-link {
        margin-bottom: 8px;
    }

    .footer-privacy-main .footer-para {
        font-size: 9px;
        line-height: 17px;
        padding-top: 14px;
    }

    .footer-col .footer-page-link {
        margin-top: 16px;
    }

    .footer-social-main .footer-social-ul {
        margin-top: 0px;
    }

    .footer-social-title-box .Footer-mobile-text {
        font-size: 16px;
    }

    .page-right-main .page-titles {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 19px;
    }

    .text-btn-main .text-btn {
        font-size: 8px;
        line-height: 10px;
    }

    .text-btn-main .current-btn {
        min-width: 69px;
    }

    .grey-btn-main .disable-btn {
        max-width: 61px;
        font-size: 10px;
        line-height: 12px;
    }

    .profile-main .profile-para {
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
    }

    .security-setting-main .page-mid-title {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
    }

    .profile-switch {
        margin-bottom: 15px;
    }

    .switch-lable .MuiTypography-root {
        font-size: 12px;
        line-height: 14px;
    }

    .security-setting-main .manage-noti-text {
        margin-top: 20px;
    }


    .primary-btn-main .profile-btn {
        max-width: 130px;
        height: 42px;
    }

    .manage-noti {
        margin-bottom: 19px;
    }

    .profile-main {
        margin-bottom: 10px;
    }

    .input-box .form-lable {
        font-size: 12px;
        line-height: 14px;
    }

    .error-text-main .red-error-text {
        align-items: center;
    }

    .fa2-scanner-flex {
        flex-direction: column;
    }

    .twofa-input-box .twofa-text-control input {
        min-width: 88%;
    }

    .modal-twofa-inner {
        padding: 30px 25px;
    }

    .modal .modal-inner {
        width: 350px;
    }

    .modal .modal-confirm-inner {
        max-width: 350px;
        min-width: 350px;
        padding: 20px;
        max-height: 330px;
    }

    .modal-twofa-inner .twofa-modal-title {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 15px;
    }

    .twofa-step-flex {
        margin-bottom: 15px;
    }

    .primary-btn-main .twofa-btn {
        max-width: 100%;
    }

    .twofa-scanner-main {
        margin: auto;
    }

    .wallet-saccner-data .wallet-scanner-data-text {
        margin-bottom: 15px;
        margin-top: 17px;
        font-size: 14px;
        line-height: 17px;
    }

    .wallet-copy-bg {
        width: 35px;
        height: 35px;
    }

    .twofa-input-box {
        margin-bottom: 30px;
    }


    .private-sale-final-col {
        margin-bottom: 10px;
    }

    .private-sale-final-col .price-card-main {
        margin-bottom: 10px;
        max-width: 100%;
        min-height: auto;
        padding: 20px 20px 15px 20px;
    }

    .private-sale-final-main {
        width: 100%;
    }

    .private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(n + 1) {

        margin-left: 0px;
        margin-right: 0px;
    }

    .private-sale-final-main .private-sale-final-row .private-sale-final-col:nth-child(2n + 2) {
        margin-right: 0;
        margin-left: 0px;
    }


    .private-sale-final-col {
        width: calc(100% / 1);
    }

    .private-sale-final-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .private-sale-data-main .private-sale-titles {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 5px;
    }

    .private-sale-data-main .private-sale-para {
        margin-bottom: 30px;
        text-align: justify;
        margin-top: 15px;
    }

    .private-sale-flex {
        margin-top: 10px;
        margin-bottom: 30px;
        flex-direction: column !important;
    }

    .private-sale-usd-data {
        margin-left: 10px;
    }

    .private-checkbox-main {
        margin-bottom: 15px;
        flex-direction: initial;
        justify-content: flex-start !important;
    }

    .private-checkbox-main .private-checkbox-lable {
        font-size: 14px;
        line-height: 17px;
    }

    .private-sale-usd-box {
        max-width: 100%;
        height: 46px;
    }

    .private-amount-main {
        max-width: 100%;
    }

    .private-amount-col {
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;
        width: 100%;
        flex-direction: column;
        margin-right: 0px;
    }

    .private-sale-col-left .private-token-para {
        font-size: 12px;
        line-height: 14px;
    }

    .private-amount-col:last-child {
        margin-bottom: 0px;
    }

    .private-sale-col-left .private-amount-para {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0px 30px 0px;
    }

    .private-error-token-flex {
        margin-top: 20px;
    }

    .wallet-modal .modal-wallet-inner {
        padding: 30px 25px;
        max-height: 594px;
    }

    .private-sale-col-right {
        margin-left: 0px;
        margin-top: 15px;
    }

    .private-sale-card-2 {
        height: auto;
    }

    .private-card-wrap .private-card-content {
        width: 80px;
    }

    .trans-status-flex {
        display: flex;
        margin-bottom: 15px;
    }

    .header-switch-popup .MuiDialog-paper {
        display: none;
    }

    .wallet-modal .wallet-modal-title {
        text-align: center;
        font-size: 18px;
        line-height: 22px;
    }

    .wallet-modal .wallet-modal-para {
        font-family: 'Urbanist-Regular';
        font-size: 13px;
        line-height: 18px;
    }

    .wallet-modal-subpara {
        display: none;
    }

    .profile-data-box {
        display: block;
    }

    .private-checkbox-main .checkbox-lable .MuiCheckbox-root {
        margin-right: 10px;
    }

    .primary-border-btn-main .wallet-cancel-btn {
        height: 42px;
        margin-bottom: 10px;
        margin-top: 15px;
    }

    .private-amount-main .private-amount-col:last-child .private-amount-lable {
        margin-bottom: 0px;
    }

    .primary-btn-main .wallet-refresh-btn {
        height: 42px;
    }

    .wallet-btn-modal-flex {
        flex-direction: column;
    }

    .wallet-btn-modal-flex .primary-border-btn-main,
    .wallet-btn-modal-flex .primary-btn-main {
        width: 100%;
    }

    .wallet-modal .wallet-modal-subpara {
        font-size: 14px;
        line-height: 22px;
        max-width: 100%;
    }

    .wallet-scanner-flex {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .wallet-saccner-data {
        width: 100%;
    }

    .wallet-input-box .wallet-text-control input {
        min-width: initial;
        height: 33px;
    }

    .wallet-usd-icon {
        top: 16px;
    }

    .wallet-input-box .wallet-text-control input::placeholder {
        font-size: 14px;
        line-height: 17px;
        max-width: 190px;
    }

    .private-payment-modal .payment-modal-inner {
        padding: 30px 25px;
        min-width: 350px;
        max-width: 350px;
        max-height: 889px;
    }

    .payment-modal-inner .payment-modal-title {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 15px;
    }

    .payment-modal-inner .payment-modal-para {
        font-size: 13px;
        line-height: 20px;
    }

    .payment-radio-content .payment-radio-text {
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 5px;
    }

    .payment-radio-content .payment-modal-error-para {
        font-size: 10px;
        line-height: 10px;
    }

    .payment-modal-error-para .primary-error-icon {
        width: 7px;
        height: 7px;
        margin-right: 4px;
    }

    .payment-modal-flex {
        flex-direction: column;
    }

    .payment-modal-radio-box1 {
        margin-bottom: 10px;
    }

    .primary-btn-main .payment-link-btn {
        display: block;
        margin-bottom: 10px;
    }

    .payment-modal-radio-box:last-child {
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .payment-modal-links {
        display: none;
    }

    .payment-modal-radio-box {
        max-width: 100%;
        padding: 10px 12px;
        min-height: 61px;
    }

    .payment-error-flex {
        align-items: center;
    }

    .payment-checkbox-main {
        align-items: flex-start;
    }

    .primary-btn-main .payment-buy-token-btn {
        max-width: 100%;
        height: 51px;
        margin-top: 15px;
    }

    .private-card-wallet-col .private-card-main {
        max-width: 100%;
        min-height: auto;
        padding: 20px;
    }

    .private-sale-wallet-main .private-sale-wallet-row .private-card-wallet-col:nth-child(n+1) {
        margin-left: 0;
        margin-right: 0px;
    }

    .wallet-btn-flex .primary-border-btn-main {
        width: 100%;
        margin-top: 10px;
        order: 1;
    }

    .private-wallet-mobile-card {
        display: block;
    }

    .payment-modal-inner .payment-condition-para {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 5px;
    }

    .radio-box-main label {
        padding: 0px;
    }

    .header-nav-li .connect-btn {
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 24px;
        display: none;
    }

    .private-card-1,
    .private-card-2,
    .private-card-3 {
        display: none;
    }

    .private-wallet-mobile-card .private-card-content .private-price-text {
        margin-bottom: 0px;
    }

    .private-wallet-mobile-card .private-card-content {
        align-items: center;
        margin-bottom: 21px;
    }

    .primary-btn-main .wallet-withdrow-btn {
        height: 42px;
    }

    .wallet-btn-flex .primary-btn-main {
        width: 100%;
    }

    .primary-border-btn-main .wallet-modal-cancel-btn {
        height: 42px;
    }

    .private-wallet-inner {
        padding: 30px 25px;
    }

    .modal-wallet-flex {
        align-items: flex-start;
        flex-direction: column;
    }

    .wallet-box-inner-otp-flex {
        display: flex;
        flex-direction: initial;
    }

    .modal-wallet-flex .modal-wallet-lable {
        max-width: 100%;
        margin-bottom: 6px;
        font-size: 12px;
        line-height: 14px;
        font-family: 'Urbanist-Regular';
    }

    .modal-wallet-flex .wallet-box-inner-otp-label {
        max-width: 110px;
        margin-right: 20px;
        font-size: 14px;
        line-height: 17px;
        font-family: 'Urbanist-Bold';
    }

    .wallet-modal-input-box .wallet-text-control input {
        min-width: 273px;
        height: 33px;
    }

    .wallet-modal-input-box .wallet-text-control input::placeholder {
        max-width: 223px;
    }

    .modal-wallet-box {
        min-width: 100%;
        padding: 9px 17px;
    }

    .primary-btn-main .wallet-Request-btn,
    .primary-btn-main .wallet-Request-btn:hover {
        margin-left: 0px;
        padding: 12px;
    }

    .private-card-row {
        flex-direction: column;
    }

    .private-card {
        padding: 25px 20px;
        height: auto;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .private-card-flex {
        max-width: 100%;
        margin-right: 0px;
        width: 100%;
        margin-bottom: 40px;
        flex: 0 0 100%;
    }

    .private-card-content .private-price-text {
        margin-bottom: 15px;
    }

    .private-card-row {
        margin: 0px;
    }

    .private-wallet-inner .private-wallet-modal-para {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
    }


    .private-card-1 {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .private-card-4 {
        display: none;
    }

    .private-card-2,
    .private-card-3,
    .private-card-4 {
        margin-left: 0px;
        max-width: 100%;
    }

    .grey-btn-main .withdrow-btn {
        width: 140px;
        height: 36px;
    }

    .private-sale-wallet-main {
        width: 100%;
        margin-bottom: 0px;
    }

    .private-card-wallet-col {
        padding-right: 0px;
        margin-bottom: 10px;
        padding-left: 0px;
        width: 100%;
    }

    .private-sale-wallet-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .primary-btn-main .locked-btn,
    .primary-btn-main .locked-btn:hover {
        margin-left: 0px;
    }

    .private-card-wallet-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .private-card-content {
        display: flex;
        justify-content: space-between;
        flex-direction: initial;
        width: 100%;
    }


    .dash-referral-span-text {
        font-size: 16px;
        line-height: 19px;
    }

    .mobile-drawer-btn-main {
        display: block;
    }

    .mobile-menu-icon {
        width: 23px;
        height: 24px;
        object-fit: contain;
        margin-right: 13px;
    }

    .header-landing-btn-main {
        display: none;
    }

    .header-logo-box .mobile-menu-btn {
        display: block;
    }

    .header-sec {
        padding: 20px 0px 20px 8px
    }

    .squre-icon {
        display: none;
    }

    .header-btn-main {
        flex-direction: column;
    }

    .page-wrapper {
        height: calc(100% - 75px);
        padding-top: 75px;
    }

    .header-btn-main .header-switch-btn {
        min-width: 255px;
        margin-right: 0px;
        margin-bottom: 20px;
        border: 1.5px solid #7668FE;
        border-radius: 5px;
        background-color: transparent;
    }

    .header-btn-main .header-btn {
        height: 45px;
        font-size: 19px;
    }

    .mobile-dropdown-main .mobile-dro-btn {
        min-width: 115px;
        width: 100%;
        background: #000000;
        border-bottom: 1.5px solid #7668FE;
        text-transform: capitalize;
        color: #FFFFFF;
        font-size: 11px;
        line-height: 14px;
        font-family: 'Urbanist-Regular';
        padding-bottom: 10.5px;
        height: 35px;
        border-radius: 0px;
        padding-right: 0px;
    }

    .header-logo-box .token-logo-text {
        display: none;
    }

    .header-logo {
        width: 107px;
        height: 25px;
        object-fit: contain;
        margin-right: 0px;
    }

    .mobile-drop-ul-main .mobile-drop-item:last-child {
        margin-bottom: 0px;
    }

    .display-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .hero-social-right .footer-social-main .footer-social-link {
        height: 0px;
        width: 0px;
        margin-right: 12px;
    }

    .hero-social-right .footer-social-main .footer-social-li {
        margin-right: 12px;
    }

    .hero-social-right .footer-social-main .footer-social-li .footer-social-icon {
        height: 12px;
        width: 12px;
    }

    .mobile-drop-ul-main .mobile-drop-list {
        padding: 15px 18px 16px;
        margin: 0px;
        width: 135px;
        background: #171717;
    }

    .mobile-drop-ul-main .mobile-drop-item {
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        font-family: 'Urbanist-Medium';
        padding: 0px;
        margin-bottom: 13px;
    }

    .mobile-dropdown-main {
        position: relative;
    }

    .mobile-drop-ul-main {
        position: absolute;
        right: -16px;
        top: 45px;
    }

    .header-col-3 {
        margin-left: auto;
    }

    .header-nav-main .header-nav-ul {
        flex-direction: column;
        width: 100%;
        overflow: auto;
        height: calc(100vh - 130px);
    }

    .header-nav-main .header-nav-link {
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 24px;
        margin-right: 0px;
    }

    .header-nav-main .header-nav-li {
        justify-content: center;
        width: auto;
    }

    .header-nav-main {
        position: fixed;
        left: -766px;
        transition: 0.5s all;
        background: rgba(0, 0, 0, 1);
        width: 100%;
        display: flex;
        justify-content: center;
        height: 100vh;
        top: 0px;
        margin: 63px 0px 0px 0px;
        right: 0px;
        overflow: auto;
        z-index: 999;
        padding-top: 40px;
        /* padding-bottom: 150px; */
    }

    .header-nav-main.active {
        left: 0px;
    }

    .header-logo-box .mobile-menu-btn {
        min-width: auto;
        padding: 0px;
        height: 24px;
    }

    .display-row>* {
        padding: 0px 0px;
    }

    .mobile-dropdown-main {
        display: block;
    }

    .mobile-switch-main {
        display: block;
    }

    .header-swtch-main {
        display: none;
    }

    .hero-content-main .hero-titles {
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 15px;
    }

    .hero-btn-main .hero-fill-btn,
    .hero-btn-main .hero-fill-btn:hover {
        min-width: 200px;
        margin-right: 10px;
    }

    .hero-btn-main .hero-border-btn,
    .hero-btn-main .hero-border-btn:hover {
        background: rgba(31, 31, 31, 0.55);
        border: 1.5px solid #34A9FF;
        min-width: 125px;
    }

    .hero-content-main .hero-sub-para {
        font-size: 13px;
        line-height: 16px;
    }

    .hero-first-row {
        flex-direction: column;
    }

    .hero-first-row {
        max-width: 335px;
        flex-direction: column;
        padding-top: 100px;
    }

    .hero-progress-main .hero-progress-txet {
        font-size: 16px;
        line-height: 19px;
    }

    .hero-bonus-flex {
        min-width: 335px;
        margin-top: 30px;
    }

    .hero-bonus-content-main .hero-bonus-price {
        font-size: 24px;
        line-height: 29px;
    }

    .hero-bonus-content-main .hero-bonus-text {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 10px;
    }

    .hero-bonus-duration-main {
        margin-right: 17px;
    }

    .hero-bonus-flex .hero-bonus-content-main:first-child {
        margin-right: 59px;
        min-width: 107px;
    }

    .hero-bonus-content-main .duration-dots-main::after {
        margin-right: -11px;
    }

    .hero-bonus-content-main .hero-bonus-inner-text {
        font-size: 10px;
        line-height: 12px;
        text-align: left;
    }

    .hero-social-round {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }

    .hero-social-icons {
        width: 5.6px;
        height: 6px;
    }

    .hero-second-row {
        max-width: 335px;
    }

    .hero-social-flex .hero-social-lables {
        font-size: 10px;
        line-height: 12px;
    }

    .hero-social-left .hero-social-flex:first-child {
        margin-right: 15px;
    }

    .footer-social-icon {
        height: 16px;
        width: 16px;
    }

    .hero-social-right .footer-social-ul {
        margin-top: 0px;
    }

    .page-title-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .home-token-main .home-token-li {
        width: calc(100% / 2 - 5px);
        margin: 0 5px 40px 0px;
    }

    .home-token-utility-box {
        padding-left: 14px;
        height: 185px;
    }

    .home-token-utility-box .home-token-utility-para {
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 30px;
    }

    .home-token-utility-box .home-token-utility-titles {
        font-size: 15px;
        line-height: 18px;
    }

    .home-utility-icons {
        height: 20px;
        width: 20px;
    }

    .main-titles-box .main-titles {
        margin-bottom: 40px;
        font-size: 26px;
        line-height: 31px;
        max-width: 100%;
        position: relative;
        max-width: 230px;
    }

    .home-utility-icons {
        bottom: 0px;
    }

    .advance-main .advance-titles {
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 3px;
    }

    .advance-main .advance-para {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 30px;
    }

    .advance-data-body {
        padding: 0px 0px 0px 0px;
    }

    .advance-data-head {
        margin-bottom: 28px;
        border-bottom: 0px;
        padding: 0px 0px 0px 0px;
        justify-content: center;
    }

    .advance-data-head .advance-col-1,
    .advance-data-head .advance-col-2,
    .advance-data-head .advance-col-3 {
        margin-right: 10px;
    }

    .advance-data-head .advance-col-4 {
        margin-right: 0px;
    }

    .advance-data-row .advance-col-1 .advance-data-box {
        justify-content: center;
        align-items: center;
        background: #1F1F1F;
        margin-bottom: 4px;
        width: 100%;
    }

    .advance-col-1 {
        max-width: 110px;
        flex: 0 0 110px;
        margin-right: 5px;
    }

    .advance-data-box .advance-data-text {
        font-size: 11px;
        text-align: center;
        line-height: 13px;
    }

    img.round-icons {
        height: 11.33px;
        width: 11.33px;
    }

    .advance-data-box .advance-data-text-small {
        text-align: center;
        word-break: break-word;
    }

    .advance-data-row {
        display: flex;
        justify-content: center;
    }

    .advance-col-2,
    .advance-col-3,
    .advance-col-4 {
        max-width: 70px;
        width: 100%;
        flex: 0 0 70px;
        margin-right: 5px;
        margin-bottom: 4px;
    }

    .advance-col-4 {
        max-width: 70px;
        width: 100%;
        flex: 0 0 70px;
        margin-right: 0px;
        margin-bottom: 4px;
    }

    .bg-dark-blue {
        background: #1F1F1F;
    }

    .advance-data-body .advance-data-row:last-child {
        margin-bottom: 0px;
    }

    .advance-data-row:first-child .advance-col-2,
    .advance-data-row:first-child .advance-col-3,
    .advance-data-row:first-child .advance-col-4 {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .advance-data-row:last-child .advance-col-2,
    .advance-data-row:last-child .advance-col-3,
    .advance-data-row:last-child .advance-col-4 {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .advance-col-1,
    .advance-col-2,
    .advance-col-3,
    .advance-col-4 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .advance-data-box {
        min-height: 35px;
        max-height: 44px;
        padding: 9px 3px;
    }

    .advance-sec .advance-condition-text {
        font-size: 8px;
        line-height: 10px;
        margin-top: 10px;
        text-align: center;
    }

    .earn-token-row {
        flex-direction: column;
    }

    .earn-token-left-main {
        max-width: 100%;
        flex: 0 0 100%;
        margin-right: 0px;
    }

    .earn-token-left-main .earn-token-title {
        font-size: 26px;
        line-height: 31px;
        max-width: 221px;
        margin-bottom: 10px;
    }

    .earn-token-left-main .earn-token-para {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 30px;
        max-width: 335px;
    }

    .earn-token-card {
        max-width: 160px;
        height: 215px;
        padding: 15px 10px 15px 15px;
        margin-right: 15px;
    }

    .earn-token-card .earn-token-price {
        font-size: 28px;
        line-height: 34px;
    }

    .earn-token-span {
        font-size: 12px;
        line-height: 14px;
    }

    .earn-token-card .earn-token-card-title {
        font-size: 13px;
        line-height: 16px;
    }

    .earn-token-card .earn-token-card-para {
        font-size: 10px;
        line-height: 12px;
    }

    .earn-token-card-flex {
        justify-content: center;
        margin-bottom: 30px;
    }

    .earn-token-card .earn-token-btn,
    .earn-token-card .earn-token-btn:hover {
        height: 30px;
        font-size: 14px;
        line-height: 17px;
        min-width: 95px;
        width: 95px;
    }

    .earn-token-video {
        object-fit: cover;
        width: 100%;
        height: 212.13px;
    }

    .token-private-sale-sec {
        padding: 20px 0px 50px;
    }

    .token-logo-main {
        margin-bottom: 30px;
    }

    .token-logo-main .token-logo-text {
        font-size: 27px;
        line-height: 26px;
    }

    .page-title-right-box .page-title-right {
        font-size: 26px;
        line-height: 31px;
        margin-top: 0px;
        max-width: 246px;
    }

    .stake-token-sec {
        padding: 50px 0px 0px;
    }

    .page-title-row {
        margin-bottom: 0px;
    }

    .token-logo {
        height: 25px;
        margin-right: 10px;
        max-width: 110px;
    }

    .token-private-sale-main {
        padding: 0px;
        background-color: transparent;
        box-shadow: none;
    }

    .token-private-data-main .token-table-th {
        font-size: 12px;
        line-height: 14px;
        padding: 15px 10px 15px;
        word-break: break-word;
        min-width: initial;
        width: calc(100% / 3);
        text-align: center;
    }

    .token-private-data-main .token-table .token-table-row .token-table-td:last-child {
        padding: 20px 20px 22px 20px;
        text-align: center;
    }

    .token-private-notes-main {
        margin-top: 30px;
    }

    .token-private-notes-main .token-private-note-titels {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }

    .token-private-notes-main .token-private-note-para {
        font-size: 14px;
        line-height: 20px;
    }

    .rewards-main .rewards-titles {
        font-size: 26px;
        line-height: 38px;
        margin-bottom: 5px;
    }

    .rewards-main .rewards-titles-para {
        font-size: 14px;
        line-height: 20px;
    }

    .rewards-data-main {
        margin: 30px -15px 30px;
    }

    .rewards-round-box {
        width: 60px;
        height: 60px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        margin-left: 20px;
    }

    .rewards-icons {
        width: 49.36px;
        height: 43px;
    }

    .rewards-main-flex {
        height: 60px;
        border-radius: 0px;
    }

    .rewards-main-flex .rewards-data-1 {
        max-width: 102px;
        width: 100%;
        flex: 0 0 102px;
        margin-right: 35px;
    }

    .rewards-main-flex .rewards-data-2 {
        max-width: 120px;
        width: 100%;
        flex: 0 0 120px;
        margin-right: 0px;
    }

    .rewards-main-flex .rewards-data-3 {
        display: none;
    }

    .rewards-main .rewards-note-titels {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 20px;
    }

    .rewards-main .rewards-note-para {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .roadmap-content-box .roadmap-quarter-text {
        font-size: 12px;
        line-height: 14px;
    }

    .roadmap-content-box .roadmap-quarter-date {
        font-size: 14px;
        line-height: 14px;
        padding-bottom: 10px;
    }

    .roadmap-content-box .roadmap-para {
        font-size: 10px;
        line-height: 12px;
    }

    .roadmap-main .roadmap-owl .owl-nav .owl-prev,
    .roadmap-main .roadmap-owl .owl-nav .owl-next {
        width: 35px;
        height: 35px;
        margin: 0px 12px 0px 12px;
    }

    .roadmap-content-box {
        min-height: 190px;
    }

    .hero-bonus-inner-flex {
        min-width: 100%;
    }


    .stake-token-data-flex .stake-token-data-round {
        display: none;
    }

    .stake-token-col {
        display: flex;
    }

    .blue-right-icon {
        display: none;
    }

    .colspan-2 {
        min-height: 30px !important;
        min-width: 100%;
    }

    .stake-mob-col-1,
    .stake-mob-col-2,
    .stake-mob-col-3,
    .stake-mob-col-4,
    .stake-mob-col-5,
    .stake-mob-col-6,
    .stake-mob-col-7,
    .stake-mob-col-8,
    .stake-mob-col-9 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-right: 0px;
    }

    .stake-token-data-flex {
        margin-right: 1.63px;
        min-width: 38px;
    }

    .stake-token-data-flex {
        margin-right: 1.63px;
        justify-content: center;
        min-width: 38px;
        min-height: 30px;
        width: 100%;
    }

    .stake-token-heading-main {
        margin-bottom: 10px;
    }

    .stake-token-heading-main .stake-token-heading {
        font-size: 10px;
        line-height: 12px;
        min-height: 40px;
        border-bottom: 1px solid #FFFFFF;
        width: max-content;
        padding-bottom: 5px;
    }

    .hero-content-inner .hero-sub-titles {
        display: block;
        font-size: 15px;
        line-height: 22px;
        color: #FFFFFF;
        font-family: "Urbanist-Medium";
        text-align: center;
        max-width: 335px;
        margin: auto;
    }

    .hero-btn-main {
        margin-top: 30px;
    }

    .banner-video {
        min-height: 790px;
    }

    /* .token-private-data-main .token-table-th:nth-child(1),
    .token-private-data-main .token-table-td:nth-child(1) {
        width: 25%;
    }

    .token-private-data-main .token-table-th:nth-child(2),
    .token-private-data-main .token-table-td:nth-child(2) {
        width: 25%;
    }

    .token-private-data-main .token-table-th:nth-child(3),
    .token-private-data-main .token-table-td:nth-child(3) {
        width: 25%;
    }

    .token-private-data-main .token-table-th:nth-child(4),
    .token-private-data-main .token-table-td:nth-child(4) {
        width: 25%;
    }

    .token-private-data-main .token-table-container .token-table,
    .token-table-inner-main .token-table-container .token-table {
        min-width: 100%;
    } */
    .token-private-data-main .token-table .token-table-row:first-child .token-table-td {
        padding-top: 20px;
    }

    .token-private-data-main .token-table-td {
        font-size: 14px;
        line-height: 19px;
        padding: 20px 10px 15px;
        text-align: center;
        /* min-width: 100%; */
        width: calc(100% / 3);
        min-width: inherit;
    }

    .token-private-data-main .token-table-th:last-child {
        text-align: right;
    }

    .token-table-container .token-table {
        min-width: 100%;
    }

    .body-overflow {
        overflow: hidden;
    }

    .profile-right-main {
        padding: 30px 0px;
    }

    .private-sale-final-main {
        width: 100%;
    }

    .private-sale-final-right-main {
        padding: 30px 0px 20px;
    }

    .dash-page-right-main {
        padding: 15px 0px 27px;
    }


    .stake-token-flex-row {
        display: block;
        margin-left: -5px;
        margin-right: -5px;
    }

    .header-sec-trans {
        background-color: #000000;
        padding: 20px 0px 20px 8px;
    }

    .dash-token-data-flex .dash-token-label {
        font-size: 10px;
        line-height: 12px;
        color: #000000;
        text-align: center;
        -webkit-line-clamp: inherit;
    }

    .listing-arrow-icon {
        width: 15px;
        height: 20px;
        margin-right: 0px;
    }

    .dash-token-mob-9 {
        max-width: 38px;
        width: 100%;
        flex: 0 0 38px;
    }

    .token-arrow-icon {
        margin-right: 0px;
        width: 9px;
        height: 18px;
    }

    .grey-check-icon {
        height: 11px;
        width: 11px;
    }

    .dash-token-heading-main .dash-token-heading {
        font-size: 10px;
        line-height: 12px;
        min-height: 30px;
        width: max-content;
    }

    .token-private-sale-title-box .token-private-sale-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .rewards-program-sec {
        padding: 0px 0px 50px;
    }

    .rewards-main-flex .rewards-data {
        font-size: 14px;
        line-height: 17px;
    }

    .home-token-main .main-titles-box .main-titles {
        max-width: 100%;
    }

    .home-token-allocation-sec {
        padding: 50px 0px 10px;
    }

    .dash-token-data-round {
        height: 12px;
        width: 12px;
        margin-right: 0px;
    }

    .dash-token-data-flex {
        padding: 7px 6.5px;
        flex-direction: column;
        align-items: center;
        margin-right: 1.63px;
        min-width: 38px;
        min-height: 38px;
        max-height: 53px;
        width: 100%;
        justify-content: center;
    }

    .dash-token-col {
        display: flex;
        margin-top: 10px;
    }

    .dash-token-flex-row {
        flex-direction: column;
    }

    .dash-token-col-1,
    .dash-token-col-2,
    .dash-token-col-3,
    .dash-token-col-4,
    .dash-token-col-5,
    .dash-token-col-6,
    .dash-token-col-7 {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
        margin-right: 0px;
    }

    .user-dash-third-row {
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
        background: #FFFFFF;
        box-shadow: none;
        padding: 0px 0px;
        margin-bottom: 0px;
    }

    .user-dashboard-main {
        width: 100%;
    }

    .mobile-view {
        display: block;
    }

    .trans-field .MuiFormControl-root {
        width: 100%;
        min-width: 100%;
    }

    .transaction-main {
        margin-bottom: 20px;
        padding: 15px;
    }

    .trans-data-inner .dash-trans-titles {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 3px;
    }

    .trans-data-inner .dash-trans-lables {
        font-size: 10px;
        line-height: 12px;
    }

    .dots-drop-main {
        margin-left: 0px;
    }

    .desktop-view {
        display: none;
    }

    .trans-btn-main .trans-btn,
    .trans-btn-main .trans-btn:hover {
        text-align: center;
        min-width: 70px;
        height: 29px;
        max-width: 70px;
    }

    .plus-icon {
        width: 12px;
        height: 12px;
        object-fit: contain;
        margin-right: 7px;
    }

    .trans-search-box {
        width: 100%;
    }

    .trans-search-flex {
        border: 1px solid #e6effb;
        padding: 10px 15px;
        margin: 19px 0px 30px;
        background: #F9FCFF;
    }

    .trans-field .form-control input,
    .trans-field .form-control fieldset {
        border: 0px;
        background-color: transparent;
        padding: 0px 0px 0px 21px;
        font-size: 11px;
        line-height: 13px;
        height: auto;
    }

    .trans-field .form-control input::placeholder {
        font-size: 11px;
        line-height: 13px;
    }

    .trans-field {
        margin: 0px 0px 15px 0px;
    }

    .search-icon {
        position: absolute;
        left: 0px;
        top: 3px;
    }

    .trans-search-btn-main .trans-search-btn {
        min-width: 24.62px;
        height: 24px;
        width: 24.62px;
    }

    .referral-card {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 20px;
    }

    .referral-card .referral-card-flex:first-child {
        margin-bottom: 34px;
    }

    .referral-card-flex {
        width: 100%;
    }

    .referral-head-row {
        flex-direction: column;
    }

    .trans-head-row .trans-title {
        font-size: 20px;
        line-height: 24px;
    }

    .referral-text-copy-box {
        margin-right: 0px;
        max-width: 259px;
        min-width: 259px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 10px;
    }

    .text-copy-box .text-copy {
        font-size: 12px;
        line-height: 18px;
        width: 100%;
    }

    .referral-right-main {
        padding: 30px 0px 0px 0px;
    }

    .dashboard-card-1 {
        margin-bottom: 15px;
    }

    .trans-head-mobile-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .referral-copy-mobile-btn {
        position: absolute;
    }

    .referral-btn-main {
        position: relative;
        width: 100%;
        height: 40px;
    }

    .trans-head-mobile-row .primary-btn-main {
        position: absolute;
        right: 0px;
    }

    .primary-btn-main .referral-copy-mobile-btn,
    .primary-btn-main .referral-copy-mobile-btn:hover {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        min-width: 40px;
        height: 40px;
        font-size: 12px;
        line-height: 14px;
        font-family: 'Urbanist-Regular';
        max-width: 53px;
    }

    .trans-head-row .referral-title {
        margin-bottom: 10px;
    }

    .referral-card-inner {
        width: 100%;
    }

    .dash-refferal-main {
        padding: 0px;
        box-shadow: none;
    }

    .dash-history-mobile-btn .primary-border-btn-main {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0px 30px;
    }

    .primary-border-btn-main .history-btn {
        min-width: 95px;
        margin-left: 0px;
        height: 34px;
        font-size: 14px;
        line-height: 17px;

    }

    .dash-transaction {
        height: 173px;
    }

    .dash-referral-mobile-row {
        flex-direction: column;
        align-items: flex-end;
    }

    .footer-privacy-main .footer-para-top {
        font-size: 9px;
        line-height: 17px;
    }

    .referral-text-copy-box {
        max-width: initial;
        min-width: inherit;
    }

    .dash-trans-flex .trans-field {
        margin: 0px 0px 0px 0px;
    }

    .private-sale-wallet-right-main {
        padding: 30px 0px;
    }

    .dash-trans-flex .search-icon {
        position: absolute;
        left: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .dash-trans-flex .trans-field .form-control input,
    .dash-trans-flex .trans-field .form-control fieldset {
        line-height: 25px;
    }

    .private-token-value-main .private-token-value-inner:nth-child(2n + 2) {
        margin-right: 0px;
    }

    .private-sale-wallet-card-flex {
        flex-direction: column;
    }

    .private-price-card-main {
        max-width: 100%;
    }

    .transaction-right-main {
        padding: 30px 0px 0px;
    }

    .mobile-footer-logo {
        margin-bottom: 20px;
        width: 120px;
        display: block;
    }

    .desktop-footer-logo {
        display: none;
    }

    .admin-mobile-menu-icon {
        display: block;
    }

    .admin-header-main {
        left: 0px;
        padding: 10px;
    }

    .admin-header-row {
        justify-content: space-between;
    }

    .admin-header-row .mobile-menu-btn {
        min-width: max-content;
        padding: 0px;
    }

    .admin-sidebar-logo-main {
        text-align: left;
        padding: 10px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .admin-sidebar-logo {
        max-width: 111px;
        height: 35px;
    }

    .admin-sidebar-close {
        display: block;
    }

    .admin-sidebar-main {
        left: -767px;
        transition: 0.5s all;
        border-right: 0px;
    }

    .admin-sidebar-main.active {
        left: 0px;
    }

    .dashboard-left-main {
        flex: 0 0 0px;
        max-width: 0px;
        width: 100%;
    }

    .dashboard-content .admin-page-title {
        font-size: 19px;
    }

    .admin-text-field-main {
        min-height: 40px;
    }

    .add-user-data-main {
        padding: 20px 10px;
    }

    .dashboard-containt-main {
        padding: 70px 15px 24px 15px;
    }

    .admin-sidebar-list-main .admin-sidebar-link {
        padding: 5px 12px 5px;
    }

    .userbar-tabs-main .userbar-tab {
        font-size: 14px;
        line-height: 16px;
        margin-right: 15px;
    }

    .add-user-btn {
        font-size: 13px;
        line-height: 14px;
        padding: 5px;
        max-width: 70px;
    }

    .admin-sidebar-list-main {
        margin-top: 15px;
    }

    .admin-header-profile-icon {
        height: 25px;
        width: 25px;
    }

    .dash-price-card {
        margin-top: 0px;
    }

    .footer-play-left {
        margin-right: 30px;
    }

    .footer-logo-box .token-logo-text {
        font-size: 26px;
        line-height: 26px;
    }

    .footer-logo-box .footer-logo {
        margin-right: 9px;
        object-fit: contain;
        max-width: 130px;
        height: 27px;
    }

    .dash-token-content .dash-token-text {
        font-size: 10px;
        line-height: 12px;
    }

    .wallet-saccner-main {
        width: 140px;
        height: 140px;
        margin: auto;
        padding: 0px;
    }

    .wallet-saccner-main img {
        width: 138px;
        height: 137px;
        object-fit: contain;
    }

    .modal-close {
        height: 20px;
        width: 20px;
        right: -10px;
        top: -10px;
    }

    .dash-token-inner-box {
        max-width: 100%;
        padding: 0px;
    }

    .dash-add-end-flex .trans-btn-main .trans-btn,
    .dash-add-end-flex .trans-btn-main .trans-btn:hover {
        text-align: center;
        min-width: 121px;
        height: 29px;
        max-width: 70px;
        font-size: 11px;
    }

    .dash-trans-flex .trans-search-main {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .header-popup-switch-main {
        display: none;
    }

    .modal-wallet-flex .modal-wallet-widthdro-control .MuiInputBase-root {
        width: 100%;
    }

    .modal-wallet-flex .modal-wallet-widthdro-control input {
        padding: 7px 17px;
    }

    .cust-token-private-col {
        width: max-content;
        min-width: max-content;
    }

    .cust-token-private-row {
        display: flex;
        border-bottom: 1px solid #f4f6fa;
        width: 100%;
        overflow: auto;
    }

    .token-private-data-main .token-table-head {
        padding: 0px 17px 0px 10px;
        height: 58px;
    }

    .token-private-data-main .token-table .token-table-row .token-table-th:first-child {
        padding-left: 0px;
        text-align: center;
    }

    .token-private-data-main .token-table .token-table-row .token-table-td:first-child {
        padding: 20px 0px 22px 20px;
    }

    .price-flex .price-span {
        font-size: 18px;
        line-height: 22px;
    }

    .green-btn-main .status-btn,
    .green-btn-main .status-btn:hover {
        min-width: 80px;
    }

    .modal .modal-twofa-inner {
        max-height: 570px;
    }
}




















@media screen and (min-width: 598px) and (max-width:730px) {
    .footer-playstore-img {
        width: 95px;
        height: 37px;
    }

    .footer-scanner-img {
        height: 83px;
        width: 85px;
    }
}




@media screen and (min-width: 375px) and (max-width:767px) {
    .dash-referral-mobile-row {
        flex-direction: column;
    }

    .footer-email-field .MuiInputBase-root {
        min-width: 182px;
        width: 100%;
    }

    .stake-token-col-1 {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .stake-token-col-2,
    .stake-token-col-3,
    .stake-token-col-4,
    .stake-token-col-5,
    .stake-token-col-6,
    .stake-token-col-7,
    .stake-token-col-8,
    .stake-token-col-9 {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
        margin-right: 5px;
    }

    .stake-token-data-flex {
        width: 100%;
        min-width: 32px;
        min-height: 30px;
    }

}






































@media screen and (min-width: 320px) and (max-width:374px) {

    .referral-card-inner {
        flex-wrap: wrap;
    }

    .stake-token-col-1 {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .stake-token-col-2,
    .stake-token-col-3,
    .stake-token-col-4,
    .stake-token-col-5,
    .stake-token-col-6,
    .stake-token-col-7,
    .stake-token-col-8,
    .stake-token-col-9 {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
        margin-right: 5px;
    }

    .stake-token-data-flex {
        width: 100%;
        min-width: 32px;
        min-height: 30px;
    }

    .dash-token-col-1,
    .dash-token-col-2,
    .dash-token-col-3,
    .dash-token-col-4,
    .dash-token-col-5,
    .dash-token-col-6,
    .dash-token-col-7 {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .dash-token-data-flex {
        min-width: 36px;
        min-height: 36px;
    }

    .referral-text-copy-box {
        max-width: 220px;
        min-width: 225px;
    }

}